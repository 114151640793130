import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  EditOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  LinkOutlined,
  RightOutlined,
  TagOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, Image, message, Modal, notification, Popover, Row, Skeleton, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PiBookmarksLight } from 'react-icons/pi';
import queryString from 'query-string';
import moment from 'moment';
import posthog from 'posthog-js';

import { REACT_APP_IS_HIDE_SUBS_UI } from 'config/env';

import { useGetOrdersByPostId } from 'apis/order';
import { useGetCurrentStorePostsMinimal, useGetPost, useGetPostStatistic, patchUpdatePostById } from 'apis/post';
import { useGetProducts } from 'apis/product';
import { postDisconnectFbConnection } from 'apis/store';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { withFbContext } from 'contexts/FbContext/FbContext';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import OrderSummaryAndProductsModal from 'components/OrderSummaryAndProductsModal/OrderSummaryAndProductsModal';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import QuickAddProductModal from 'components/QuickAddProductModal/QuickAddProductModal';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useIsLoadings } from 'hooks/utils';
import { getAccountProfileRoute, getSubscriptionPlanRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { checkIsDate, formatToDateTimeWithDayFullString, getCurrentMoment, formatToDateString, getDayDiff } from 'utils/date';
import { checkIsArrayEmpty, constructDisplayPrice, guard } from 'utils/general';

import ProfileAccountSetup from './components/ProfileAccountSetup/ProfileAccountSetup';
import FbLiveComments from './components/FbLiveComments/FbLiveComments';
import BlacklistQuickViewModal from './components/BlacklistQuickViewModal/BlacklistQuickViewModal';
import IGMessagesQuickViewModal from './components/IGMessagesQuickViewModal/IGMessagesQuickViewModal';
import LuckyDrawSection from './components/LuckyDrawSection/LuckyDrawSection';
import PostCommentAndBumpKeywords from './components/PostCommentAndBumpKeywords/PostCommentAndBumpKeywords';
import ProductQuickUpdateModal from './components/ProductQuickUpdateModal/ProductQuickUpdateModal';
import ProductScrollSection from './components/ProductScrollSection/ProductScrollSection';
import AddLiveStreamerModal from './components/AddLiveStreamerModal/AddLiveStreamerModal';
import AddPostTagModal from './components/AddPostTagModal/AddPostTagModal';
import {
  LiveEndButton,
  LiveSessionSelection,
  LiveSessionSelectionLiveIndicator,
  LiveSessionSelect,
  LiveTypeSelect,
  CursiveText,
  FullHeightCard,
  FullHeightRow,
  LiveIframe,
  LivePlaceholderContainer,
  LivePlaceholderIcon,
  LiveStatsOrderContainer,
  LiveStatsPotentialRevenueContainer,
  LiveStatsLabel,
  LiveStatsValue,
  LiveVideoContainer,
  OnLiveContainer,
  OnLiveIcon,
  TooltipIcon,
  LiveOrdersProductContainer,
  StyledImagePreview,
  StyledFacebookOutlined,
  StyledInstagramOutlined
} from './Overview.styles';

import MWPoster from 'images/mw-poster.png';

const { confirm, error } = Modal;

const THROTTLE_REFETCH_TIME_MS = 5000;
const NOTIFICATION_WARNING_ACCOUNT_SETUP = 'notification-warning-account-setup';
const NOTIFICATION_WARNING_EXPIRY_PLAN = 'notification-warning-expiry-plan';
const NOTIFICATION_WARNING_EXCEED_ORDER_LIMIT = 'notification-warning-exceed-order-limit';
const NOTIFICATION_WARNING_REACHING_ORDER_LIMIT = 'notification-warning-reaching-order-limit';
const NOTIFICATION_MW_BANNER = 'notification-mw-banner';

/* =================================================== Local Functions =================================================== */
// =================================================== General

const constructQtyLeftDisplay = (productKeyword, variances = []) => {
  return (
    <Popover
      trigger="click"
      content={variances.map(variance => (
        <p key={variance.keyword}>
          <TagOutlined /> [{productKeyword}
          {variance.keyword}] {variance.label} x {variance.inventory}
        </p>
      ))}
    >
      <TooltipIcon />
    </Popover>
  );
};

const constructPriceDisplayForProductWithVariances = (variances = [], currencyISO) => {
  const variancesPriceList = variances.map(variance => Number(variance.price));
  const maxPrice = Math.max(...variancesPriceList);
  const minPrice = Math.min(...variancesPriceList);
  const isMinMaxPriceEqual = maxPrice === minPrice;

  return checkIsArrayEmpty(variances)
    ? '-'
    : isMinMaxPriceEqual
    ? constructDisplayPrice(maxPrice, currencyISO)
    : `${constructDisplayPrice(minPrice, currencyISO)} - ${constructDisplayPrice(maxPrice, currencyISO)}`;
};

const constructProductColumns = ({ t, currencyISO, productStatusesConst, handleOnEditProductQtyClick }) => [
  {
    ...constructColumn(t('pageOverview:product-table-header-name'), 'label', {
      hasAggregationSorter: true
    }),
    ...constructColumnFilterSearch('label', t('pageOverview:product-table-header-action-search-product'), { hasAggregationFilter: true }),
    render: (text, record) => {
      const previewImageUrl = record.coverPhoto?.original?.url || record.coverPhotoFile?.url;
      const thumbnailImageUrl = record.coverPhoto?.thumbnail?.url || previewImageUrl;

      return (
        <Row align="middle" gutter={4}>
          <Col span={2}>
            <StatusIndicator isActive={productStatusesConst && record.status === productStatusesConst.ACTIVE.code} />
          </Col>
          <Col span={22}>
            <Row justify="middle" gutter={8}>
              {previewImageUrl && (
                <Col>
                  <StyledImagePreview previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />
                </Col>
              )}
              <Col span={previewImageUrl ? 18 : 22}>
                <span>
                  {text} ({record.keyword})
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  },
  {
    ...constructColumn(t('pageOverview:product-table-header-qty-left'), 'inventory', { hasAggregationSorter: true, width: '20%' }),
    render: (inventory, record) => (
      <Row>
        <Col span={4}>{checkIsArrayEmpty(record.variances) ? '' : constructQtyLeftDisplay(record.keyword, record.variances)}</Col>
        <Col>{String(inventory)}</Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('pageOverview:product-table-header-price'), 'price', { isPrice: true, width: '20%' }),
    render: (price, record) => (
      <Row>
        <Col>
          {checkIsArrayEmpty(record.variances)
            ? constructDisplayPrice(price, currencyISO)
            : constructPriceDisplayForProductWithVariances(record.variances, currencyISO)}
        </Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('pageOverview:product-table-header-actions'), 'action', { width: '15%' }),
    render: (text, record) => {
      return (
        <Row>
          <TextButton
            icon={<EditOutlined />}
            onClick={() => handleOnEditProductQtyClick(record._id)}
            tooltipMessage={t('common:text-button-tooltip-message', {
              action: t('pageOverview:product-table-action-tooltip-message-manage-quantity')
            })}
          />
        </Row>
      );
    }
  }
];

const constructOrderColumns = ({ t, handleOnViewSummaryBtnClick }) => [
  {
    ...constructColumn(t('pageOverview:order-table-header-order-number'), 'orderNumber', {
      width: '20%',
      onClick: record => handleOnViewSummaryBtnClick(record._id)
    }),
    ...constructColumnFilterSearch('orderNumber', t('pageOverview:order-table-header-action-search-order-number'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageOverview:order-table-header-order-buyer'), 'customerDisplayInfo', {
      width: '30%'
    }),
    ...constructColumnFilterSearch('customerDisplayInfo', t('pageOverview:order-table-header-action-search-order-buyer'), {
      hasAggregationFilter: true
    }),
    render: (text, record) => {
      const hasCustomerSource = record.customerSource;
      return (
        <div>
          <span>
            {record.customerName} {hasCustomerSource ? `(${record.customerSource.toUpperCase()}: ${record.customerSourceName})` : null}
          </span>
          {record.customerContactNumber && <span> ({record.customerContactNumber}) </span>}
        </div>
      );
    }
  },
  {
    ...constructColumn(t('pageOverview:order-table-header-order-products'), 'products'),
    ...constructColumnFilterSearch('products', t('pageOverview:order-table-header-action-search-products'), { hasAggregationFilter: true }),
    render: (text, record) => {
      return (
        <LiveOrdersProductContainer>
          {record.products &&
            record.products.map(orderProduct => (
              <span key={orderProduct.matchingKeyword}>
                {orderProduct.matchingKeyword} x {orderProduct.quantity}
              </span>
            ))}
        </LiveOrdersProductContainer>
      );
    }
  },
  {
    ...constructColumn(t('pageOverview:order-table-header-order-total-amount'), 'totalAmount', {
      isPrice: true,
      hasAggregationSorter: true
    })
  }
];

const showSubscriptionConfigNotification = ({
  notificationKey,
  history,
  message,
  buttonText,
  description,
  isShowButton,
  onAfterClose = () => {}
}) => {
  notification.warning({
    key: notificationKey,
    duration: 0,
    placement: 'topRight',
    message: <b style={{ color: 'red' }}>{message}</b>,
    ...(isShowButton && {
      btn: (
        <TextButton
          onClick={() => {
            notification.close(notificationKey);
            history.push(getSubscriptionPlanRoute().path);
            onAfterClose();
          }}
          icon={null}
          text={
            <>
              {buttonText} <RightOutlined />
            </>
          }
        />
      )
    }),
    onClose: () => {
      onAfterClose();
    },
    description: <p>{description}</p>
  });
};

const AddPostTagSection = ({ t, tags, handleOnAddPostTagBtnClick }) => {
  const hasTags = tags && tags.length > 0;

  return (
    <div style={{ marginBottom: 8 }}>
      {t('pageOverview:post-tag-title')}
      {hasTags && ` ${tags.map(tag => tag.title).join(', ')}`}
      <TextButton
        icon={<PiBookmarksLight style={{ marginBottom: '-2px', marginRight: '8px' }} />}
        text={t(`pageOverview:${hasTags ? 'edit' : 'add'}-post-tag-button-text`)}
        onClick={handleOnAddPostTagBtnClick}
      />
    </div>
  );
};

// =================================================== Hook Functions
const useFetchConstants = () => {
  const { data: postStatusesConst, isLoading: isPostStatusesLoading } = useFetchConstant('postStatuses');
  const { data: productStatusesConst, isLoading: isProductStatusesLoading } = useFetchConstant('productStatuses');
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');

  const { isLoading: isConstantLoading } = useIsLoadings([isPostStatusesLoading, isProductStatusesLoading, isSocialMediaSourcesLoading]);

  return { isConstantLoading, postStatusesConst, productStatusesConst, socialMediaSourcesConst };
};

const useStoreStarterOrderLimitExceedNotification = (
  history,
  t,
  isSubscriptionStarter,
  isLoadingOrderLimitDetails,
  starterOrderLimit,
  starterTotalOrder,
  cookies,
  setCookie
) => {
  const [shouldShowOrderLimitExceed, setShouldShowOrderLimitExceed] = useState(false);

  useEffect(() => {
    if (REACT_APP_IS_HIDE_SUBS_UI) {
      return;
    }

    if (isSubscriptionStarter && !isLoadingOrderLimitDetails) {
      const orderLeft = starterOrderLimit - starterTotalOrder;
      const shouldShowReachingOrderLimit = orderLeft <= 15;
      const shouldShowOrderLimitExceed = orderLeft <= 0;
      const notificationKey = shouldShowOrderLimitExceed ? NOTIFICATION_WARNING_EXCEED_ORDER_LIMIT : NOTIFICATION_WARNING_REACHING_ORDER_LIMIT;

      if (cookies[notificationKey]) {
        return;
      }

      if (shouldShowReachingOrderLimit || shouldShowOrderLimitExceed) {
        let message = t('pageOverview:starter-user-reaching-order-limit-notification-message');
        let buttonText = t('pageOverview:starter-user-reaching-order-limit-notification-button');
        let description = t('pageOverview:starter-user-reaching-order-limit-notification-desc', { orderLeft: orderLeft });

        if (shouldShowOrderLimitExceed) {
          message = t('pageOverview:starter-user-order-limit-exceed-notification-message');
          buttonText = t('pageOverview:starter-user-order-limit-exceed-notification-button');
          description = t('pageOverview:starter-user-order-limit-exceed-notification-desc');
        }

        setShouldShowOrderLimitExceed(shouldShowOrderLimitExceed);
        showSubscriptionConfigNotification({
          notificationKey,
          history,
          message,
          buttonText,
          description,
          onAfterClose: () => setCookie(notificationKey, true, { expires: moment().add(1, 'days').toDate() })
        });
      }
    }
  }, [history, t, isSubscriptionStarter, isLoadingOrderLimitDetails, starterOrderLimit, starterTotalOrder, cookies, setCookie]);

  return { shouldShowOrderLimitExceed };
};

const useStoreBusinessSubsciprtionExpiryNotification = (
  history,
  t,
  isConstantLoading,
  isSubscriptionTypeBusiness,
  isSubscriptionPeriodsMonthly,
  isSubscriptionPeriodYearly,
  expiryDate,
  cookies,
  setCookie,
  isRoleAllowNotif,
  isMalaysiaLocation
) => {
  const shouldIgnoreNotif = REACT_APP_IS_HIDE_SUBS_UI || !isRoleAllowNotif;

  useEffect(() => {
    if (shouldIgnoreNotif) {
      return;
    }

    const notificationKey = NOTIFICATION_WARNING_EXPIRY_PLAN;

    if (isConstantLoading || !isSubscriptionTypeBusiness || cookies[notificationKey]) {
      return;
    }

    const dayLeft = getDayDiff(getCurrentMoment(), expiryDate, true);
    const shouldShowExpire = dayLeft <= 5;
    const renewPlanButtonText = t('pageOverview:business-user-expire-soon-notification-button');
    const subscriptionExpireNotificationTitle = t('pageOverview:business-user-expire-soon-notification-message');

    if (shouldShowExpire) {
      let message = subscriptionExpireNotificationTitle;
      let buttonText = renewPlanButtonText;
      let description = t('pageOverview:business-user-6month-plan-expire-soon-notification-desc', {
        dayLeft,
        expiryDate: formatToDateString(expiryDate, 'DD MMM YYYY')
      });

      if (isSubscriptionPeriodsMonthly) {
        description = t('pageOverview:business-user-monthly-plan-expire-soon-notification-desc', {
          dayLeft,
          expiryDate: formatToDateString(expiryDate, 'DD MMM YYYY')
        });
      } else if (isSubscriptionPeriodYearly) {
        description = t('pageOverview:business-user-yearly-plan-expire-soon-notification-desc', {
          dayLeft,
          expiryDate: formatToDateString(expiryDate, 'DD MMM YYYY')
        });
      }

      showSubscriptionConfigNotification({
        notificationKey,
        history,
        message,
        buttonText,
        description,
        isShowButton: isMalaysiaLocation,
        onAfterClose: () => setCookie(notificationKey, true, { expires: moment().add(1, 'days').toDate() })
      });
    }
  }, [
    history,
    t,
    isConstantLoading,
    isSubscriptionTypeBusiness,
    isSubscriptionPeriodsMonthly,
    isSubscriptionPeriodYearly,
    expiryDate,
    cookies,
    setCookie,
    shouldIgnoreNotif,
    isMalaysiaLocation
  ]);
};

const usePostTypes = (t, onAfterPostTypeUpdate) => {
  const locationQuery = useLocationQuery();
  const history = useHistory();

  const [isInit, setIsInit] = useState(false);
  const [selectedPostType, setSelectedPostType] = useState();

  const { data: postTypesConst, selection: apiPostTypeSelections, isLoading: isPostTypeSelectionsLoading } = useFetchConstant('postTypes');
  const postTypeSelections = useMemo(() => [{ label: t('pageOverview:post-selection-all-label'), value: 'all' }, ...(apiPostTypeSelections || [])], [
    t,
    apiPostTypeSelections
  ]);

  useEffect(() => {
    if (!isInit && !isPostTypeSelectionsLoading && postTypeSelections) {
      setIsInit(true);

      if (locationQuery.postType) {
        setSelectedPostType(locationQuery.postType);
      } else if (postTypeSelections[0]) {
        const defaultPostType = postTypeSelections[0].value;
        setSelectedPostType(defaultPostType);
      }
    }
  }, [isInit, isPostTypeSelectionsLoading, locationQuery, postTypeSelections]);

  const updateSelectedPostType = useCallback(
    newPostType => {
      setSelectedPostType(newPostType);

      const shouldCachePostType =
        !isPostTypeSelectionsLoading && postTypeSelections && postTypeSelections[0] && postTypeSelections[0].value !== newPostType;

      let newSearchQueryParams;
      if (shouldCachePostType) {
        newSearchQueryParams = { ...locationQuery, postType: newPostType };
      } else {
        newSearchQueryParams = { ...locationQuery, postType: undefined };
      }
      history.replace({ search: queryString.stringify(newSearchQueryParams) });

      onAfterPostTypeUpdate(newSearchQueryParams);
    },
    [history, isPostTypeSelectionsLoading, locationQuery, postTypeSelections, onAfterPostTypeUpdate]
  );

  return { postTypesConst, postTypeSelections, isPostTypeSelectionsLoading, selectedPostType, updateSelectedPostType };
};

const useStorePosts = t => {
  const locationQuery = useLocationQuery();
  const history = useHistory();

  const [isInit, setIsInit] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState();

  const handleOnAfterPostTypeUpdate = useCallback(
    newSearchQueryParams => {
      setIsInit(false);
      setSelectedPostId();
      history.replace({ search: queryString.stringify({ ...newSearchQueryParams, postId: undefined }) });
    },
    [history]
  );

  const { postTypesConst, postTypeSelections, isPostTypeSelectionsLoading, selectedPostType, updateSelectedPostType } = usePostTypes(
    t,
    handleOnAfterPostTypeUpdate
  );

  const { data: storePostSelections, isLoading: isStorePostSelectionsLoading, refetch: refetchStorePostSelections } = useGetCurrentStorePostsMinimal(
    selectedPostType
  );

  const storeSelectedPostPostProcessFunc = useCallback(storeSelectedPost => {
    return {
      ...storeSelectedPost,
      liveStreamers: storeSelectedPost.liveStreamers
        ? storeSelectedPost.liveStreamers.map(liveStreamerId => {
            return liveStreamerId.name;
          })
        : []
    };
  }, []);
  const { data: selectedPost, isLoading: isSelectedPostLoading, refetch: refetchSelectedPost } = useGetPost(selectedPostId, {
    postProcessFunc: storeSelectedPostPostProcessFunc
  });

  const updateSelectedPostId = useCallback(
    newPostId => {
      setSelectedPostId(newPostId);

      const shouldCachePostId =
        !isStorePostSelectionsLoading && storePostSelections && storePostSelections[0] && storePostSelections[0].value !== newPostId;
      if (shouldCachePostId) {
        history.replace({ search: queryString.stringify({ ...locationQuery, postId: newPostId }) });
      } else {
        history.replace({ search: queryString.stringify({ ...locationQuery, postId: undefined }) });
      }
    },
    [history, locationQuery, isStorePostSelectionsLoading, storePostSelections]
  );

  useEffect(() => {
    if (!isInit && !isStorePostSelectionsLoading && storePostSelections) {
      setIsInit(true);

      if (locationQuery.postId) {
        setSelectedPostId(locationQuery.postId);
      } else if (storePostSelections[0]) {
        const latestPostId = storePostSelections[0]._id;
        setSelectedPostId(latestPostId);
      }
    }
  }, [isInit, isStorePostSelectionsLoading, locationQuery, storePostSelections]);

  return {
    postTypesConst,
    postTypeSelections,
    isPostTypeSelectionsLoading,
    selectedPostType,
    updateSelectedPostType,

    storePostSelections,
    isStorePostSelectionsLoading,
    selectedPostId,
    updateSelectedPostId,

    isSelectedPostLoading,
    selectedPost,
    refetchSelectedPost,
    refetchStorePostSelections
  };
};

const useIGMessages = () => {
  const [isIGMessagesQuickViewClicked, setIsIGMessagesQuickViewClicked] = useState(false);

  const handleOnIGMessageBtnClick = () => {
    setIsIGMessagesQuickViewClicked(true);
  };

  const handleOnIGMessagesViewClose = () => {
    setIsIGMessagesQuickViewClicked(false);
  };

  return { isIGMessagesQuickViewClicked, handleOnIGMessageBtnClick, handleOnIGMessagesViewClose };
};

const useQuickAddProductModal = refetchProducts => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleOnCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOnCreated = newProduct => {
    refetchProducts();
    handleOnCloseModal();
  };

  return {
    showQuickAddProductModal: isModalVisible,
    onOpenQuickAddProductModal: handleOnOpenModal,
    onCloseQuickAddProductModal: handleOnCloseModal,
    handleOnProductCreated: handleOnCreated
  };
};

const invitedStores = [
  'MB',
  'ELM',
  'SS',
  'TB-',
  'EU',
  'MIC',
  'LE',
  'OSON',
  'CX',
  'JS21',
  'PLC',
  '18EB',
  'LACY',
  'LM',
  'ID',
  'HHY',
  'SML',
  'SA ',
  'NHC',
  'HCSD',
  '29',
  'VIOR',
  'SJF',
  'GFBL',
  'YNS',
  'MLS',
  'GS',
  'BM',
  'MIRA',
  'XW',
  'HHHH',
  'XXXX',
  'DUDU'
];

const MWBannerModal = ({ t, onOk }) => {
  return (
    <Modal title={t('pageOverview:mw-banner-notification-title')} visible footer={null} onCancel={() => onOk()}>
      <Image src={MWPoster} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          onClick={() => {
            window.open(
              'https://www.eventbrite.sg/e/briefing-on-momentum-works-live-commerce-immersion-hangzhou-tickets-901157033947?aff=oddtdtcreator&utm_source=ulive&utm_medium=affiliate',
              '_blank'
            );
            posthog.capture('mw_banner_click');
            onOk();
          }}
        >
          {t('pageOverview:mw-banner-notification-open-text')}
        </Button>
      </div>
    </Modal>
  );
};

const useMWBannerModal = storeShortName => {
  const [cookies, setCookie] = useCookies([NOTIFICATION_MW_BANNER]);

  const handleOnOk = () => {
    setCookie(NOTIFICATION_MW_BANNER, true, { expires: moment().add(1, 'day').toDate() });
  };

  useEffect(() => {
    if (cookies[NOTIFICATION_MW_BANNER]) {
      return;
    }
    // We can hide the modal after maintenance time
    const targetDate = moment('2024-05-14T14:00:00.000+08:00').toDate();
    const currentDate = moment().toDate();
    if (checkIsDate('after', currentDate, targetDate)) {
      setCookie(NOTIFICATION_MW_BANNER, true, { expires: moment().add(1, 'month').toDate() });
    }
  }, [cookies, setCookie, storeShortName]);

  return {
    showMWBannerModal: !cookies[NOTIFICATION_MW_BANNER] && invitedStores.includes(storeShortName),
    handleOnOk
  };
};

/* =================================================== Main Component =================================================== */
const Overview = ({
  store,
  storeCurrency,
  refetchStore,
  isStoreConnectedToFb,
  isStoreConnectedToIg,
  isMalaysiaLocation,
  checkCanUserAccess,
  storeOperatorRoles
}) => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'pageOverview']);

  const [isUpdatingPostStatus, setIsUpdatingPostStatus] = useState(false);
  const [isProductQuickEditClicked, setIsProductQuickEditClicked] = useState(false);
  const [isBlacklistQuickViewClicked, setIsBlacklistQuickViewClicked] = useState(false);
  const [shouldOpenAddLiveStreamerModal, setShouldOpenAddLiveStreamerModal] = useState(false);
  const [shouldOpenAddPostTagModal, setShouldOpenAddPostTagModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [selectedProductId, setSelectedProductId] = useState();
  const [isViewSummaryClicked, setIsViewSummaryClicked] = useState(false);
  const [productQuery, setProductQuery] = useState({ currentPage: 1, limit: 5 });
  const [orderQuery, setOrderQuery] = useState({ currentPage: 1, limit: 10 });
  const [cookies, setCookie] = useCookies([
    NOTIFICATION_WARNING_EXPIRY_PLAN,
    NOTIFICATION_WARNING_EXCEED_ORDER_LIMIT,
    NOTIFICATION_WARNING_REACHING_ORDER_LIMIT
  ]);

  const { isConstantLoading, postStatusesConst, productStatusesConst, socialMediaSourcesConst } = useFetchConstants();

  const {
    postTypesConst,
    postTypeSelections,
    isPostTypeSelectionsLoading,
    selectedPostType,
    updateSelectedPostType,

    storePostSelections,
    isStorePostSelectionsLoading,
    selectedPostId,
    updateSelectedPostId,

    isSelectedPostLoading,
    selectedPost,
    refetchSelectedPost,
    refetchStorePostSelections
  } = useStorePosts(t);

  const { isIGMessagesQuickViewClicked, handleOnIGMessageBtnClick, handleOnIGMessagesViewClose } = useIGMessages();

  const { showMWBannerModal, handleOnOk: handleOnMWBannerModalOk } = useMWBannerModal(store.shortName);

  const currencyISO = guard(() => storeCurrency.iso, '');
  const isStoreAllowStaticPostOrder = guard(() => store.isAllowStaticPostOrder, false);
  const hasValidSelectedPost = !isSelectedPostLoading && selectedPost;
  const isSelectedPostLivePost = guard(() => hasValidSelectedPost && selectedPost.type === postTypesConst.LIVE_VIDEO.code, false);
  const isSelectedPostIGLivePost = guard(
    () => hasValidSelectedPost && selectedPost.type === postTypesConst.LIVE_VIDEO.code && selectedPost.source === socialMediaSourcesConst.IG.code,
    false
  );
  const isOnLive =
    !isConstantLoading &&
    !isSelectedPostLoading &&
    selectedPost &&
    selectedPost.status !== postStatusesConst.LIVE_ENDED.code &&
    selectedPost.type === postTypesConst.LIVE_VIDEO.code;
  const shouldShowEndButton = isOnLive && moment(Date.now()).diff(selectedPost.createdAt, 'hours') > 5;
  const isPageDataLoading = isPostTypeSelectionsLoading || isStorePostSelectionsLoading || isSelectedPostLoading;
  const hasCommissionEnabled = store.subscriptionConfig.isCommissionEnabled;
  const isSubscriptionStarter = store.subscriptionConfig.isSubscriptionStarter;
  const isLoadingOrderLimitDetails = store.subscriptionConfig.isLoadingOrderLimitDetails;
  const isSubscriptionBusiness = store.subscriptionConfig.isSubscriptionBusiness;
  const isSubscriptionMonthly = store.subscriptionConfig.isSubscriptionMonthly;
  const isSubscriptionYearly = store.subscriptionConfig.isSubscriptionYearly;
  const expiryDate = store.subscriptionConfig.expiryDate;
  const starterOrderLimit = store.subscriptionConfig.starterOrderLimit;
  const starterTotalOrder = store.subscriptionConfig.starterTotalOrder;

  const isStoreRequiredInfoFilled =
    store.shortName && store.bankDetails && store.bankDetails.accountNumber && store.bankDetails.bankName && store.bankDetails.beneficiaryName;
  const isStoreConnectedToSocialMedia = isStoreConnectedToFb || isStoreConnectedToIg;
  const isAccountSetupComplete = isStoreConnectedToSocialMedia && isStoreRequiredInfoFilled;

  const isRoleAllowNotif = checkCanUserAccess([]);
  const isRoleAllowViewSensitiveInfo = checkCanUserAccess([storeOperatorRoles.ADMIN.code]);
  const isRoleAllowDownloadCSV = checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code]);

  const hasLiveStreamers = selectedPost && selectedPost.liveStreamers && selectedPost.liveStreamers.length > 0;

  const { data: postStatistic, isLoading: isPostStatisticLoading, refetch: refetchPostStatistic } = useGetPostStatistic(selectedPostId, {
    throttleRefetchTimeInMs: THROTTLE_REFETCH_TIME_MS
  });

  const { paginatedData: products, isLoading: isProductsLoading, total: productsTotal, refetch: refetchProducts } = useGetProducts(productQuery, {
    throttleRefetchTimeInMs: THROTTLE_REFETCH_TIME_MS
  });
  const { paginatedData: orders, isLoading: isOrdersLoading, total: ordersTotal, refetch: refetchOrders } = useGetOrdersByPostId(
    selectedPostId,
    orderQuery,
    { throttleRefetchTimeInMs: THROTTLE_REFETCH_TIME_MS }
  );

  const { showQuickAddProductModal, onOpenQuickAddProductModal, onCloseQuickAddProductModal, handleOnProductCreated } = useQuickAddProductModal(
    refetchProducts
  );

  const { shouldShowOrderLimitExceed } = useStoreStarterOrderLimitExceedNotification(
    history,
    t,
    isSubscriptionStarter,
    isLoadingOrderLimitDetails,
    starterOrderLimit,
    starterTotalOrder,
    cookies,
    setCookie
  );

  useStoreBusinessSubsciprtionExpiryNotification(
    history,
    t,
    isConstantLoading,
    isSubscriptionBusiness,
    isSubscriptionMonthly,
    isSubscriptionYearly,
    expiryDate,
    cookies,
    setCookie,
    isRoleAllowNotif,
    isMalaysiaLocation
  );

  useEffect(() => {
    if (store && !store.bankDetails && !Object.keys(store && store.bankDetails ? store.bankDetails : {}).length > 0) {
      notification.warning({
        key: NOTIFICATION_WARNING_ACCOUNT_SETUP,
        top: 80,
        message: <b>{t('pageOverview:notification-message')}</b>,
        btn: (
          <TextButton
            onClick={() => {
              notification.close(NOTIFICATION_WARNING_ACCOUNT_SETUP);
              history.push(getAccountProfileRoute().path);
            }}
            icon={null}
            text={
              <>
                {t('pageOverview:go-to-profile-button')} <RightOutlined />
              </>
            }
          />
        ),
        description: (
          <p>
            {t('pageOverview:notification-desc-part-1')} <CursiveText>{t('pageOverview:notification-desc-product-name')}</CursiveText>{' '}
            {t('pageOverview:notification-desc-part-2')}!
          </p>
        )
      });
    } else {
      notification.close(NOTIFICATION_WARNING_ACCOUNT_SETUP);
    }
  }, [store, history, t]);

  const handleOnNewLiveCommentWithOrder = useCallback(() => {
    refetchProducts();
    refetchOrders();
    refetchPostStatistic();
  }, [refetchProducts, refetchOrders, refetchPostStatistic]);

  const handleOnEditProductQtyClick = selectedProductId => {
    setIsProductQuickEditClicked(true);
    setSelectedProductId(selectedProductId);
  };

  const handleOnEditProductQtyClose = () => {
    setIsProductQuickEditClicked(false);
    setSelectedProductId(undefined);
  };

  const handleOnViewSummaryBtnClick = orderId => {
    setIsViewSummaryClicked(true);
    setSelectedOrderId(orderId);
  };

  const handleOnViewSummaryClose = () => {
    setIsViewSummaryClicked(false);
    setSelectedOrderId(undefined);
  };

  const handleOnBlacklistBtnClick = () => {
    setIsBlacklistQuickViewClicked(true);
  };

  const handleOnBlacklistViewClose = () => {
    setIsBlacklistQuickViewClicked(false);
  };

  const handleOnAddLiveStreamerBtnClick = () => {
    setShouldOpenAddLiveStreamerModal(true);
  };

  const handleOnAddLiveStreamerViewClose = () => {
    setShouldOpenAddLiveStreamerModal(false);
  };

  const handleOnAddPostTagBtnClick = () => {
    setShouldOpenAddPostTagModal(true);
  };

  const handleOnAddPostTagViewClose = () => {
    setShouldOpenAddPostTagModal(false);
  };

  const handleOnEndLiveBtnClick = () => {
    confirm({
      title: t('pageOverview:live-end-confirm-pop-up-title'),
      okText: t('pageOverview:live-end-confirm-pop-up-ok-button-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleOnConfirmEndLive();
      }
    });
  };

  const handleOnConfirmEndLive = () => {
    setIsUpdatingPostStatus(true);
    patchUpdatePostById(selectedPostId, { status: postStatusesConst.LIVE_ENDED.code })
      .then(() => {
        message.success(t('common:update-success-message'));
        refetchSelectedPost();
        refetchStorePostSelections();
        setIsUpdatingPostStatus(false);
      })
      .catch(ex => {
        setIsUpdatingPostStatus(false);
        error({
          title: ex.message
        });
      });
  };

  const handleOnFbDisconnectBtnClick = () => {
    confirm({
      title: t('pageOverview:fb-disconnect-confirm-pop-up-title'),
      okText: t('pageOverview:fb-disconnect-confirm-pop-up-ok-button-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return postDisconnectFbConnection().then(() => {
          message.success(t('common:update-success-message'));
          refetchStore();
        });
      }
    });
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Overview" />
        <title>{t('pageOverview:page-title')}</title>
      </Helmet>
      {showMWBannerModal && <MWBannerModal t={t} onOk={handleOnMWBannerModalOk} />}
      {shouldShowOrderLimitExceed && (
        <Alert style={{ marginBottom: '8px' }} message={t('pageOverview:starter-user-order-limit-exceed-banner-desc')} type="warning" showIcon />
      )}
      {!isAccountSetupComplete ? (
        isRoleAllowViewSensitiveInfo ? (
          <ProfileAccountSetup isStoreConnectedToSocialMedia={isStoreConnectedToFb} isStoreRequiredInfoFilled={isStoreRequiredInfoFilled} />
        ) : (
          <Alert
            showIcon
            type="warning"
            message={
              <span>
                {t('pageOverview:overview-warning-banner-desc')}{' '}
                <CursiveText>{t('pageOverview:overview-warning-banner-desc-product-name')}</CursiveText>
              </span>
            }
          />
        )
      ) : (
        <div>
          {/* ================= Header START ================= */}
          <Row justify="end" gutter={[32, 24]}>
            <Col span={24} lg={10}>
              <>
                {t('pageOverview:live-streamer-title')}
                {hasLiveStreamers ? (
                  ` ${selectedPost.liveStreamers.join(', ')}`
                ) : (
                  <TextButton
                    icon={<UsergroupAddOutlined />}
                    text={t('pageOverview:add-live-streamer-button-text')}
                    onClick={handleOnAddLiveStreamerBtnClick}
                  />
                )}
              </>
            </Col>
            <Col span={24} lg={14}>
              <Row gutter={12}>
                {isStoreAllowStaticPostOrder && (
                  <Col span={8} lg={6}>
                    <LiveTypeSelect
                      options={postTypeSelections}
                      value={selectedPostType}
                      onChange={value => {
                        updateSelectedPostType(value);
                      }}
                    />
                  </Col>
                )}
                <Col span={isStoreAllowStaticPostOrder ? 16 : 24} lg={isStoreAllowStaticPostOrder ? 18 : 24} style={{ display: 'flex' }}>
                  <LiveSessionSelect
                    options={
                      storePostSelections &&
                      storePostSelections.map(storePost => {
                        const socialMediaIconMapping = !isConstantLoading
                          ? {
                              [socialMediaSourcesConst.FB.code]: <StyledFacebookOutlined />,
                              [socialMediaSourcesConst.IG.code]: <StyledInstagramOutlined />
                            }
                          : {};
                        return {
                          label: (
                            <LiveSessionSelection>
                              {socialMediaIconMapping[storePost.source] ? socialMediaIconMapping[storePost.source] : null}
                              {storePost.title || formatToDateTimeWithDayFullString(storePost.createdAt)}
                              {storePost.isLive && <LiveSessionSelectionLiveIndicator color="#F5222D">LIVE</LiveSessionSelectionLiveIndicator>}
                            </LiveSessionSelection>
                          ),
                          value: storePost._id,
                          searchValue: `${storePost.title || formatToDateTimeWithDayFullString(storePost.createdAt)}`
                        };
                      })
                    }
                    value={selectedPostId}
                    onChange={value => {
                      updateSelectedPostId(value);
                    }}
                    optionFilterProp="searchValue"
                    showSearch={true}
                  />
                  {shouldShowEndButton && (
                    <LiveEndButton type="danger" icon={<CloseOutlined />} loading={isUpdatingPostStatus} onClick={handleOnEndLiveBtnClick}>
                      {t('pageOverview:live-end-button')}
                    </LiveEndButton>
                  )}
                </Col>
              </Row>
            </Col>
            {store.isIGMessageEnabled && (
              <>
                <Col span={0} lg={10}></Col>
                <Col span={24} lg={14}>
                  <Row justify="end">
                    <Col>
                      <TextButton onClick={handleOnIGMessageBtnClick} text={t('pageOverview:ig-stories-button')} />
                    </Col>
                    <Col>
                      <TextButton onClick={handleOnFbDisconnectBtnClick} text={t('pageOverview:fb-disconnect-button')} />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
          {/* ================= Header END ================= */}
          {/* ================= Content START ================= */}
          {isPageDataLoading ? (
            <Skeleton active={true} />
          ) : (
            <>
              {/* ================= Content Live-Related Sections START ================= */}
              <Row gutter={[24, 24]}>
                {!hasValidSelectedPost && (
                  <Col span={24} lg={10}>
                    <FullHeightCard bodyStyle={{ padding: '16px 24px', height: '100%' }}>
                      <FullHeightRow align="middle" justify="center">
                        <LivePlaceholderContainer span={24}>
                          <LivePlaceholderIcon />
                          <p>{t('pageOverview:live-video-section-no-live-post-message-1')}</p>
                          <p>{t('pageOverview:live-video-section-no-live-post-message-2')}</p>
                        </LivePlaceholderContainer>
                      </FullHeightRow>
                    </FullHeightCard>
                  </Col>
                )}
                {!isSelectedPostLivePost && selectedPost && (
                  <Col span={24} lg={10}>
                    <FullHeightCard bodyStyle={{ padding: '16px 24px', height: '100%' }}>
                      <AddPostTagSection t={t} tags={selectedPost.tags} handleOnAddPostTagBtnClick={handleOnAddPostTagBtnClick} />
                      <div style={{ height: '60vh', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <div>
                          <p>{t('pageOverview:static-post-section-title')}</p>
                          <a href={selectedPost.url} rel="noreferrer" target="_blank">
                            <LinkOutlined /> {selectedPost.title}
                          </a>
                        </div>
                        {hasValidSelectedPost && (
                          <div>
                            <PostCommentAndBumpKeywords allowCommentAction postId={selectedPostId} />
                          </div>
                        )}
                      </div>
                    </FullHeightCard>
                  </Col>
                )}
                {isSelectedPostLivePost && (
                  <Col xs={0} sm={24} lg={10}>
                    <FullHeightCard bodyStyle={{ padding: '16px 24px', height: '100%' }}>
                      <AddPostTagSection t={t} tags={selectedPost.tags} handleOnAddPostTagBtnClick={handleOnAddPostTagBtnClick} />
                      {isSelectedPostIGLivePost ? (
                        <div>
                          <p>{t('pageOverview:static-post-section-title')}</p>
                          {guard(() => !!store.socialMedia.ig.page, false) ? (
                            <a href={`https://instagram.com/${store.socialMedia.ig.page.name}/live`} rel="noreferrer" target="_blank">
                              <LinkOutlined /> {selectedPost.title}
                            </a>
                          ) : (
                            <p>{selectedPost.title}</p>
                          )}
                        </div>
                      ) : (
                        <LiveVideoContainer>
                          <LiveIframe
                            id="frameId"
                            title={t('pageOverview:live-section-video-title')}
                            src={selectedPost.liveStreamIframeUrl}
                            scrolling="no"
                            frameBorder="0"
                            allowFullScreen={true}
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                          />
                        </LiveVideoContainer>
                      )}
                      {hasValidSelectedPost && !isSelectedPostIGLivePost && (
                        <div>
                          <PostCommentAndBumpKeywords allowCommentAction postId={selectedPostId} />
                        </div>
                      )}
                    </FullHeightCard>
                  </Col>
                )}
                {isSelectedPostLivePost && isOnLive && (
                  <Col xs={24} sm={0}>
                    <Card>
                      <OnLiveContainer>
                        <OnLiveIcon /> {t('pageOverview:live-section-on-live-mobile')}
                      </OnLiveContainer>
                    </Card>
                  </Col>
                )}
                <Col span={24} lg={14}>
                  <Card bordered>
                    <FullHeightRow gutter={[16, 16]}>
                      <Col span={24} md={24} xl={8} xxl={8}>
                        <LiveStatsOrderContainer>
                          <LiveStatsLabel>{t('pageOverview:live-statistic-section-orders-title')}</LiveStatsLabel>
                          <LiveStatsValue>{hasValidSelectedPost && !isPostStatisticLoading ? postStatistic.totalOrdersCount : '-'}</LiveStatsValue>
                        </LiveStatsOrderContainer>
                      </Col>
                      {!store.isLockProfileAndRev && isRoleAllowViewSensitiveInfo && (
                        <Col span={24} md={24} xl={16} xxl={12}>
                          <LiveStatsPotentialRevenueContainer>
                            <LiveStatsLabel>{t('pageOverview:live-statistic-section-revenue-title')}</LiveStatsLabel>
                            <LiveStatsValue>
                              {hasValidSelectedPost && !isPostStatisticLoading ? (
                                <PriceDisplay amount={postStatistic && postStatistic.totalRevenue ? postStatistic.totalRevenue : 0} />
                              ) : (
                                '-'
                              )}
                            </LiveStatsValue>
                          </LiveStatsPotentialRevenueContainer>
                        </Col>
                      )}
                    </FullHeightRow>
                    <Divider />
                    {hasValidSelectedPost && guard(() => !!postStatistic.soldProducts.length, false) && (
                      <>
                        <FullHeightRow>
                          <ProductScrollSection
                            isLoading={isPostStatisticLoading}
                            isSupportPostComment={!isSelectedPostIGLivePost}
                            postData={{ _id: selectedPostId, createdAt: selectedPost.createdAt }}
                            soldProducts={postStatistic && postStatistic.soldProducts}
                            storeShortName={store.shortName}
                            storeSecurityConfig={store.securityConfig}
                            storeCurrency={storeCurrency}
                            isAllowDownloadCSV={isRoleAllowDownloadCSV}
                          />
                        </FullHeightRow>
                        <Divider style={{ marginTop: 0 }} />
                      </>
                    )}
                    <FullWidthContainer>
                      <Row justify="space-between">
                        <Col>
                          <LuckyDrawSection postId={selectedPostId} />
                        </Col>
                        <Col>
                          <TextButton
                            icon={<UsergroupDeleteOutlined />}
                            onClick={handleOnBlacklistBtnClick}
                            text={t('pageOverview:blacklist-user-button')}
                          />
                        </Col>
                      </Row>

                      <FbLiveComments
                        store={store}
                        postId={selectedPostId}
                        source={guard(() => selectedPost.source)}
                        sourcePostId={guard(() => selectedPost.sourcePostId)}
                        hasValidSelectedPost={hasValidSelectedPost}
                        shouldShowMoreComments={isOnLive}
                        isHighTrafficPost={guard(() => isOnLive && selectedPost.isHighTraffic, false)}
                        refetchSelectedPost={refetchSelectedPost}
                        onNewLiveCommentWithOrder={handleOnNewLiveCommentWithOrder}
                        onViewOrderClick={handleOnViewSummaryBtnClick}
                      />
                    </FullWidthContainer>
                  </Card>
                </Col>
              </Row>
              {/* ================= Content Live-Related Sections END ================= */}
              {/* ================= Content Product & Order Sections START ================= */}
              <Row gutter={[24, 24]}>
                {/* ================= Content Product Section START ================= */}
                <Col span={24} xl={10}>
                  <Card bordered>
                    <Row justify="space-between" style={{ marginBottom: '12px' }}>
                      <Col>
                        <Title>{t('pageOverview:product-section-title')}</Title>
                      </Col>
                      <Col>
                        <TextButton
                          icon={<PlusOutlined />}
                          onClick={onOpenQuickAddProductModal}
                          text={t('pageOverview:quick-add-new-product-button')}
                        />
                      </Col>
                    </Row>

                    <FullWidthContainer>
                      <Table
                        size="small"
                        loading={isProductsLoading || isConstantLoading}
                        rowKey={record => record._id}
                        dataSource={products}
                        columns={constructProductColumns({ t, currencyISO, productStatusesConst, handleOnEditProductQtyClick })}
                        scroll={{ x: 'max-content' }}
                        locale={{
                          triggerDesc: t('common:table-header-sort-trigger-desc'),
                          triggerAsc: t('common:table-header-sort-trigger-asc'),
                          cancelSort: t('common:table-header-sort-cancel-sort')
                        }}
                        pagination={{ total: productsTotal, defaultPageSize: 5 }}
                        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setProductQuery)}
                      />
                    </FullWidthContainer>
                  </Card>
                </Col>
                {/* ================= Content Product Section END ================= */}
                {/* ================= Content Order Section START ================= */}
                <Col span={24} xl={14}>
                  <Card bordered>
                    <Title>{t('pageOverview:order-section-title')}</Title>
                    <FullWidthContainer>
                      {hasValidSelectedPost ? (
                        <Table
                          size="small"
                          loading={isOrdersLoading}
                          rowKey={record => record._id}
                          dataSource={orders}
                          columns={constructOrderColumns({ t, handleOnViewSummaryBtnClick })}
                          scroll={{ x: 'max-content' }}
                          locale={{
                            triggerDesc: t('common:table-header-sort-trigger-desc'),
                            triggerAsc: t('common:table-header-sort-trigger-asc'),
                            cancelSort: t('common:table-header-sort-cancel-sort')
                          }}
                          pagination={{ total: ordersTotal }}
                          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setOrderQuery)}
                        />
                      ) : (
                        <span>{t('pageOverview:live-section-no-live-post-message')}</span>
                      )}
                    </FullWidthContainer>
                  </Card>
                </Col>
                {/* ================= Content Order Section END ================= */}
              </Row>
              {/* ================= Content Product & Order Sections END ================= */}
            </>
          )}
          {/* ================= Content END ================= */}
          {isViewSummaryClicked && (
            <OrderSummaryAndProductsModal
              orderId={selectedOrderId}
              onCancel={handleOnViewSummaryClose}
              postId={selectedPostId}
              storeSecurityConfig={store.securityConfig}
              storeCustomWAMessage={store.customWAMessage}
            />
          )}
          {isProductQuickEditClicked && (
            <ProductQuickUpdateModal visible={isProductQuickEditClicked} productId={selectedProductId} onClose={handleOnEditProductQtyClose} />
          )}
          {isBlacklistQuickViewClicked && <BlacklistQuickViewModal visible={isBlacklistQuickViewClicked} onClose={handleOnBlacklistViewClose} />}
          {shouldOpenAddLiveStreamerModal && (
            <AddLiveStreamerModal
              liveStreamerRemarks={selectedPost.liveStreamerRemarks}
              hasCommissionEnabled={hasCommissionEnabled}
              postId={selectedPostId}
              onClose={handleOnAddLiveStreamerViewClose}
              onSuccess={refetchSelectedPost}
            />
          )}
          {shouldOpenAddPostTagModal && (
            <AddPostTagModal
              postId={selectedPostId}
              tags={selectedPost.tags?.map(tag => tag._id)}
              onSuccess={refetchSelectedPost}
              onClose={handleOnAddPostTagViewClose}
            />
          )}
          {isIGMessagesQuickViewClicked && <IGMessagesQuickViewModal visible={isIGMessagesQuickViewClicked} onClose={handleOnIGMessagesViewClose} />}
          {showQuickAddProductModal && (
            <QuickAddProductModal
              visible={showQuickAddProductModal}
              onClose={onCloseQuickAddProductModal}
              onProductCreated={handleOnProductCreated}
            />
          )}
        </div>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(withFbContext(Overview));
