import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Card, Col, Form, message, Modal, Row, Skeleton, Table, Tabs } from 'antd';
import { CheckOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import {
  useGetStoreCustomer,
  useGetStoreCustomerOrders,
  useGetStoreCustomerPoint,
  useGetStoreCustomerOrderSummary,
  useGetStoreCustomerTierSuggestions,
  patchStoreCustomer
} from 'apis/storecustomer';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useIsLoadings } from 'hooks/utils';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { DEFAULT_PAGINATION_QUERY, customerDetailsTabsObj } from 'utils/constants';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { constructReadableNum, getLabelOfConstant, guard } from 'utils/general';
import { getCustomersRoute, getCustomerDetailsRoute, getCustomerGeneralDetailsRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterSearch,
  constructColumnFilterRadio,
  formatPaginationShowTotalDisplay,
  handleOnAggregationTableChange
} from 'utils/table/table';

import TextButton from 'components/TextButton/TextButton';
import BackButton from 'components/BackButton/BackButton';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import Title from 'components/Title/Title';
import OrderSummaryAndProductsModal from 'components/OrderSummaryAndProductsModal/OrderSummaryAndProductsModal';
import CustomerTier from 'components/CustomerTier/CustomerTier';

import PointUpdateModal from './components/PointUpdateModal/PointUpdateModal';
import PointLogsModal from './components/PointLogsModal/PointLogsModal';
import StatisticCard from './components/StatisticCard/StatisticCard';

import {
  StyledCard,
  StyledTextLink,
  TitleContainer,
  TabsContainer,
  CurrentPointsLabelText,
  CurrentPointsValueText,
  OrderProductsContainer
} from './CustomerDetails.styles';

const { useForm } = Form;
const { TabPane } = Tabs;

/* ============================================= Local Functions ============================================= */
const useFetchConstants = ({ currencyISO }) => {
  const { isLoading: isCountryCodesLoading, selection: countryCodes } = useFetchConstant('countries', {
    labelKey: 'phoneCode',
    valueKey: 'phoneCode',
    query: { currency: currencyISO }
  });

  const { isLoading: isLoadingCountries, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });

  const { isLoading: isOrderStatusesLoading, data: orderStatusesConst, selection: orderStatuses } = useFetchConstant('orderStatuses');
  const { isLoading: isFetchingConstants } = useIsLoadings([isCountryCodesLoading, isLoadingCountries, isOrderStatusesLoading]);

  return { isFetchingConstants, countryCodes, countries, orderStatuses, orderStatusesConst };
};

const useStoreCustomer = customerId => {
  const { isLoading: isLoadingCustomerDetails, data: customerDetails, refetch: refetchCustomerDetails } = useGetStoreCustomer(customerId);

  return { isLoadingCustomerDetails, initialCustomerDetails: customerDetails, refetchCustomerDetails };
};

const useStoreCustomerPoint = customerId => {
  const { isLoading: isLoadingCustomerPoint, data: customerPointData, refetch: refetchCustomerPoint } = useGetStoreCustomerPoint(customerId);

  return { isLoadingCustomerPoint, customerPoint: customerPointData?.point, refetchCustomerPoint };
};

const useStoreCustomerOrderSummary = customerId => {
  const { isLoading: isLoadingCustomerOrderSummary, data: customerOrderSummary } = useGetStoreCustomerOrderSummary(customerId);

  return { isLoadingCustomerOrderSummary, customerOrderSummary };
};

const useStoreCustomerOrders = customerId => {
  const [query, setQuery] = useState(DEFAULT_PAGINATION_QUERY);
  const { isLoading: isLoadingCustomerOrders, paginatedData, total } = useGetStoreCustomerOrders(customerId, query);

  return { isLoadingCustomerOrders, customerOrders: paginatedData, totalCustomerOrders: total, setQuery };
};

const useTab = customerId => {
  const query = useLocationQuery();
  const history = useHistory();

  const selectedTab = guard(() => query.tab);

  useEffect(() => {
    if (!selectedTab && customerId) {
      history.replace(getCustomerGeneralDetailsRoute(customerId).path);
    }
  }, [selectedTab, history, customerId]);

  const handleOnTabChange = useCallback(
    newTab => {
      if (customerId) {
        history.push(getCustomerDetailsRoute(customerId, newTab).path);
      }
    },
    [customerId, history]
  );

  return { selectedTab, handleOnTabChange };
};

/* ============================================= Local Components ============================================= */
const CustomerForm = ({ t, customerId, initialCustomerDetails, refetchCustomerDetails, countries, countryCodes }) => {
  const [form] = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchTierKeyword, setSearchTierKeyword] = useState('');
  const delaySetSearchTierKeyword = useMemo(() => debounce(setSearchTierKeyword, 300), []);

  const { isLoading: isStoreCustomerTierLoading, data: storeCustomerTiers } = useGetStoreCustomerTierSuggestions(searchTierKeyword);

  const initialCustomerDetailWithContact = useMemo(() => {
    const initialCustomerDetail = initialCustomerDetails;
    if (countryCodes) {
      if (initialCustomerDetail) {
        return {
          ...initialCustomerDetail,
          contact: {
            ...initialCustomerDetail.contact,
            countryCode: initialCustomerDetail.contact?.countryCode || countryCodes[0].value
          }
        };
      }
      return {
        contact: {
          countryCode: countryCodes[0].value
        }
      };
    }
  }, [countryCodes, initialCustomerDetails]);

  const handleOnSubmitClick = async () => {
    const values = await form.validateFields();
    setIsSubmitting(true);
    Modal.confirm({
      title: <span>{t('pageCustomerDetails:modal-update-customer-details-confirm-modal-title')}</span>,
      content: <span>{t('pageCustomerDetails:modal-update-customer-details-confirm-modal-content')}</span>,
      onOk: () =>
        patchStoreCustomer(customerId, values)
          .then(() => {
            message.success(t('pageCustomerDetails:modal-update-customer-details-confirm-modal-sucess'));
            refetchCustomerDetails();
          })
          .catch(() => {
            message.error(t('pageCustomerDetails:modal-update-customer-details-confirm-modal-error'));
          })
          .finally(() => {
            setIsSubmitting(false);
          }),
      onCancel: () => setIsSubmitting(false),
      width: 'fit-content',
      okText: t('pageCustomerDetails:modal-update-customer-details-confirm-modal-button-confirm')
    });
  };

  return (
    <Form form={form} initialValues={initialCustomerDetailWithContact} onFinish={handleOnSubmitClick}>
      <StyledCard>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <FormInput
              name="name"
              label={t('pageCustomerDetails:form-field-name-label')}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-name')}
              requiredErrorMessage={t('pageCustomerDetails:form-field-required-error-message-full-name')}
            />
          </Col>
          <Col span={12}>
            <FormInput name="sourceUserName" label={t('pageCustomerDetails:form-field-source-user-name-label')} disabled />
          </Col>
          <Col span={12}>
            <FormGroupSelectionInput
              label={t('pageCustomerDetails:form-field-contact-label')}
              inputName={['contact', 'contactNumber']}
              selectionName={['contact', 'countryCode']}
              inputType="contact"
              selections={countryCodes}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name="email"
              label={t('pageCustomerDetails:form-field-email-label')}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-email')}
            />
          </Col>
          <Col span={24}>
            <FormInput
              label={t('pageCustomerDetails:form-field-address-line-1')}
              name={['address', 'addressLine1']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-address-line-1')}
            />
          </Col>
          <Col span={24}>
            <FormInput
              label={t('pageCustomerDetails:form-field-address-line-2')}
              name={['address', 'addressLine2']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-address-line-2')}
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={t('pageCustomerDetails:form-field-address-city')}
              name={['address', 'city']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-city')}
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={t('pageCustomerDetails:form-field-address-zipcode')}
              name={['address', 'zipcode']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-zipcode')}
            />
          </Col>
          <Col span={12}>
            <FormInput
              label={t('pageCustomerDetails:form-field-address-state')}
              name={['address', 'state']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-state')}
            />
          </Col>
          <Col span={12}>
            <FormSelection
              label={t('pageCustomerDetails:form-field-address-country')}
              name={['address', 'country']}
              placeholder={t('pageCustomerDetails:form-input-placeholder-customer-country')}
              selections={countries}
            />
          </Col>
          <Col span={24}>
            <FormInput label={t('pageCustomerDetails:form-field-remarks-label')} name="remarks" type="textArea" />
          </Col>
          <Col span={24}>
            <FormSelection
              label={t('pageCustomerDetails:form-field-tier-label')}
              name="tier"
              selections={isStoreCustomerTierLoading ? [] : storeCustomerTiers}
              filterOption={false}
              isSearching={isStoreCustomerTierLoading}
              onSearch={delaySetSearchTierKeyword}
              onChange={() => delaySetSearchTierKeyword('')}
              customMarginBottom={0}
            />
          </Col>
        </Row>
      </StyledCard>
      <Row gutter={8} style={{ margin: '16px 0px' }}>
        <Col>
          <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isSubmitting} disabled={isSubmitting}>
            {t('pageCustomerDetails:form-submit-update-button-text')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const OrdersTable = ({ t, isLoadingCustomerOrders, customerOrders, totalCustomerOrders, orderStatuses, setQuery, handleOnViewSummaryBtnClick }) => {
  const constructColumns = t => [
    {
      ...constructColumn(t('pageCustomerDetails:table-header-order-number'), 'orderNumber', { width: '20%' }),
      ...constructColumnFilterSearch('orderNumber', undefined, { hasAggregationFilter: true }),
      render: (orderNumber, record) => {
        return <StyledTextLink onClick={() => handleOnViewSummaryBtnClick(record._id)}>{orderNumber}</StyledTextLink>;
      }
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-status'), 'status', { width: '10%' }),
      ...constructColumnFilterRadio('status', orderStatuses),
      render: status => getLabelOfConstant(status, orderStatuses)
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-product'), 'products', { width: '20%' }),
      render: (label, record) => {
        return (
          <OrderProductsContainer>
            {record.products.map(orderProduct => (
              <span key={orderProduct.matchingKeyword}>
                {orderProduct.matchingKeyword} x {orderProduct.quantity}
              </span>
            ))}
          </OrderProductsContainer>
        );
      }
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-total-amount'), 'totalAmount', { width: '15%', isPrice: true })
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-purchase-date'), 'purchasedDate', { width: '15%', hasAggregationSorter: true }),
      render: date => formatToDateTimeWithPMAM(date)
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-point-earned'), 'pointEarn', { width: '10%' }),
      render: point => constructReadableNum(point)
    },
    {
      ...constructColumn(t('pageCustomerDetails:table-header-point-spent'), 'pointRedeem', { width: '10%' }),
      render: point => constructReadableNum(point)
    }
  ];

  return (
    <Table
      loading={isLoadingCustomerOrders}
      rowKey={record => record._id}
      dataSource={customerOrders}
      columns={constructColumns(t)}
      scroll={{ x: 'fit-content' }}
      locale={{
        triggerDesc: t('common:table-header-sort-trigger-desc'),
        triggerAsc: t('common:table-header-sort-trigger-asc'),
        cancelSort: t('common:table-header-sort-cancel-sort')
      }}
      pagination={{
        total: totalCustomerOrders,
        showSizeChanger: true,
        showTotal: formatPaginationShowTotalDisplay
      }}
      onChange={(pagination, filters, sorter) => {
        handleOnAggregationTableChange(
          {
            pagination,
            filters,
            sorter
          },
          setQuery
        );
      }}
    />
  );
};

const StoreCustomerPointCard = ({ t, isLoadingCustomerPoint, point = 0, setIsPointUpdateModalVisible }) => {
  return (
    <Card>
      {isLoadingCustomerPoint ? (
        <Skeleton active />
      ) : (
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <CurrentPointsLabelText>{t('pageCustomerDetails:card-customer-label-current-points')}</CurrentPointsLabelText>
            <CurrentPointsValueText>{constructReadableNum(point)}</CurrentPointsValueText>
          </Col>
          <Col span={12} justify="center" style={{ textAlign: 'center' }}>
            <Button type="link" onClick={() => setIsPointUpdateModalVisible(true)}>
              <EditOutlined /> {t('pageCustomerDetails:card-customer-button-edit-text')}
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};

/* ============================================= Main Component ============================================= */
const CustomerDetails = ({ store, storeCurrency, isMobile }) => {
  const { id: customerId } = useParams();
  const { t } = useTranslation(['common', 'pageCustomerDetails']);

  const [isPointUpdateModalVisible, setIsPointUpdateModalVisible] = useState(false);
  const [isViewSummaryClicked, setIsViewSummaryClicked] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [isPointLogsModalVisible, setIsPointLogsModalVisible] = useState(false);

  const { selectedTab, handleOnTabChange } = useTab(customerId);

  const { isFetchingConstants, countryCodes, countries, orderStatuses, orderStatusesConst } = useFetchConstants({ currencyISO: storeCurrency.iso });

  const { isLoadingCustomerDetails, initialCustomerDetails, refetchCustomerDetails } = useStoreCustomer(customerId, countries);
  const { isLoadingCustomerPoint, customerPoint, refetchCustomerPoint } = useStoreCustomerPoint(customerId);
  const { isLoadingCustomerOrders, customerOrders, totalCustomerOrders, setQuery } = useStoreCustomerOrders(customerId);
  const { isLoadingCustomerOrderSummary, customerOrderSummary } = useStoreCustomerOrderSummary(customerId);

  const { isLoading } = useIsLoadings([isLoadingCustomerDetails, isFetchingConstants]);

  const handleOnUpdatePointSuccess = () => {
    refetchCustomerPoint();
    setIsPointUpdateModalVisible(false);
  };

  const handleOnViewSummaryBtnClick = orderId => {
    setIsViewSummaryClicked(true);
    setSelectedOrderId(orderId);
  };

  const handleOnViewSummaryClose = () => {
    setIsViewSummaryClicked(false);
    setSelectedOrderId(undefined);
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Customer Details" />
        <title>{t('pageCustomerDetails:page-title', { name: initialCustomerDetails.name })}</title>
      </Helmet>
      <FullWidthContainer>
        <TitleContainer>
          <Col flex="56px">
            <BackButton backLink={getCustomersRoute().path} />
          </Col>
          <Col flex="1">
            <Title>
              {initialCustomerDetails.profilePicUrl && (
                <>
                  <Avatar size="default" src={`${initialCustomerDetails.profilePicUrl}`} />{' '}
                </>
              )}
              {initialCustomerDetails.name}
              {initialCustomerDetails.source && ` (${initialCustomerDetails.source.toUpperCase()}: ${initialCustomerDetails.sourceUserName})`}
              {initialCustomerDetails.tierObj && (
                <CustomerTier emoji={initialCustomerDetails.tierObj.emoji} label={initialCustomerDetails.tierObj.label} style={{ marginLeft: 8 }} />
              )}
            </Title>
          </Col>
        </TitleContainer>
        <TabsContainer
          activeKey={selectedTab}
          destroyInactiveTabPane
          tabBarExtraContent={
            <TextButton
              onClick={() => setIsPointLogsModalVisible(true)}
              icon={<HistoryOutlined />}
              text={isMobile ? '' : t('pageCustomerDetails:modal-customer-point-log-modal-button')}
            />
          }
          onChange={handleOnTabChange}
        >
          <TabPane tab={t('pageCustomerDetails:tab-title-general')} key={customerDetailsTabsObj.GENERAL.code}>
            {
              <CustomerForm
                t={t}
                customerId={customerId}
                initialCustomerDetails={initialCustomerDetails}
                refetchCustomerDetails={refetchCustomerDetails}
                countries={countries}
                countryCodes={countryCodes}
              />
            }
          </TabPane>
          <TabPane tab={t('pageCustomerDetails:tab-title-orders-points')} key={customerDetailsTabsObj.ORDERS.code}>
            {isLoadingCustomerOrderSummary ? (
              <Skeleton active />
            ) : (
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <StatisticCard
                    title={t('pageCustomerDetails:statistic-revenue-amount')}
                    value={customerOrderSummary ? customerOrderSummary.amount : '-'}
                    pendingTitle={t('pageCustomerDetails:statistic-pending-revenue-amount')}
                    pendingValue={customerOrderSummary ? customerOrderSummary.pendingAmount : '-'}
                    isCurrency={true}
                    storeCurrency={storeCurrency}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <StatisticCard
                    title={t('pageCustomerDetails:statistic-total-number-of-orders')}
                    value={customerOrderSummary ? customerOrderSummary.numOfOrders : 0}
                    pendingTitle={t('pageCustomerDetails:statistic-total-number-of-pending-orders')}
                    pendingValue={customerOrderSummary ? customerOrderSummary.numOfPendingOrders : 0}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[16, 16]}>
              <Col span={24} md={6}>
                <StoreCustomerPointCard
                  t={t}
                  isLoadingCustomerPoint={isLoadingCustomerPoint}
                  point={customerPoint}
                  setIsPointUpdateModalVisible={setIsPointUpdateModalVisible}
                />
              </Col>
              <Col span={24} md={18}>
                <OrdersTable
                  t={t}
                  isLoadingCustomerOrders={isLoadingCustomerOrders}
                  customerOrders={customerOrders}
                  totalCustomerOrders={totalCustomerOrders}
                  orderStatuses={orderStatuses}
                  orderStatusesConst={orderStatusesConst}
                  setQuery={setQuery}
                  handleOnViewSummaryBtnClick={handleOnViewSummaryBtnClick}
                />
              </Col>
            </Row>
          </TabPane>
        </TabsContainer>
        {isPointUpdateModalVisible && (
          <PointUpdateModal
            visible
            customerId={customerId}
            point={customerPoint}
            onOk={handleOnUpdatePointSuccess}
            onCancel={() => setIsPointUpdateModalVisible(false)}
            patchStoreCustomer={patchStoreCustomer}
          />
        )}
        {isViewSummaryClicked && (
          <OrderSummaryAndProductsModal
            orderId={selectedOrderId}
            onCancel={handleOnViewSummaryClose}
            storeSecurityConfig={store.securityConfig}
            storeCustomWAMessage={store.customWAMessage}
          />
        )}
        {isPointLogsModalVisible && (
          <PointLogsModal customerId={customerId} customerName={initialCustomerDetails.name} onClose={() => setIsPointLogsModalVisible(false)} />
        )}
      </FullWidthContainer>
    </>
  );
};

export default withAppContext(CustomerDetails);
