import { useState } from 'react';
import { Button, Col, Form, message, Modal, Row, Skeleton, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import { useFetchConstant } from 'hooks/constants';
import { constructColumn } from 'utils/table/table';
import { chainPromises } from 'utils/promise';

import { InputContainer } from './../Variances.styles';

const { useForm } = Form;

export const useBulkCreateModal = () => {
  const [isBulkCreateModalVisible, setIsBulkCreateModalVisible] = useState(false);

  const handleOnBulkCreateBtnClick = () => {
    setIsBulkCreateModalVisible(true);
  };

  return {
    isBulkCreateModalVisible,
    setIsBulkCreateModalVisible,
    handleOnBulkCreateBtnClick
  };
};

const generateVariances = (specs, price, inventory, weight) => {
  const generateVariancesRecursive = (index, specs, price, inventory, weight, currentVariances, result) => {
    if (index === specs.length) {
      result.push({ label: currentVariances.join(' '), keyword: currentVariances.join('').toUpperCase(), price, inventory, weight });
    } else {
      for (let i = 0; i < specs[index].length; i++) {
        const newVariance = currentVariances.concat(specs[index][i]);
        generateVariancesRecursive(index + 1, specs, price, inventory, weight, newVariance, result);
      }
    }
  };

  const result = [];
  generateVariancesRecursive(0, specs, price, inventory, weight, [], result);
  return result;
};

const getDuplicatedKeywords = keywords => {
  const duplicatedKeywords = [];
  const uniqueKeywords = {};

  for (const keyword of keywords) {
    if (uniqueKeywords[keyword]) {
      if (uniqueKeywords[keyword] === 1) {
        duplicatedKeywords.push(keyword);
      }
      uniqueKeywords[keyword]++;
    } else {
      uniqueKeywords[keyword] = 1;
    }
  }

  return duplicatedKeywords;
};

const constructColumns = ({ t }) => [
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-label'), 'label', { width: '40%' }),
    render: (text, record, index) => {
      return (
        <>
          <InputContainer>
            <FormInput
              name={['variances', index, 'label']}
              requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-label', { itemName: 'variance name' })}
              placeholder={t('pageProductDetails:variance-cell-placeholder-product')}
            />
          </InputContainer>
        </>
      );
    }
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-keyword'), 'keyword', { width: '15%' }),
    render: (text, record, index) => (
      <InputContainer>
        <FormInput
          name={['variances', index, 'keyword']}
          type="keyword"
          requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-keyword', { itemName: 'keyword' })}
          placeholder={t('pageProductDetails:variance-cell-placeholder-keyword')}
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-inventory'), 'inventory', { width: '15%' }),
    render: (text, record, index) => (
      <InputContainer>
        <FormInputNumber
          name={['variances', index, 'inventory']}
          minValue={0}
          requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-inventory')}
          placeholder="20"
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-price'), 'price', { width: '15%', isPrice: true }),
    render: (text, record, index) => (
      <InputContainer>
        <FormInputNumber
          name={['variances', index, 'price']}
          type="financial"
          requiredErrorMessage={t('pageProductDetails:variance-cell-form-error-message-price')}
          placeholder="100.00"
        />
      </InputContainer>
    )
  },
  {
    ...constructColumn(t('pageProductDetails:variance-table-header-weight'), ['weight', 'amount'], { width: '15%' }),
    render: (text, record, index) => (
      <InputContainer>
        <FormInputNumber name={['variances', index, 'weight', 'amount']} placeholder="20" precision={2} />
      </InputContainer>
    )
  }
];

const BulkCreateModal = ({ productId, isBulkCreateModalVisible, setIsBulkCreateModalVisible, postCreateProductVariance, handleOnSaveSuccess }) => {
  const { t } = useTranslation(['pageProductDetails']);
  const [isGeneratedPreview, setIsGeneratedPreview] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(false);

  const [form] = useForm();
  const { isLoading: isLoadingProductStatuses, data: productStatusesConst } = useFetchConstant('productStatuses');

  const isLoading = isLoadingProductStatuses;

  const handleOnBulkCreateModalGenerate = async () => {
    if (!isGeneratedPreview) {
      const allFieldsValue = await form.validateFields();
      const specsArray = allFieldsValue.input.specs
        .filter(spec => !!spec)
        .map(spec => {
          return spec.split(',').map(str => str.trim());
        });

      const variancesData = generateVariances(specsArray, allFieldsValue.input.price, allFieldsValue.input.inventory, {
        amount: allFieldsValue.input.weight || 0
      });
      form.setFieldsValue({ variances: variancesData });
      setData(variancesData);
      setIsGeneratedPreview(true);
    }
  };

  const handleOnBulkCreateModalSave = async () => {
    if (!isSubmitting) {
      const allFieldsValue = await form.validateFields();
      setIsSubmitting(true);

      const duplicatedKeywords = getDuplicatedKeywords(allFieldsValue.variances.map(variance => variance.keyword.toUpperCase()));
      if (duplicatedKeywords.length > 0) {
        setIsSubmitting(false);
        return message.error({
          content: t('pageProductDetails:variance-bulk-create-modal-error-message-duplicated-keyword', { keywords: duplicatedKeywords.join(', ') })
        });
      }

      const failedVarianceKeyword = [];
      await chainPromises(
        allFieldsValue.variances.map(variance => async () => {
          try {
            await postCreateProductVariance(productId, { ...variance, status: productStatusesConst.ACTIVE.code });
            message.success({
              content: t('pageProductDetails:variance-create-success-message', { keyword: variance.keyword })
            });
          } catch (ex) {
            failedVarianceKeyword.push(variance.keyword);
            message.error({
              content: t('pageProductDetails:variance-create-fail-message', { message: ex.message, keyword: variance.keyword })
            });
          }
        })
      ).then(() => {
        if (failedVarianceKeyword.length === allFieldsValue.variances.length) {
          setIsSubmitting(false);
        } else {
          handleOnSaveSuccess();
          setIsSubmitting(false);

          if (failedVarianceKeyword.length === 0) {
            setIsBulkCreateModalVisible(false);
          } else {
            const unsaveVariances = allFieldsValue.variances.filter(variance => failedVarianceKeyword.includes(variance.keyword));
            setData(unsaveVariances);
            form.setFieldsValue({ variances: unsaveVariances });
          }
        }
      });
    }
  };

  const handleOnBulkCreateModalBack = () => {
    setIsGeneratedPreview(false);
  };

  const handleOnBulkCreateModalCancel = () => {
    setIsBulkCreateModalVisible(false);
  };

  return (
    <Modal
      width="90%"
      style={{ maxWidth: '768px' }}
      title={t('pageProductDetails:variance-bulk-create-modal-title')}
      visible={isBulkCreateModalVisible}
      onCancel={handleOnBulkCreateModalCancel}
      footer={
        <Row justify="end" gutter={[8, 8]}>
          <Col>
            <Button type="ghost" onClick={handleOnBulkCreateModalCancel}>
              {t('pageProductDetails:variance-bulk-create-modal-cancel-button')}
            </Button>
          </Col>
          {!isGeneratedPreview ? (
            <Col>
              <Button type="primary" onClick={handleOnBulkCreateModalGenerate}>
                {t('pageProductDetails:variance-bulk-create-modal-generate-button')}
              </Button>
            </Col>
          ) : (
            <>
              <Col>
                <Button type="ghost" onClick={handleOnBulkCreateModalBack}>
                  {t('pageProductDetails:variance-bulk-create-modal-back-button')}
                </Button>
              </Col>
              <Col>
                <Button type="primary" loading={isSubmitting} onClick={handleOnBulkCreateModalSave}>
                  {t('pageProductDetails:variance-bulk-create-modal-save-button')}
                </Button>
              </Col>
            </>
          )}
        </Row>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} scrollToFirstError={true} style={{ width: '100%' }}>
          {!isGeneratedPreview ? (
            <Row gutter={24}>
              <Col span={24}>
                {Array(5)
                  .fill(1)
                  .map((el, index) => (
                    <FormInput
                      key={index}
                      label={index === 0 ? t('pageProductDetails:variance-bulk-create-modal-label-specs') : null}
                      name={['input', 'specs', index]}
                      placeholder={t('pageProductDetails:variance-bulk-create-modal-placeholder-specs')}
                      requiredErrorMessage={index === 0 ? t('pageProductDetails:variance-bulk-create-modal-error-message-specs') : null}
                      customMarginBottom={index === 4 ? '24px' : '4px'}
                    />
                  ))}
              </Col>
              <Col span={24} md={8}>
                <FormInputNumber
                  label={t('pageProductDetails:variance-bulk-create-modal-label-price')}
                  name={['input', 'price']}
                  type="financial"
                  placeholder={t('pageProductDetails:variance-bulk-create-modal-placeholder-price')}
                  requiredErrorMessage={t('pageProductDetails:variance-bulk-create-modal-error-message-price')}
                />
              </Col>
              <Col span={24} md={8}>
                <FormInputNumber
                  label={t('pageProductDetails:variance-bulk-create-modal-label-inventory')}
                  name={['input', 'inventory']}
                  isAllowNegative={true}
                  minValue={0}
                  placeholder={t('pageProductDetails:variance-bulk-create-modal-placeholder-inventory')}
                  requiredErrorMessage={t('pageProductDetails:variance-bulk-create-modal-error-message-inventory')}
                />
              </Col>
              <Col span={24} md={8}>
                <FormInputNumber
                  label={t('pageProductDetails:variance-bulk-create-modal-label-weight')}
                  name={['input', 'weight']}
                  placeholder={t('pageProductDetails:variance-bulk-create-modal-placeholder-weight')}
                  precision={2}
                />
              </Col>
            </Row>
          ) : (
            <Table
              loading={isSubmitting}
              rowKey={record => record.keyword}
              size="small"
              bordered
              dataSource={data}
              columns={constructColumns({ t })}
              scroll={{ x: 576 }}
              pagination={false}
            />
          )}
        </Form>
      )}
    </Modal>
  );
};

export default BulkCreateModal;
