import api from './apiHelper';
import { useCustomPaginatedQuery, useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

export const postCreateProduct = payload => {
  return api.post('/product', payload);
};

export const postCreateBulkProducts = payload => {
  return api.post('/product/bulk', payload);
};

export const postCreateProductVariance = (productId, payload) => {
  return api.post(`/product/${productId}/variance`, payload);
};

export const useGetProducts = (query, { shouldFetch = true, onError, throttleRefetchTimeInMs } = {}) => {
  const getProducts = async (key, query) => {
    return api.get(`/product/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('products', getProducts, query, {
    onError,
    throttleRefetchTimeInMs,
    enabled: shouldFetch
  });
};

export const useGetProductSuggestions = (query, onError) => {
  const getProductSuggestions = async (key, query) => {
    return api.get(`/product/suggestions`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('products', getProductSuggestions, query, {
    onError,
    shouldDefaultEmptyObject: false,
    throttleRefetchTimeInMs: 2000
  });
};

export const getProductDetails = async (productId, query) => {
  return api.get(`/product/${productId}`, {
    params: { ...query }
  });
};

export const getProductAllKeyword = async productId => {
  return api.get(`/product/${productId}/all-keyword`);
};

export const getProductCSVData = () => {
  return api.get(`/product/csv`);
};

export const useGetProductDetails = (productId, query = {}, onError) => {
  const localGetProductDetails = async () => {
    return getProductDetails(productId, query);
  };

  return useCustomQuery('productDetails', [productId, query], localGetProductDetails, {
    onError,
    enabled: !!productId
  });
};

export const useGetProductAllKeyword = (productId, onError) => {
  const localGetProductAllKeyword = async () => {
    return getProductAllKeyword(productId);
  };

  return useCustomQuery('productAllKeyword', [productId], localGetProductAllKeyword, {
    onError,
    enabled: !!productId
  });
};

export const useGetProductPendingOrders = (productId, onError) => {
  const getProductPendingOrders = async () => {
    return api.get(`/product/${productId}/pending-orders`);
  };

  return useCustomQuery('productPendingOrders', [productId], getProductPendingOrders, {
    onError,
    shouldDefaultEmptyObject: false
  });
};

export const useGetProductItemPhotos = (productId, varianceId, shouldFetch = false, onError) => {
  const getProductItemPhotos = async () => {
    return api.get(`/product/${productId}/photos`, { params: { varianceId } });
  };

  return useCustomQuery('productDetailsPhotos', [productId, varianceId], getProductItemPhotos, {
    onError,
    shouldDefaultEmptyObject: false,
    enabled: shouldFetch
  });
};

export const useGetProductPhotos = (productId, varianceId, shouldFetch = false, onError) => {
  const getProductPhotos = async () => {
    return api.get(`/product/${productId}/photos-v2`, { params: { varianceId } });
  };

  return useCustomQuery('productDetailsPhotosV2', [productId, varianceId], getProductPhotos, {
    onError,
    shouldDefaultEmptyObject: false,
    enabled: shouldFetch
  });
};

export const useGetProductMicrositeSettings = productId => {
  const getProductMicrositeSettings = async () => {
    return api.get(`/product/${productId}/microsite-settings`);
  };

  return useCustomQuery('microsite-settings', [productId], getProductMicrositeSettings, {
    enabled: !!productId
  });
};

export const useGetProductsForMicrosite = () => {
  const getProductDetails = async () => {
    return api.get(`/product/microsite`);
  };

  return useCustomQuery('product-microsite', [], getProductDetails, { shouldDefaultEmptyObject: false });
};

export const useGetProductBulkPurchaseSettings = productId => {
  const getProductBulkPurchaseSettings = async () => {
    return api.get(`/product/${productId}/bulk-purchase`);
  };

  return useCustomQuery('bulk-purchase', [productId], getProductBulkPurchaseSettings, {
    enabled: !!productId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetProductShipmentShippingFeeTiers = (productId, shipmentId) => {
  const getProductShipmentSettings = async () => {
    return api.get(`/product/${productId}/shipment/${shipmentId}`);
  };

  return useCustomQuery('productShipment', [productId, shipmentId], getProductShipmentSettings, {
    enabled: !!productId && !!shipmentId,
    shouldDefaultEmptyObject: false
  });
};

export const useGetProductInventoryLogs = (query, { onError } = {}) => {
  const getProductInventoryLogs = async (key, query) => {
    return api.get(`/product/inventorylogs/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('productInventoryLogs', getProductInventoryLogs, query, {
    onError
  });
};

export const patchProduct = (productId, payload) => {
  return api.patch(`/product/${productId}`, payload);
};

export const patchProductVariance = (productId, varianceId, payload) => {
  return api.patch(`/product/${productId}/variance/${varianceId}`, payload);
};

export const patchMicrositeSettings = (productId, payload) => {
  return api.patch(`/product/${productId}/microsite-settings`, payload);
};

export const patchBulkPurchaseSettings = (productId, payload) => {
  return api.patch(`/product/${productId}/bulk-purchase`, payload);
};

export const patchShipmentShippingFeeTiers = (productId, shipmentId, payload) => {
  return api.patch(`/product/${productId}/shipment/${shipmentId}`, payload);
};

export const patchAllKeyword = (productId, keyword, allKeyword) => {
  return api.patch(`/product/${productId}/all-keyword`, { keyword, allKeyword });
};

export const deleteProduct = productId => {
  return api.delete(`product/${productId}`);
};

export const deleteProductVariance = (productId, varianceId) => {
  return api.delete(`product/${productId}/variance/${varianceId}`);
};

export const useRefetchProducts = () => {
  const { refetch } = useRefetchQuery(['products']);

  return refetch;
};

export const useRefetchProductDetails = () => {
  const { refetch } = useRefetchQuery(['productDetails']);

  return productId => refetch([productId]);
};
