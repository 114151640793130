import { GiftOutlined } from '@ant-design/icons';
import { Col, Row, List, Tooltip } from 'antd';

import { getFormattedContact, getLabelOfConstant, guard } from 'utils/general';
import { formatToDateTimeWithPMAM } from 'utils/date';

import ShippingFeeIcon from 'components/Icon/ShippingFeeIcon';
import BulkPurchaseIcon from 'components/Icon/BulkPurchaseIcon';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';

import {
  ContentContainer,
  DateText,
  HeaderContainer,
  InvoiceContentContainer,
  OrderInfoTitleText,
  PriceBreakdownContainer,
  PriceBreakdownItem,
  ShipmentContainer,
  ShipmentDetailContact,
  ShipmentDetailText,
  StoreInfoContainer,
  StoreNameText,
  StoreAddressText
} from './CompactInvoice.styles';

const getAddressText = ({ addressLine1, addressLine2, city, zipcode, state, country }, countries) =>
  `${addressLine1 ? addressLine1 + ' ' : ''}${addressLine2 ? addressLine2 + ', ' : ''}${zipcode || ''} ${city ? city + ', ' : ''}${
    state ? state + ', ' : ''
  }${country ? getLabelOfConstant(country, countries) + '.' : ''}`;

const formatAddressSubInfo = ({ city, zipcode, state, country }, countries) =>
  `${zipcode || ''} ${city ? city + ', ' : ''}${state ? state + ', ' : ''}${country ? getLabelOfConstant(country, countries) : ''}`;

const ShipmentInfoItem = ({ t, shipment, countries }) => {
  return (
    <Row gutter={32} justify="space-between">
      <Col span={14}>
        <ShipmentDetailText>
          <ShipmentDetailContact>
            {shipment.recipientName}
            {shipment && shipment.recipientContact && ` (${getFormattedContact(shipment.recipientContact)})`}
          </ShipmentDetailContact>
        </ShipmentDetailText>
        <ShipmentDetailText>{shipment.address && getAddressText(shipment.address, countries)}</ShipmentDetailText>
      </Col>
      <Col span={10}>
        {shipment.label && (
          <ShipmentDetailText>
            {t('pageOrderDetails:order-display-desc-label-shipment-method')}: {shipment.label}
          </ShipmentDetailText>
        )}
        {shipment.remarks && (
          <ShipmentDetailText>
            {t('pageOrderDetails:order-display-desc-label-shipment-remarks')}: <i> {shipment.remarks}</i>
          </ShipmentDetailText>
        )}
      </Col>
    </Row>
  );
};

const OrderProductList = ({ t, products, productTypesConst, totalWeightInKG }) => {
  const totalItemCount = products.reduce((totalItemCount, product) => {
    return totalItemCount + product.quantity;
  }, 0);

  return (
    <List
      size="small"
      dataSource={products}
      footer={
        <div style={{ padding: '16px 8px 0px' }}>
          <p style={{ marginBottom: '0px', padding: '0 8px', fontSize: '12px' }}>
            {t('pageOrderDetails:order-display-desc-label-total-item-count', {
              itemCount: totalItemCount
            })}
          </p>
        </div>
      }
      renderItem={(product, index) => (
        <List.Item key={product.matchingKeyword}>
          <List.Item.Meta
            title={
              <div style={{ display: 'flex' }}>
                <span>
                  {index + 1}. [{product.matchingKeyword}] {product.label}
                </span>
                <ShowShippingIndicator t={t} orderProduct={product} />
                <ShowBulkPurchaseIndicator t={t} orderProduct={product} />
                <ShowGiftIndicator t={t} orderProduct={product} productTypesConst={productTypesConst} />
              </div>
            }
            description={<span style={{ fontSize: '12px' }}>{formatToDateTimeWithPMAM(product.purchasedDate)}</span>}
          />
          <div style={{ width: '30%' }}>
            <Row justify="space-between">
              <Col>
                <span>
                  {product.quantity} x <PriceDisplay amount={product.priceDetails.price} isHideSymbol />
                </span>
              </Col>
              <Col>
                <PriceDisplay amount={product.priceDetails.totalAmount} isHideSymbol />
              </Col>
            </Row>
          </div>
        </List.Item>
      )}
    />
  );
};

const ShowShippingIndicator = ({ t, orderProduct }) => {
  const hasShippingFeeTier = guard(() => !!orderProduct.priceDetails.shippingFeeTier, false);

  return hasShippingFeeTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-shipping-fee-icon')}>
      <div>
        <ShippingFeeIcon width={20} height={20} style={{ marginLeft: '4px', marginBottom: '2px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowBulkPurchaseIndicator = ({ t, orderProduct }) => {
  const hasBulkPurchaseTier = guard(() => !!orderProduct.priceDetails.bulkPurchaseTier, false);

  return hasBulkPurchaseTier ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-bulkpurchase-icon')}>
      <div>
        <BulkPurchaseIcon width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const ShowGiftIndicator = ({ t, orderProduct, productTypesConst }) => {
  const orderProductType = guard(() => orderProduct.type, productTypesConst.NORMAL.code);

  return orderProductType === productTypesConst.GIFT.code ? (
    <Tooltip title={t('pageOrderDetails:order-display-desc-label-gift-icon')}>
      <div>
        <GiftOutlined width={16} height={16} style={{ marginLeft: '4px', marginBottom: '4px' }} />
      </div>
    </Tooltip>
  ) : null;
};

const PriceBreakdownListItem = ({ label, extraDesc, amount, isDeduct, isHighlightAmout }) => {
  return (
    <Row>
      <PriceBreakdownItem span={18}>
        <CurrencyDisplay prefix={label} />
        {extraDesc && <i>({extraDesc})</i>}
      </PriceBreakdownItem>
      <PriceBreakdownItem span={6} style={{ fontWeight: isHighlightAmout ? 'bold' : 'normal' }}>
        <PriceDisplay amount={amount} isHideSymbol isDeduct={isDeduct} />
      </PriceBreakdownItem>
    </Row>
  );
};

const CompactInvoice = ({ t, order, countries, productTypesConst }) => {
  const hasSubInfo = guard(
    () => order.store.address.city || order.store.address.zipcode || order.store.address.state || order.store.address.country,
    false
  );

  return (
    <ContentContainer>
      <HeaderContainer>
        <StoreInfoContainer>
          <StoreNameText>{order.store.name}</StoreNameText>
          {order.store.address?.addressLine1 && <StoreAddressText>{order.store.address.addressLine1}</StoreAddressText>}
          {order.store.address?.addressLine2 && <StoreAddressText>{order.store.address.addressLine2}</StoreAddressText>}
          {hasSubInfo && <StoreAddressText>{formatAddressSubInfo(order.store.address, countries)}</StoreAddressText>}
        </StoreInfoContainer>
        <h1 style={{ fontSize: '18px', marginBottom: '0px' }}>
          <b>
            {t('pageOrderDetails:pdf-invoice-title')} #{order.orderNumber}
          </b>
        </h1>
        <DateText>{formatToDateTimeWithPMAM(order.createdAt)}</DateText>
        <ShipmentContainer>
          {order.shipment && <ShipmentInfoItem t={t} shipment={order.shipment} countries={countries} />}{' '}
          <p style={{ fontSize: '12px' }}>
            <i>{order.remarks ? `"${order.remarks}"` : undefined}</i>
          </p>
        </ShipmentContainer>
      </HeaderContainer>

      <InvoiceContentContainer>
        <OrderInfoTitleText>
          <b>{t('pageOrderDetails:order-display-desc-label-order-info')}</b>
        </OrderInfoTitleText>
        {order.products && (
          <OrderProductList t={t} products={order.products} productTypesConst={productTypesConst} totalWeightInKG={order.shipment.totalWeightInKG} />
        )}
        <PriceBreakdownContainer>
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-subtotal')} amount={order.subtotalPrice} />
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-shipping-fee')} amount={order.shipment.shippingFee} />
          {!!order.shipment.productShippingFee && (
            <PriceBreakdownListItem
              label={t('pageOrderDetails:order-display-desc-label-product-shipping-fee')}
              amount={order.shipment.productShippingFee}
            />
          )}
          {order.extraFee && !!order.extraFee.amount && (
            <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-extra-fee')} amount={order.extraFee.amount} />
          )}
          {order.discount && !!order.discount.amount && (
            <PriceBreakdownListItem
              label={t('pageOrderDetails:order-display-desc-label-discount')}
              amount={order.discount.amount}
              extraDesc={order.discount.remarks}
              isDeduct
            />
          )}
          {order.promotionDetails && order.promotionDetails.promotions && order.promotionDetails.promotions.length > 0 && (
            <PriceBreakdownListItem
              label={`${t('pageOrderDetails:order-display-desc-label-promo')} (${order.promotionDetails.promotions
                .map(promotion => promotion.code)
                .join(', ')})`}
              amount={order.promotionDetails.discountAmount}
              isDeduct
            />
          )}
          {!!(order.point && order.point.redeem && order.point.redeem.discountAmount) && (
            <PriceBreakdownListItem
              label={`${t('pageOrderDetails:order-display-desc-label-points-redeemed')} (${t(
                'pageOrderDetails:order-display-desc-tooltip-point-redeemed-message',
                {
                  point: order.point.redeem.amount
                }
              )})`}
              amount={order.point.redeem.discountAmount}
              isDeduct
            />
          )}
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-total')} amount={order.totalAmount} isHighlightAmout />
          <PriceBreakdownListItem label={t('pageOrderDetails:order-display-desc-label-balance')} amount={order.balanceAmount} isHighlightAmout />
        </PriceBreakdownContainer>
        <br />
      </InvoiceContentContainer>
      <p style={{ fontSize: '12px' }}>This invoice is computer generated and no signature is required</p>
    </ContentContainer>
  );
};

export default CompactInvoice;
