import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Button, Modal } from 'antd';
import WinnerBadge from '../../images/win.png';

const neon = keyframes`
  from {
    text-shadow:
    0 0 6px rgba(25,23,67,0.92),
    0 0 30px rgba(25,23,67,0.34),
    0 0 12px rgba(254,114,18,0.52),
    0 0 21px rgba(254,114,18,0.92),
    0 0 34px rgba(254,114,18,0.78),
    0 0 54px rgba(254,114,18,0.92);
  }
  to {
    text-shadow:
    0 0 6px rgba(25,23,67,0.98),
    0 0 30px rgba(25,23,67,0.42),
    0 0 12px rgba(254,114,18,0.58),
    0 0 22px rgba(254,114,18,0.84),
    0 0 38px rgba(254,114,18,0.88),
    0 0 60px rgba(254,114,18,1);
  }
`;

const spin = keyframes`
  from{
    transform:rotate(0deg)
  }
  to {
    transform:rotate(360deg)
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
  }
`;

export const Frame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CenterTextContainer = styled.div`
  text-align: center;
`;

export const StoreName = styled.p`
  animation: ${neon} 0.08s ease-in-out infinite alternate;
  color: ${props => props.theme.color.primary};
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin: 72px 16px 0px 16px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WinnerProfile = styled.div`
  background-image: ${props => (props.image ? `url("${props.image}")` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: ${props => (props.isMultipleWinner ? '120px' : '280px')};
  position: relative;
  width: ${props => (props.isMultipleWinner ? '120px' : '280px')};
`;

export const WinnerBanner = styled.div`
  background-image: url(${WinnerBadge});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  height: ${props => (props.isMultipleWinner ? '160px' : '360px')};
  left: 172;
  top: ${props => (props.isMultipleWinner ? '-22px' : '-40px')};
  position: absolute;
  width: ${props => (props.isMultipleWinner ? '160px' : '360px')};

  animation: ${spin} 5s linear infinite;
`;

export const WinnerName = styled.p`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.fontFamily.title};
  font-size: ${props => (props.isMultipleWinner ? '24px' : '48px')};
  line-height: 108px;
  margin: 16px 0 24px 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 0 6px rgba(25, 23, 67, 0.98), 0 0 30px rgba(25, 23, 67, 0.42), 0 0 12px rgba(254, 114, 18, 0.58), 0 0 22px rgba(254, 114, 18, 0.84),
    0 0 38px rgba(254, 114, 18, 0.88), 0 0 60px rgba(254, 114, 18, 1);
  white-space: nowrap;
`;

export const FooterText = styled.p`
  color: ${props => props.theme.color.white};
  font-size: 16px;
  margin-top: 48px;
  text-align: center;
  width: 100%;
`;

export const CursiveText = styled.span`
  font-family: ${props => props.theme.fontFamily.title};
  font-size: 24px;
`;

export const ActionButton = styled(Button)`
  font-size: 24px !important;
  height: 100% !important;
`;
