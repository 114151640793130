import { Tooltip } from 'antd';
import { StyledButton } from './TextButton.styles';

const TextButton = ({ color = 'default', disabled, icon, onClick, text, tooltipMessage, htmlType, isWordBreak, size, style }) => {
  return tooltipMessage ? (
    <Tooltip placement="top" title={tooltipMessage}>
      <StyledButton
        size={size}
        color={color}
        icon={icon}
        onClick={onClick}
        type="link"
        disabled={disabled}
        htmlType={htmlType}
        style={style}
        isWordBreak={isWordBreak}
      >
        {text}
      </StyledButton>
    </Tooltip>
  ) : (
    <StyledButton
      size={size}
      color={color}
      icon={icon}
      onClick={onClick}
      type="link"
      disabled={disabled}
      htmlType={htmlType}
      style={style}
      isWordBreak={isWordBreak}
    >
      {text}
    </StyledButton>
  );
};

export default TextButton;
