import styled from '@emotion/styled';
import { CheckCircleOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: #52c41a !important;
`;

export const OrderSummaryModal = styled(Modal)`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 70vw !important;
  }
`;

export const ContentContainer = styled.div`
  width: 576px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  color: ${props => props.theme.color.socialMediaFB};
`;

export const StyledInstagramOutlined = styled(InstagramOutlined)`
  color: ${props => props.theme.color.socialMediaIG};
`;
