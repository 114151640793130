import { useEffect } from 'react';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Shipments from './components/Shipments/Shipments';
import MicrositeSettings from './components/MicrositeSettings/MicrositeSettings';
import Promotions from './components/Promotions/Promotions';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useLocationQuery } from 'hooks/router';
import { guard } from 'utils/general';
import { settingTabsObj } from 'utils/constants';
import { getSettingsRoute, getShipmentsRoute } from 'utils/routes';

import { TabsContainer } from './Settings.styles';

const { TabPane } = Tabs;

const useTab = () => {
  const query = useLocationQuery();
  const history = useHistory();
  const selectedTab = guard(() => query.tab);

  useEffect(() => {
    if (!selectedTab) {
      history.replace(getShipmentsRoute().path);
    }
  }, [selectedTab, history]);

  const handleOnTabChange = newTab => {
    history.push(getSettingsRoute(newTab).path);
  };

  return { selectedTab, handleOnTabChange };
};

const Settings = ({ store }) => {
  const { t } = useTranslation(['pageShipmentDetails', 'pageMicrositeSettings', 'pagePromotion']);
  const { selectedTab, handleOnTabChange } = useTab();
  const isMicrositeEnabled = guard(() => store.subscriptionConfig.isMicrositeEnabled);

  return (
    <TabsContainer activeKey={selectedTab} onChange={handleOnTabChange}>
      <TabPane tab={t('pageShipment:title')} key={settingTabsObj.SHIPMENT.code}>
        {selectedTab === settingTabsObj.SHIPMENT.code ? (
          <Helmet>
            <meta name="title" id="gtm-title" content="Settings | Shipments" />
            <title>{t('pageShipment:page-title')}</title>
          </Helmet>
        ) : undefined}
        <Shipments />
      </TabPane>
      {isMicrositeEnabled && (
        <TabPane tab={t('pageMicrositeSettings:title')} key={settingTabsObj.MICROSITE.code}>
          {selectedTab === settingTabsObj.MICROSITE.code ? (
            <Helmet>
              <meta name="title" id="gtm-title" content="Settings | Microsite" />
              <title>{t('pageMicrositeSettings:page-title')}</title>
            </Helmet>
          ) : undefined}
          <MicrositeSettings />
        </TabPane>
      )}
      <TabPane tab={t('pagePromotion:title')} key={settingTabsObj.PROMOTION.code}>
        {selectedTab === settingTabsObj.PROMOTION.code ? (
          <Helmet>
            <meta name="title" id="gtm-title" content="Promotions" />
            <title>{t('pagePromotion:page-title')}</title>
          </Helmet>
        ) : undefined}
        <Promotions />
      </TabPane>
    </TabsContainer>
  );
};

export default withAppContext(Settings);
