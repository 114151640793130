import styled from '@emotion/styled';
import { TagOutlined, VideoCameraAddOutlined, VideoCameraOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Select, Tag } from 'antd';

import ImagePreview from 'components/ImagePreview/ImagePreview';

export const LiveSessionSelection = styled.div``;

export const LiveSessionSelectionLiveIndicator = styled(Tag)`
  margin-left: 4px;
`;

export const LiveTypeSelect = styled(Select)`
  width: 100%;
`;

export const LiveSessionSelect = styled(Select)`
  width: 0;
  flex: 1 0 auto;
`;

export const LiveEndButton = styled(Button)`
  margin-left: 8px;
`;

export const FullHeightCard = styled(Card)`
  height: 100%;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const LiveVideoContainer = styled.div`
  display: none;
  height: 60vh;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    height: 50vh;
  }

  @media (min-width: 1024px) {
    height: 60vh;
  }
`;

export const LiveIframe = styled.iframe`
  border: none;
  overflow: hidden;
  width: fit-content;
  height: 100%;
`;

export const LivePlaceholderContainer = styled(Col)`
  text-align: center;
  font-size: 16px;
`;

export const CursiveText = styled.span`
  font-family: Pacifico, 'cursive';
  font-size: 16px;
`;

export const TooltipIcon = styled(TagOutlined)`
  cursor: pointer;
  color: ${props => props.theme.color.lightTertiary};
`;

export const LivePlaceholderIcon = styled(VideoCameraAddOutlined)`
  color: ${props => props.theme.color.secondary} !important;
  font-size: ${props => props.theme.spacing.xxl};
  margin-bottom: ${props => props.theme.spacing.md};
`;

export const OnLiveContainer = styled.p`
  margin-bottom: 0px;
  text-align: center;
`;

export const OnLiveIcon = styled(VideoCameraOutlined)`
  color: ${props => props.theme.color.secondary} !important;
`;

export const LiveStatsOrderContainer = styled(Card)`
  border: 1px solid ${props => props.theme.color.secondary} !important;
  color: ${props => props.theme.color.white} !important;
  background-color: ${props => props.theme.color.secondary};
  text-align: center;
`;

export const LiveStatsPotentialRevenueContainer = styled(Card)`
  border: 1px solid ${props => props.theme.color.tertiary} !important;
  color: ${props => props.theme.color.white} !important;
  background-color: ${props => props.theme.color.tertiary};
  text-align: center;
`;

export const LiveStatsLabel = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const LiveStatsValue = styled.p`
  font-size: 32px;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: 0;
`;

export const LiveOrdersProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImagePreview = styled(ImagePreview)`
  height: 48px;
  width: 48px;
`;

export const StyledFacebookOutlined = styled(FacebookOutlined)`
  color: ${props => props.theme.color.socialMediaFB};
  margin-right: 4px;
`;

export const StyledInstagramOutlined = styled(InstagramOutlined)`
  color: ${props => props.theme.color.socialMediaIG};
  margin-right: 4px;
`;
