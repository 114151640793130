import { useCallback, useMemo } from 'react';
import { ExportOutlined, TeamOutlined, UserOutlined, PayCircleOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, List, Menu, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { storeCurrencyToLocaleConfigMapping } from 'i18n';

import { REACT_APP_IS_HIDE_SUBS_UI } from 'config/env';

import { withAppContext } from 'contexts/AppContext/AppContext';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';
import { useFetchConstant } from 'hooks/constants';

import { getAccountProfileRoute, getSubscriptionPlanRoute, getTeamRoute } from 'utils/routes';
import { guard } from 'utils/general';
import { formatToDateString, addMoment } from 'utils/date';
import { useIsLoadings } from 'hooks/utils';

import {
  AccountInfoMenuItem,
  AvatarCol,
  HeaderRow,
  ProfileNameCol,
  ProfileEmailText,
  StyledProfileStoreDetailsCard,
  StyledStoreDetailsTitleText,
  StyledStoreDetailsDescText,
  ProfileStoreDetailsListItem,
  ProfileStoreDetailsTitle,
  ProfileStoreDetailsButtonContainer,
  RedirectExtPlanPageBtn
} from './Header.styles';

const { Item: MenuItem } = Menu;
const { Item: ListItem } = List;

/* =================================================== Local Functions =================================================== */
const useFetchConstants = () => {
  const { data: subscriptionAddOnsConst, isLoading: isLoadingsubscriptionAddOns } = useFetchConstant('subscriptionAddOns');
  const { data: subscriptionPeriodsConst, isLoading: isLoadingSubscriptionPeriods } = useFetchConstant('subscriptionPeriods');

  const { isLoading: isLoadingConstants } = useIsLoadings([isLoadingsubscriptionAddOns, isLoadingSubscriptionPeriods]);

  return { isLoadingConstants, subscriptionAddOnsConst, subscriptionPeriodsConst };
};

/* =================================================== Local Components =================================================== */
const StoreDetails = ({
  t,
  subscriptionAddOnsConst,
  subscriptionPeriodsConst,
  isMicrositeEnabled,
  isCustomerEnabled,
  isSubscriptionStarter,
  isSubscriptionMonthly,
  isSubscriptionYearly,
  shouldHideUpgrade,
  starterTotalOrder,
  starterOrderLimit,
  expiryDateDisplayInString,
  onClickButtonPlan
}) => {
  const subscriptionPeriodLabel = useMemo(
    () =>
      isSubscriptionMonthly
        ? subscriptionPeriodsConst.MONTHLY.label
        : isSubscriptionYearly
        ? subscriptionPeriodsConst.YEARLY.label
        : subscriptionPeriodsConst.HALF_YEAR.label,
    [subscriptionPeriodsConst, isSubscriptionMonthly, isSubscriptionYearly]
  );

  const constructAccountSubscriptionPlan = ({ isStarterPlan, subscriptionPeriodLabel }) => {
    switch (true) {
      case isStarterPlan:
        return {
          title: t('layoutStandard:subscription-card-starter-plan-title-text'),
          contents: [
            {
              title: t('layoutStandard:subscription-card-starter-plan-order-limit'),
              desc: `${starterTotalOrder} / ${starterOrderLimit}`
            },
            {
              title: t('layoutStandard:subscription-card-starter-plan-usage-reset'),
              desc: expiryDateDisplayInString
            }
          ],
          buttonRedirectPlanPage: t('layoutStandard:subscription-card-starter-plan-button-text'),
          buttonRedirectExternalPlanPage: t('layoutStandard:subscription-card-starter-plan-link-text')
        };

      default:
        return {
          title: t('layoutStandard:subscription-card-business-plan-title-text', {
            subscriptionPeriod: subscriptionPeriodLabel
          }),
          contents: [
            {
              title: t('layoutStandard:subscription-card-business-plan-add-ons'),
              desc: `${isMicrositeEnabled || isCustomerEnabled ? subscriptionAddOnsConst.MICROSITE_CUSTOMER.label : '-'}`
            },
            {
              title: t('layoutStandard:subscription-card-business-plan-expires-on'),
              desc: expiryDateDisplayInString
            }
          ],
          buttonRedirectPlanPage: t('layoutStandard:subscription-card-business-plan-button-text'),
          buttonRedirectExternalPlanPage: t('layoutStandard:subscription-card-business-plan-link-text')
        };
    }
  };

  const { title, contents, buttonRedirectPlanPage, buttonRedirectExternalPlanPage } = constructAccountSubscriptionPlan({
    isStarterPlan: isSubscriptionStarter,
    subscriptionPeriodLabel
  });

  return (
    <StyledProfileStoreDetailsCard title={<ProfileStoreDetailsTitle>{title}</ProfileStoreDetailsTitle>} size="small" bodyStyle={{}}>
      <List
        size="small"
        dataSource={contents}
        renderItem={content => (
          <ProfileStoreDetailsListItem>
            <ListItem.Meta title={<StyledStoreDetailsTitleText>{content.title}</StyledStoreDetailsTitleText>} />
            <StyledStoreDetailsDescText>{content.desc}</StyledStoreDetailsDescText>
          </ProfileStoreDetailsListItem>
        )}
      />
      {!shouldHideUpgrade && (
        <ProfileStoreDetailsButtonContainer>
          <Button type="primary" block onClick={onClickButtonPlan}>
            {buttonRedirectPlanPage}
          </Button>
          <RedirectExtPlanPageBtn type="link" onClick={() => window.open('https://ulive.me/pricing', '_blank')} style={{ paddingBottom: 0 }}>
            {buttonRedirectExternalPlanPage}
          </RedirectExtPlanPageBtn>
        </ProfileStoreDetailsButtonContainer>
      )}
    </StyledProfileStoreDetailsCard>
  );
};

/* =================================================== Main Component =================================================== */
const Header = ({ user, store, storeCurrency, storeOperatorRoles, isMalaysiaLocation, onLogout }) => {
  const { t } = useTranslation(['layoutStandard']);
  const history = useHistory();

  const { isLoadingConstants, subscriptionAddOnsConst, subscriptionPeriodsConst } = useFetchConstants();

  const userName = guard(() => user.name);
  const localeConfig = guard(() => storeCurrencyToLocaleConfigMapping[storeCurrency.iso], {});
  const storeLogoUrl = guard(() => store.logoV2.thumbnail.url) || guard(() => store.logo.url);
  const hasSubscriptionConfig = store.subscriptionConfig.hasSubscriptionConfig;
  const isCommissionEnabled = store.subscriptionConfig.isCommissionEnabled;
  const isMicrositeEnabled = store.subscriptionConfig.isMicrositeEnabled;
  const isCustomerEnabled = store.subscriptionConfig.isCustomerEnabled;
  const isSubscriptionStarter = store.subscriptionConfig.isSubscriptionStarter || store.subscriptionConfig.isSubscriptionPaidStarter;
  const isSubscriptionMonthly = store.subscriptionConfig.isSubscriptionMonthly;
  const isSubscriptionYearly = store.subscriptionConfig.isSubscriptionYearly;
  const expiryDateDisplayInString = isSubscriptionStarter
    ? formatToDateString(addMoment(store.subscriptionConfig.expiryDate, 1, 'minute'))
    : formatToDateString(store.subscriptionConfig.expiryDate);
  const starterTotalOrder = store.subscriptionConfig.starterTotalOrder;
  const starterOrderLimit = store.subscriptionConfig.starterOrderLimit;
  const isStoreOwnerRole = guard(() => user.role === storeOperatorRoles.OWNER.code, false);

  const handleOnClickButtonPlan = useCallback(() => {
    history.push(getSubscriptionPlanRoute().path);
  }, [history]);

  const handleOnClickAccountProfile = useCallback(() => {
    history.push(getAccountProfileRoute().path);
  }, [history]);

  const handleOnClickTeam = useCallback(() => {
    history.push(getTeamRoute().path);
  }, [history]);

  const handleOnClickPlan = useCallback(() => {
    history.push(getSubscriptionPlanRoute().path);
  }, [history]);

  const handleOnClickLogout = useCallback(() => {
    onLogout();
  }, [onLogout]);

  const menu = (
    <Menu style={{ minWidth: '292px', marginRight: '16px' }}>
      <AccountInfoMenuItem key="accountInfo">
        <Row gutter={8} align="middle" style={{ minHeight: '72px' }}>
          <Col style={{ height: '72px' }}>
            <ProfileAvatar size={48} src={storeLogoUrl} fallbackName={userName} />
          </Col>
          <Col>
            <ProfileNameCol>
              <span>{store.name}</span>
            </ProfileNameCol>
            <span>{user.name}</span>
            <br />
            {user.email && <ProfileEmailText>{user.email}</ProfileEmailText>}
          </Col>
        </Row>
      </AccountInfoMenuItem>
      <Menu.Divider />

      {!REACT_APP_IS_HIDE_SUBS_UI && !isLoadingConstants && hasSubscriptionConfig && isStoreOwnerRole && (
        <>
          <StoreDetails
            t={t}
            subscriptionAddOnsConst={subscriptionAddOnsConst}
            subscriptionPeriodsConst={subscriptionPeriodsConst}
            isMicrositeEnabled={isMicrositeEnabled}
            isCustomerEnabled={isCustomerEnabled}
            isSubscriptionStarter={isSubscriptionStarter}
            isSubscriptionMonthly={isSubscriptionMonthly}
            isSubscriptionYearly={isSubscriptionYearly}
            shouldHideUpgrade={!isMalaysiaLocation}
            starterTotalOrder={starterTotalOrder}
            starterOrderLimit={starterOrderLimit}
            expiryDateDisplayInString={expiryDateDisplayInString}
            onClickButtonPlan={handleOnClickButtonPlan}
          />
          <Menu.Divider />
        </>
      )}

      <MenuItem key="accountProfile" onClick={handleOnClickAccountProfile}>
        <UserOutlined /> {t('layoutStandard:avatar-menu-item-account-profile')}
      </MenuItem>
      <Menu.Divider />
      {isCommissionEnabled && isStoreOwnerRole ? (
        <>
          <MenuItem key="team" onClick={handleOnClickTeam}>
            <TeamOutlined /> {t('layoutStandard:avatar-menu-item-account-team')}
          </MenuItem>
          <Menu.Divider />
        </>
      ) : undefined}
      {!REACT_APP_IS_HIDE_SUBS_UI && isMalaysiaLocation && isStoreOwnerRole && (
        <>
          <MenuItem key="plans" onClick={handleOnClickPlan}>
            <PayCircleOutlined /> {t('layoutStandard:avatar-menu-item-account-plans')}
          </MenuItem>
          <Menu.Divider />
        </>
      )}

      <MenuItem key="logOut" onClick={handleOnClickLogout}>
        <ExportOutlined /> {t('layoutStandard:avatar-menu-item-account-logout')}
      </MenuItem>
    </Menu>
  );

  return (
    <HeaderRow justify="end" align="middle" gutter={48}>
      <LanguageSwitcher localesToShow={localeConfig.locales || []} />
      <Dropdown overlay={menu} trigger="click">
        <AvatarCol>
          <ProfileAvatar size={48} src={storeLogoUrl} fallbackName={userName} />
        </AvatarCol>
      </Dropdown>
    </HeaderRow>
  );
};

export default withAppContext(Header);
