import { useEffect, useState } from 'react';
import { Alert, Modal, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { constructDisplayPriceWithCurrency, constructReadableNum } from 'utils/general';
import { CURRENCY_FORMAT } from 'utils/constants';

const PointsModal = ({
  storeCurrency,
  subtotalPrice,
  totalDiscountAmount,
  shippingFee,
  successOrderPaymentAmount,
  redeemRate,
  redeemedPoints,
  currentPoints,
  onReedemPoints,
  onCancel
}) => {
  const { t } = useTranslation(['common', 'pageCheckout']);
  const [localRedeemedPoints, setLocalRedeemedPoints] = useState();
  const baseCurrencyMultiplier = CURRENCY_FORMAT[storeCurrency.iso].baseCurrencyMultiplier;
  const currencyDecimal = CURRENCY_FORMAT[storeCurrency.iso].decimal;

  useEffect(() => {
    const priceAfterOtherDiscount = subtotalPrice - totalDiscountAmount + redeemedPoints.discountAmount;
    const orderAmountToPay = (priceAfterOtherDiscount + shippingFee - successOrderPaymentAmount).toFixed(currencyDecimal);
    const redeemableProductAmount = priceAfterOtherDiscount < orderAmountToPay ? priceAfterOtherDiscount : orderAmountToPay;

    const pointDiscountWithoutDecimal = Math.floor(
      (currentPoints / redeemRate) * baseCurrencyMultiplier > redeemableProductAmount
        ? redeemableProductAmount
        : (currentPoints / redeemRate) * baseCurrencyMultiplier
    );
    // To make sure point doesn't get decimal points
    const maxRedeemablePoints = Math.ceil((pointDiscountWithoutDecimal * redeemRate) / baseCurrencyMultiplier);

    setLocalRedeemedPoints({
      amount: maxRedeemablePoints,
      discountAmount: pointDiscountWithoutDecimal
    });
  }, [
    currentPoints,
    redeemRate,
    subtotalPrice,
    totalDiscountAmount,
    shippingFee,
    successOrderPaymentAmount,
    redeemedPoints,
    baseCurrencyMultiplier,
    currencyDecimal
  ]);

  const onHandleReedemPoints = () => {
    onReedemPoints(localRedeemedPoints);
  };

  const canRedeem = localRedeemedPoints && localRedeemedPoints.amount > 0;

  return (
    <Modal
      visible
      title={t('pageCheckout:points-modal-title')}
      footer={canRedeem ? undefined : null}
      okText={t('pageCheckout:points-modal-button-confirm')}
      cancelText={t('common:modal-cancel-text')}
      onOk={onHandleReedemPoints}
      onCancel={onCancel}
      closable={!canRedeem}
    >
      {!localRedeemedPoints ? (
        <Skeleton active />
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            <b style={{ fontSize: '24px' }}>{constructReadableNum(currentPoints)}</b>
            <p>{t('pageCheckout:points-modal-desc-available-points')}</p>
          </div>
          <hr style={{ borderTop: '0px' }} />
          <br />
          <Alert
            description={
              !canRedeem ? (
                <span>{t('pageCheckout:points-modal-info-not-enough-points')}</span>
              ) : (
                <>
                  <span>
                    {t('pageCheckout:points-modal-info-message-redeem-point-amount')}{' '}
                    <b>
                      {`${constructReadableNum(localRedeemedPoints?.amount)}pts`} (
                      {constructDisplayPriceWithCurrency({
                        amount: localRedeemedPoints?.discountAmount,
                        currencySymbol: storeCurrency.symbol,
                        currencyISO: storeCurrency.iso
                      })}
                      )
                    </b>
                  </span>
                  <br />
                  <span>
                    {t('pageCheckout:points-modal-info-message-new-available-points')}{' '}
                    <b>{`${constructReadableNum(currentPoints - localRedeemedPoints?.amount)}pts`}</b>
                  </span>
                </>
              )
            }
            type="warning"
            showIcon
          />
        </>
      )}
    </Modal>
  );
};

export default PointsModal;
