import { useCustomQuery, useRefetchQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const postPublishCommentToPost = (postId, { message }) => {
  return api.post(`/post/${postId}/comment`, { message });
};

export const postPublishSoldProductsComment = async (postId, productVarianceId) => {
  return api.post(`/post/${postId}/sold-product/${productVarianceId}/comment`);
};

export const patchUpdatePostHighTraffic = async postId => {
  return api.patch(`/post/${postId}/high-traffic`);
};

export const patchUpdatePostById = async (postId, payload) => {
  return api.patch(`/post/${postId}`, payload);
};

export const useGetCurrentStorePostsMinimal = (postType, postProcessFunc, onError) => {
  const getStorePostsMinimal = async () => {
    return api.get(`/post/store/minimal/${postType}`);
  };

  return useCustomQuery('currentStorePostsMinimal', [postType], getStorePostsMinimal, {
    shouldDefaultEmptyObject: false,
    enabled: !!postType,
    postProcessFunc,
    onError
  });
};

export const useGetPost = (postId, { postProcessFunc, onError } = {}) => {
  const getPostComments = async () => {
    return api.get(`/post/${postId}`);
  };

  return useCustomQuery('post', [postId], getPostComments, {
    shouldDefaultEmptyObject: false,
    enabled: !!postId,
    postProcessFunc,
    onError
  });
};

export const useGetPaginatedPostComments = (postId, query, { onError } = {}) => {
  const getPostComments = async (key, query) => {
    return api.get(`/post/${postId}/comments/paginated`, { params: { ...query } });
  };

  return useCustomPaginatedQuery('postComments', getPostComments, query, {
    extraKeys: [postId],
    enabled: !!postId,
    onError
  });
};

export const useGetPostStatistic = (postId, { onError, throttleRefetchTimeInMs } = {}) => {
  const getPostRevenue = async () => {
    return api.get(`/post/${postId}/statistic`);
  };

  return useCustomQuery('postStatistic', [postId], getPostRevenue, {
    shouldDefaultEmptyObject: false,
    enabled: !!postId,
    onError,
    throttleRefetchTimeInMs
  });
};

export const useGetPostSoldProductStickers = (postId, productId) => {
  const getPostSoldProductStickers = async () => {
    return api.get(`/post/${postId}/sold-product/${productId}/stickers`);
  };

  return useCustomQuery('postSoldProductStickers', [postId, productId], getPostSoldProductStickers, {
    shouldDefaultEmptyObject: false,
    enabled: !!postId && !!productId
  });
};

export const useRefetchPostStatistic = () => {
  const { refetch } = useRefetchQuery(['postStatistic']);

  return refetch;
};
