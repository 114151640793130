import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Form, message, Modal, Row, Space, Tag } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { patchOrderSplit } from 'apis/order';

import FormSelection from 'components/FormSelection/FormSelection';

import { RootRow } from './OrderSplitModal.styles';

const { useForm } = Form;

/* =================================================== Exported Functions =================================================== */
export const useOrderSplitModal = () => {
  const [isOrderSplitModalVisible, setIsOrderSplitModalVisible] = useState(false);

  const handleOnSplitOrderBtnClick = useCallback(() => {
    setIsOrderSplitModalVisible(true);
  }, []);

  const handleOnSplitOrderModalCancel = useCallback(() => {
    setIsOrderSplitModalVisible(false);
  }, []);

  return { isOrderSplitModalVisible, handleOnSplitOrderBtnClick, handleOnSplitOrderModalCancel };
};

/* =================================================== Main Component =================================================== */
const OrderSplitModal = ({ orderStatuses, orderId, orderStatus, orderProducts, onSplitOrderSuccess, onCancel }) => {
  const { t } = useTranslation(['pageOrderDetails']);
  const [form] = useForm();

  const [isSplitting, setIsSplitting] = useState(false);

  const orderProductsToSplitOptions = useMemo(
    () =>
      orderProducts.map(product => ({
        ...product,
        value: product._id,
        label: `[${product.matchingKeyword}] ${product.label} x ${product.quantity}`
      })),
    [orderProducts]
  );

  const handleOnConfirm = useCallback(() => {
    form.validateFields().then(({ newStatus, orderProductIdsToSplit }) => {
      if (orderProducts.length === orderProductIdsToSplit.length) {
        message.error(t('pageOrderDetails:modal-split-order-select-confirm-modal-error-split-all-products'));
        return;
      }

      setIsSplitting(true);

      Modal.confirm({
        title: <span>{t('pageOrderDetails:modal-split-order-select-confirm-modal-title')}</span>,
        content: <span>{t('pageOrderDetails:modal-split-order-select-confirm-modal-content')}</span>,
        onOk: () =>
          patchOrderSplit(orderId, { newStatus, orderProductIdsToSplit })
            .then(() => {
              message.success(t('pageOrderDetails:modal-split-order-select-confirm-modal-sucess'));
              onSplitOrderSuccess();
            })
            .catch(() => {
              message.error(t('pageOrderDetails:modal-split-order-select-confirm-modal-error'));
            })
            .finally(() => {
              setIsSplitting(false);
            }),
        onCancel: () => setIsSplitting(false),
        width: 'fit-content',
        okText: t('pageOrderDetails:modal-split-order-select-confirm-modal-button-confirm'),
        cancelText: t('common:modal-cancel-text')
      });
    });
  }, [t, orderId, orderProducts.length, form, onSplitOrderSuccess]);

  return (
    <Modal
      visible
      width="60%"
      okText={t('pageOrderDetails:modal-split-order-ok-button-text')}
      cancelText={t('pageOrderDetails:modal-split-order-cancel-button-text')}
      confirmLoading={isSplitting}
      cancelButtonProps={{ loading: isSplitting }}
      onOk={handleOnConfirm}
      onCancel={onCancel}
    >
      <Alert
        type="warning"
        style={{ marginBottom: 20 }}
        message={
          <Space direction="vertical">
            <Row align="middle">
              <Tag color="#ffba00">
                <b>
                  <InfoCircleOutlined />
                </b>
              </Tag>
              <span>
                <b>{t('pageOrderDetails:modal-split-order-note')}:</b>
              </span>
            </Row>
            <ul>
              <li>{t('pageOrderDetails:modal-split-order-note-desc-1')}</li>
              <li>{t('pageOrderDetails:modal-split-order-note-desc-2')}</li>
            </ul>
          </Space>
        }
      />
      <Form form={form} initialValues={{ newStatus: orderStatus }}>
        <RootRow>
          <Col span={24}>
            <FormSelection
              name="newStatus"
              label={t('pageOrderDetails:modal-split-order-select-status-label')}
              placeholder={t('pageOrderDetails:modal-split-order-select-status-placeholder')}
              selections={orderStatuses}
              requiredErrorMessage={t('pageOrderDetails:modal-split-order-select-status-required-error-message')}
            />
          </Col>
          <Col span={24}>
            <FormSelection
              name="orderProductIdsToSplit"
              label={t('pageOrderDetails:modal-split-order-select-products-label')}
              placeholder={t('pageOrderDetails:modal-split-order-select-products-placeholder')}
              isMultiple
              selections={orderProductsToSplitOptions}
              requiredErrorMessage={t('pageOrderDetails:modal-split-order-select-products-required-error-message')}
            />
          </Col>
        </RootRow>
      </Form>
    </Modal>
  );
};

export default OrderSplitModal;
