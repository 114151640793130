import React, { useState } from 'react';
import { PlusOutlined, CheckOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, message, Space, Table, Modal, Skeleton, Form, Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetAllOperators, patchResetOperatorPassword } from 'apis/store';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import Title from 'components/Title/Title';
import TextButton from 'components/TextButton/TextButton';

import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';

import { useFetchConstant } from 'hooks/constants';
import { constructConstantLabel } from 'utils/constants';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { getNewTeamRoute, getTeamDetailRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { logError } from 'utils/logging';

import { TitleContainer } from './Team.styles';

const { confirm } = Modal;
const MAX_STORE_OPERATORS = 5;

const constructColumns = ({ t, roleSelections, storeOperatorRoles, handleOnResetPasswordModalShow, isLoading }) => {
  const columns = [
    {
      ...constructColumn(t('pageTeam:table-header-title-username'), 'username', { hasSorter: true, width: '15%' }),
      render: (text, record) => {
        const productLinkComp = 'user' && <Link to={getTeamDetailRoute(record._id).path}>{String(text)}</Link>;

        return productLinkComp;
      },
      ...constructColumnFilterSearch('username', 'Search username')
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-name'), 'name', { hasSorter: true, width: '15%' }),
      ...constructColumnFilterSearch('name', 'Search name')
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-email-address'), 'email', { width: '25%' }),
      ...constructColumnFilterSearch('email', 'Search email')
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-contact-number'), 'contact', { isArray: true, width: '15%' }),
      render: (text, record) => {
        return `${record.contact.countryCode}${record.contact.contactNumber}`;
      },
      ...constructColumnFilterSearch('contact', 'Search contact no.', { key: 'contact' })
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-role'), 'storeOperatorRoles', { width: '15%' }),
      render: (role, record) => {
        return <div>{constructConstantLabel(roleSelections, record.role)}</div>;
      }
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-last-accessed'), 'lastLogin', { width: '15%' }),
      render: date => (date ? formatToDateTimeWithPMAM(date) : '-')
    },
    {
      ...constructColumn(t('pageTeam:table-header-title-action'), 'action', { width: '15%' }),
      render: (text, record) => {
        return (
          <Space wrap={true}>
            {record.role !== storeOperatorRoles.OWNER.code && (
              <TextButton
                icon={<KeyOutlined />}
                onClick={() => handleOnResetPasswordModalShow(record._id)}
                text={t('pageTeam:table-action-reset-password')}
                loading={isLoading}
              />
            )}
          </Space>
        );
      }
    }
  ];

  return columns;
};

const TeamProfile = ({ storeOperatorRoles }) => {
  const history = useHistory();
  const [shouldShowResetPasswordModal, setShouldShowResetPasswordModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userId, setUserId] = useState();
  const { isLoading: isOperatorLoading, data: users } = useGetAllOperators();
  const { isLoading: isRolesLoading, selection: roleSelections } = useFetchConstant('storeOperatorRoles');

  const isLoading = isRolesLoading || isOperatorLoading;

  const hasExceedUserLimit = users && users.length >= MAX_STORE_OPERATORS;

  const { t } = useTranslation(['common', 'pageTeam']);

  const handleOnResetPasswordModalShow = selectedUserId => {
    setUserId(selectedUserId);
    setShouldShowResetPasswordModal(true);
  };

  const handleOnResetPasswordSubmit = values => {
    confirm({
      title: t('pageTeam:confirm-modal-reset-password-title'),
      content: t('pageTeam:confirm-modal-reset-password-content'),
      okText: t('pageTeam:confirm-modal-reset-password-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      onOk() {
        setIsUpdating(true);
        patchResetOperatorPassword(userId, { newPassword: values.newPassword })
          .then(() => {
            message.success(t('pageTeam:confirm-modal-reset-password-success-text'));
          })
          .catch(ex => {
            logError(ex);
            message.error(t('pageTeam:confirm-modal-reset-password-error-text', { errorMessage: ex.message }));
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    });
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Team" />
        <title>{t('pageTeam:page-title')}</title>
      </Helmet>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <TitleContainer justify="space-between">
            <Title>{t('pageTeam:page-title')}</Title>
            <Space>
              <Tooltip title={hasExceedUserLimit ? t('pageTeam:maximum-users-reach-tooltip') : null}>
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(getNewTeamRoute().path);
                  }}
                  icon={<PlusOutlined />}
                  loading={isUpdating}
                  disabled={hasExceedUserLimit}
                >
                  {t('pageTeam:add-new-member-button-text')}
                </Button>
              </Tooltip>
            </Space>
          </TitleContainer>
          <div style={{ marginTop: '20px' }}>
            <Table
              rowKey={record => record._id}
              dataSource={users}
              columns={constructColumns({ t, roleSelections, storeOperatorRoles, handleOnResetPasswordModalShow, isLoading })}
              scroll={{ x: 1000 }}
            />
          </div>
        </>
      )}

      {shouldShowResetPasswordModal && (
        <Modal
          visible={shouldShowResetPasswordModal}
          title={t('pageTeam:reset-password-modal-title')}
          onCancel={() => setShouldShowResetPasswordModal(false)}
          footer={false}
        >
          <div>
            <div style={{ marginBottom: '10px' }}>{t('pageTeam:reset-password-modal-input-title-new-password')}</div>
            <Form onFinish={handleOnResetPasswordSubmit}>
              <FormPasswordInput
                name="newPassword"
                type="password"
                placeholder={t('pageTeam:reset-password-modal-input-placeholder-new-password')}
                requiredErrorMessage={t('pageTeam:reset-password-modal-password-required-error-message')}
                checkPattern={true}
              />
              <div style={{ marginTop: '20px' }}>
                <Button htmlType="submit" type="primary" icon={<CheckOutlined />} shape="round" size="middle">
                  {t('pageTeam:reset-password-modal-reset-button-text')}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(TeamProfile);
