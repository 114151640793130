import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiBookmarksLight } from 'react-icons/pi';
import debounce from 'lodash.debounce';

import FormSelection from 'components/FormSelection/FormSelection';

import { useGetStoreOrderTagSuggestions } from 'apis/order';

const OrderTagSelection = ({ name }) => {
  const { t } = useTranslation(['pageOrder']);
  const [searchTagKeyword, setSearchTagKeyword] = useState('');
  const delaySetSearchTagKeyword = useMemo(() => debounce(setSearchTagKeyword, 300), []);
  const { isLoading: isStoreOrderTagSuggestionsLoading, data: storeOrderTagsSuggestions } = useGetStoreOrderTagSuggestions(searchTagKeyword);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <PiBookmarksLight style={{ fontSize: '20px', color: '#797979' }} />
      <FormSelection
        name={name}
        placeholder={t('pageOrder:select-input-placeholder-order-tags')}
        isMultiple
        selections={storeOrderTagsSuggestions ? storeOrderTagsSuggestions : []}
        isSearching={isStoreOrderTagSuggestionsLoading}
        onSearch={delaySetSearchTagKeyword}
        customMarginBottom="0"
      />
    </div>
  );
};

FormSelection.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default OrderTagSelection;
