import { useMemo, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Radio, Row, Col, Skeleton, Space } from 'antd';
import { getCurrentMoment, formatToDateString } from 'utils/date';

import { useTranslation, Trans } from 'react-i18next';
import { useFetchConstant } from 'hooks/constants';

import CsvDownload from 'components/CsvDownload/CsvDownload';
import XlsxDownload from 'components/XlsxDownload/XlsxDownload';
import FormCheckbox from 'components/Checkbox/FormCheckbox/FormCheckbox';
import FormSelection from 'components/FormSelection/FormSelection';
import OrderTagSelection from 'components/OrderTagSelection/OrderTagSelection';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { getShipmentsTemplate } from 'apis/order';
import { useGetShipments } from 'apis/shipment';
import {
  SHIPMENT_TYPE,
  EASY_PARCEL_CSV_HEADERS,
  POS_LAJU_CSV_HEADERS,
  BEST_EXPRESS_XLSX_HEADERS,
  NINJA_VAN_CSV_HEADERS,
  GDEX_XLSX_HEADERS,
  CITILINK_XLSX_HEADERS,
  JNT_XLSX_HEADERS,
  BLUEBOX_XLSX_HEADERS,
  DHL_XLSX_HEADERS,
  FMX_CSV_HEADERS,
  PNP_CSV_HEADERS,
  BX_CSV_HEADERS,
  SKYNET_CSV_HEADERS,
  LINE_CLEAR_EXPRESS_XLSX_HEADERS
} from 'utils/constants';

import { FieldLabel, NoteLabel } from './ExportShipmentCSVModal.styles';

const { useForm } = Form;

const xlsxShipmentTypes = [
  SHIPMENT_TYPE.BEST_EXPRESS.value,
  SHIPMENT_TYPE.GDEX.value,
  SHIPMENT_TYPE.CITILINK.value,
  SHIPMENT_TYPE.JNT.value,
  SHIPMENT_TYPE.BLUEBOX.value,
  SHIPMENT_TYPE.DHL.value,
  SHIPMENT_TYPE.LINE_CLEAR_EXPRESS.value
];

const bestExpressRowsBeforeHeader = Array(5).fill(['(DO NOT DELETE THIS ROW)']);
const bestExpressRowsAfterHeader = [
  [0, 'example (DO NOT DELETE THIS ROW)', '098123456', '12345', 'G 47, jalan mutiara emas 10/19', 1000.0, 1000.0, 11.0, 'parcel', 'Clothing']
];

const getFullAddress = item => {
  if (!item.customerAddress || !item.customerAddress.trim()) {
    return '';
  }

  return `${item.customerAddress}, ${item.shipment?.address?.city ? item.shipment?.address?.city + ', ' : ''}${
    item.shipment?.address?.state ? item.shipment?.address?.state : ''
  }`;
};

const formatBestExpressData = (item, index) => [
  index + 1,
  item.shipment?.recipientName,
  item.customerPhone?.replace('+', ''),
  item.shipment?.address?.zipcode,
  item.shipment?.address?.addressLine1,
  item.subtotalPrice,
  item.subtotalPrice,
  item.shipment?.totalWeightInKG,
  item.orderType,
  item.orderNumber,
  '',
  item.shipment?.remarks
];

const formatGdexData = item => [
  item.store.name,
  item.shipment?.recipientName,
  item.customerPhone?.replace('+', ''),
  '',
  item.shipment?.address?.addressLine1,
  item.shipment?.address?.addressLine2,
  '',
  item.shipment?.address?.zipcode,
  item.shipment?.address?.city,
  item.shipment?.address?.state,
  'P',
  '',
  item.subtotalPrice,
  1,
  item.shipment?.totalWeightInKG,
  '',
  '',
  '',
  '',
  '',
  '',
  item.orderNumber,
  ''
];

const formatCitilinkData = item => [
  'EXP',
  1,
  item.shipment?.totalWeightInKG,
  '',
  'MYR',
  item.shipment?.recipientName,
  item.shipment?.recipientName,
  '',
  item.customerPhone?.replace('+', ''),
  '',
  item.shipment?.recipientName,
  '',
  item.shipment?.address?.addressLine1,
  item.shipment?.address?.addressLine2,
  '',
  item.shipment?.address?.zipcode,
  item.shipment?.address?.city,
  item.shipment?.address?.state,
  'MY',
  item.orderNumber,
  '',
  'SPX'
];

const formatJntData = item => [
  item.shipment?.totalWeightInKG,
  item.userDetail?.name,
  item.sellerPhone?.replace('+', ''),
  item.store?.address?.zipcode,
  item.sellerAddress,
  item.shipment?.recipientName,
  item.customerPhone?.replace('+', ''),
  item.shipment?.address?.zipcode,
  '',
  getFullAddress(item),
  '',
  '',
  1,
  'EZ',
  '',
  '',
  '',
  '',
  '',
  'PARCEL',
  '',
  item.orderNumber,
  item.subtotalPrice,
  '',
  ''
];

const formatBlueBoxData = item => [
  'Username',
  '8/11/2022',
  item.orderNumber,
  item.shipment?.recipientName,
  item.customerPhone?.replace('+', ''),
  '',
  '',
  item.shipment?.address?.zipcode,
  getFullAddress(item),
  item.shipment?.remarks,
  '0',
  '0',
  '0',
  '0',
  '0'
];

const formatDHLData = item => [
  'XXX',
  item.orderNumber,
  'PDO',
  '',
  item.shipment?.recipientName,
  item.shipment?.address?.addressLine1,
  item.shipment?.address?.addressLine2,
  '',
  item.shipment?.address?.city,
  item.shipment?.address?.state,
  item.shipment?.address?.zipcode,
  item.shipment?.address?.country,
  item.customerPhone?.replace('+', ''),
  '',
  item.shipment?.totalWeight,
  item.store.currency.iso,
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '1',
  '',
  'C',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  ''
];

const formatLineClearExpressData = item => [
  'Domestic Shipment',
  '',
  '',
  item.shipment?.recipientName,
  '',
  item.shipment?.address?.addressLine1,
  item.shipment?.address?.addressLine2,
  item.shipment?.address?.zipcode,
  item.customerPhone?.replace('+', ''),
  '',
  'Package',
  'Pick Up',
  item.orderNumber,
  item.orderItems,
  item.totalOrderItems,
  item.shipment?.totalWeight,
  '',
  '',
  '',
  '',
  '',
  item.totalPrice,
  item.subtotalPrice,
  '',
  item.shipment?.shippingFee,
  '',
  '',
  ''
];

const DownloadButton = ({ t, isLoading }) => {
  return (
    <Button loading={isLoading} type="primary" icon={<DownloadOutlined />}>
      {t('pageOrder:csv-shipment-download-button-text')}
    </Button>
  );
};

const ExportShipmentCSVModal = ({ onCancel }) => {
  const { t } = useTranslation(['common', 'commonConstants', 'pageOrder']);
  const [form] = useForm();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [shipmentType, setShipmentType] = useState(SHIPMENT_TYPE.EASY_PARCEL.value);
  const { data: orderStatusesConst, selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipments();

  const shipmentOrderStatuses = useMemo(
    () =>
      isOrderStatusesLoading
        ? []
        : orderStatuses.filter(orderStatus => [orderStatusesConst.PAID.code, orderStatusesConst.PARTIALLY_SHIPPED.code].includes(orderStatus.value)),
    [isOrderStatusesLoading, orderStatuses, orderStatusesConst]
  );

  const formattedShipment = useMemo(
    () =>
      isShipmentsLoading
        ? []
        : shipments.map(shipment => ({
            label: shipment.label,
            value: shipment._id
          })),
    [isShipmentsLoading, shipments]
  );

  const getCsvHeaders = () => {
    switch (shipmentType) {
      case SHIPMENT_TYPE.EASY_PARCEL.value:
        return EASY_PARCEL_CSV_HEADERS;
      case SHIPMENT_TYPE.POS_LAJU.value:
        return POS_LAJU_CSV_HEADERS;
      case SHIPMENT_TYPE.NINJA_VAN.value:
        return NINJA_VAN_CSV_HEADERS;
      case SHIPMENT_TYPE.FMX.value:
        return FMX_CSV_HEADERS;
      case SHIPMENT_TYPE.PNP.value:
        return PNP_CSV_HEADERS;
      case SHIPMENT_TYPE.BX.value:
        return BX_CSV_HEADERS;
      case SHIPMENT_TYPE.SKYNET.value:
        return SKYNET_CSV_HEADERS;
      default:
        return [];
    }
  };

  const getXlsxRowsBeforeHeader = () => {
    if (shipmentType === SHIPMENT_TYPE.BEST_EXPRESS.value) {
      return bestExpressRowsBeforeHeader;
    }
    return [];
  };

  const getXlsxHeaders = () => {
    switch (shipmentType) {
      case SHIPMENT_TYPE.BEST_EXPRESS.value:
        return BEST_EXPRESS_XLSX_HEADERS;
      case SHIPMENT_TYPE.GDEX.value:
        return GDEX_XLSX_HEADERS;
      case SHIPMENT_TYPE.CITILINK.value:
        return CITILINK_XLSX_HEADERS;
      case SHIPMENT_TYPE.JNT.value:
        return JNT_XLSX_HEADERS;
      case SHIPMENT_TYPE.BLUEBOX.value:
        return BLUEBOX_XLSX_HEADERS;
      case SHIPMENT_TYPE.DHL.value:
        return DHL_XLSX_HEADERS;
      case SHIPMENT_TYPE.LINE_CLEAR_EXPRESS.value:
        return LINE_CLEAR_EXPRESS_XLSX_HEADERS;
      default:
        return [];
    }
  };

  const getXlsxRowsAfterHeader = () => {
    if (shipmentType === SHIPMENT_TYPE.BEST_EXPRESS.value) {
      return bestExpressRowsAfterHeader;
    }
    return [];
  };

  const getXlsxData = (item, index) => {
    switch (shipmentType) {
      case SHIPMENT_TYPE.BEST_EXPRESS.value:
        return formatBestExpressData(item, index);
      case SHIPMENT_TYPE.GDEX.value:
        return formatGdexData(item);
      case SHIPMENT_TYPE.CITILINK.value:
        return formatCitilinkData(item);
      case SHIPMENT_TYPE.JNT.value:
        return formatJntData(item);
      case SHIPMENT_TYPE.BLUEBOX.value:
        return formatBlueBoxData(item);
      case SHIPMENT_TYPE.DHL.value:
        return formatDHLData(item);
      case SHIPMENT_TYPE.LINE_CLEAR_EXPRESS.value:
        return formatLineClearExpressData(item);
      default:
        return [];
    }
  };

  const exportFileName = `${shipmentType} ${formatToDateString(getCurrentMoment(), 'YYYYMMDD')}`;
  const csvFileName = `${exportFileName}.csv`;
  const xlsxFileName = `${exportFileName}.xlsx`;
  const xlsxSheetName = shipmentType === SHIPMENT_TYPE.LINE_CLEAR_EXPRESS.value ? 'bulk-shipment' : 'Sheet 1';

  const handleOnChangeShipmentType = e => {
    setShipmentType(e.target.value);
  };

  const asyncExportMethod = async () => {
    try {
      const values = await form.validateFields();

      const shipmentData = await getShipmentsTemplate({
        type: shipmentType,
        statuses: values.statuses.join(','),
        shipmentMethods: values.shipmentMethods ? values.shipmentMethods.join(',') : '',
        tags: values.tags ? values.tags.join(',') : ''
      });

      return shipmentData;
    } catch (ex) {
      message.error(t('pageOrder:csv-download-form-validation-failed-message'));
    }
  };

  return (
    <Modal
      visible
      title={t('pageOrder:csv-shipment-download-title')}
      onCancel={onCancel}
      footer={
        <Space>
          {xlsxShipmentTypes.includes(shipmentType) ? (
            <XlsxDownload
              asyncExportMethod={asyncExportMethod}
              onDataLoading={() => setIsLoadingData(true)}
              onDataLoaded={() => setIsLoadingData(false)}
              rowsBeforeHeader={getXlsxRowsBeforeHeader()}
              headers={getXlsxHeaders()}
              rowsAfterHeader={getXlsxRowsAfterHeader()}
              formatData={getXlsxData}
              filename={xlsxFileName}
              sheetName={xlsxSheetName}
            >
              <DownloadButton t={t} isLoading={isLoadingData} />
            </XlsxDownload>
          ) : (
            <CsvDownload
              asyncExportMethod={asyncExportMethod}
              onDataLoading={() => setIsLoadingData(true)}
              onDataLoaded={() => setIsLoadingData(false)}
              headers={getCsvHeaders()}
              filename={csvFileName}
            >
              <DownloadButton t={t} isLoading={isLoadingData} />
            </CsvDownload>
          )}
        </Space>
      }
    >
      {!shipmentOrderStatuses.length && isShipmentsLoading ? (
        <Skeleton loading />
      ) : (
        <Row>
          <Col span={24}>
            <FieldLabel margin="4px 0">{t('pageOrder:csv-shipment-download-title-text')}</FieldLabel>
            <NoteLabel>{t('pageOrder:csv-shipment-download-note-text')}</NoteLabel>
          </Col>
          <Col span={24}>
            <Radio.Group
              options={Object.values(SHIPMENT_TYPE).map(shipmentType => {
                return { ...shipmentType, label: t(`commonConstants:${shipmentType.transKey}`) };
              })}
              onChange={handleOnChangeShipmentType}
              value={shipmentType}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
          <Col span={24} style={{ margin: '24px 0' }}>
            <Form
              form={form}
              initialValues={{
                statuses: shipmentOrderStatuses.map(shipmentOrderStatus => shipmentOrderStatus.value)
              }}
            >
              <FormCheckbox
                name="statuses"
                label={t('pageOrder:csv-shipment-download-order-status-text')}
                requiredErrorMessage={t('pageOrder:csv-shipment-download-order-status-selection-required-message')}
                options={shipmentOrderStatuses}
              />

              <FormSelection
                name="shipmentMethods"
                label={t('pageOrder:csv-shipment-download-shipment-method-text')}
                selections={formattedShipment}
                isMultiple
                isAllowClear
              />
              <OrderTagSelection name="tags" />
            </Form>
          </Col>
          <Col span={24}>
            <p style={{ marginBottom: '0px' }}>
              <Trans i18nKey="pageOrder:csv-shipment-download-remark-text" key="redirect-manual">
                <a href="https://forms.gle/YoWsA2ciVeXUhgBa8" target="_blank" rel="noopener noreferrer">
                  here
                </a>
              </Trans>
            </p>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default withAppContext(ExportShipmentCSVModal);
