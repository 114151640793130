import api from './apiHelper';
import { useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

/* =================================================== Store =================================================== */
// =================================================== POST
export const postCreateStore = payload => {
  return api.post('/store', payload);
};

export const postNewStoreFbConnection = payload => {
  return api.post('/store/facebook/new', payload);
};

export const postRefetchStoreFbLivePosts = () => {
  return api.post('/store/refetch-live-posts/fb');
};

export const postSelectAndPaySubscriptionPlan = payload => {
  return api.post(`store/checkout`, payload);
};

export const postDisconnectFbConnection = () => {
  return api.post('/store/facebook/disconnect');
};

// =================================================== GET
export const getCheckIsFbConnected = () => {
  return api.get('store/facebook/check-token');
};

export const useGetCurrentUserStore = (shouldStartFetch = false) => {
  const getProductDetails = async () => {
    return api.get(`/store/me`);
  };

  return useCustomQuery('myStore', [], getProductDetails, {
    enabled: shouldStartFetch
  });
};

export const useRefetchCurrentUserStore = () => {
  const { refetch } = useRefetchQuery(['myStore']);

  return refetch;
};

export const useGetStoreMicrositeSettings = () => {
  const getStoreMicrositeSettings = async () => {
    return api.get(`/store/me/microsite-settings`);
  };

  return useCustomQuery('microsite-settings', [], getStoreMicrositeSettings, { shouldDefaultEmptyObject: false });
};

export const useFetchStoreStarterOrderLimitDetails = (shouldStartFetch = false) => {
  const getStoreStarterOrderLimitDetails = async () => {
    return api.get('store/me/order-limit');
  };
  return useCustomQuery('storeOrderLimit', [], getStoreStarterOrderLimitDetails, {
    enabled: shouldStartFetch
  });
};

export const useRefetchStoreOrderLimit = () => {
  const { refetch } = useRefetchQuery(['storeOrderLimit']);

  return refetch;
};

export const useGetStoreNotifConfig = () => {
  const getStoreNotifConfig = async () => {
    return api.get(`/store/me/notif-config`);
  };

  return useCustomQuery('notif-config', [], getStoreNotifConfig, { shouldDefaultEmptyObject: false });
};

export const useGetStoreCustomerConfig = () => {
  const getStoreCustomerConfig = async () => {
    return api.get(`/store/me/customer-config`);
  };

  return useCustomQuery('customer-config', [], getStoreCustomerConfig, { shouldDefaultEmptyObject: false });
};

// =================================================== PATCH
export const patchStoreMicrositeSettings = payload => {
  return api.patch('/store/me/microsite-settings', payload);
};

export const patchStoreNotifConfig = payload => {
  return api.patch('/store/me/notif-config', payload);
};

export const patchStoreCustomerConfig = payload => {
  return api.patch('/store/me/customer-config', payload);
};

/* =================================================== Operator =================================================== */
// =================================================== POST
export const postCreateOperator = payload => {
  return api.post(`store/operator`, payload);
};

// =================================================== GET
export const useGetAllOperators = ({ shouldFetchLiveStreamersOnly } = {}, onError) => {
  const getAllOperators = () => {
    return api.get(`/store/operator/`, { params: { shouldFetchLiveStreamersOnly } });
  };

  return useCustomQuery('operators', [shouldFetchLiveStreamersOnly], getAllOperators, {
    onError,
    shouldDefaultEmptyObject: false
  });
};

export const useRefetchOperators = () => {
  const { refetch } = useRefetchQuery(['operators']);

  return refetch;
};

export const useGetOperatorDetails = (userId, onError) => {
  const getOperatorDetails = async () => {
    return api.get(`/store/operator/${userId}`);
  };

  return useCustomQuery('operator', [userId], getOperatorDetails, {
    onError,
    enabled: !!userId,
    shouldDefaultEmptyObject: false
  });
};

// =================================================== PATCH
export const patchOperator = (userId, payload) => {
  return api.patch(`/store/operator/${userId}`, payload);
};

export const patchResetOperatorPassword = (userId, { newPassword }) => {
  return api.patch(`store/operator/${userId}/reset-password`, { newPassword });
};

// =================================================== DELETE
export const deleteOperator = userId => {
  return api.delete(`store/operator/${userId}`);
};
