import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { CreditCardOutlined, ExclamationCircleOutlined, InfoCircleOutlined, PicRightOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, List, message, Modal, Row, Skeleton, Space, Tag, notification, Result } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { LuCoins } from 'react-icons/lu';

import { storeCurrencyToLocaleConfigMapping } from 'i18n';

import {
  useGetCheckoutDetails,
  postCheckout,
  useGetShipmentsForExternal,
  useGetCustomerPromosForExternal,
  getCalculateShipmentFee,
  getCalculatePromoDiscount,
  useGetAvailablePaymentMethodsForExternal,
  getCheckIsTelegramExist,
  useGetExternalMicrositeFeaturedProducts,
  useGetExternalMicrositeFeaturedProductDetails
} from 'apis/external';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import TextWithUrl from 'components/TextWithUrl/TextWithUrl';
import Title from 'components/Title/Title';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';
import FormCheckboxToggle from 'components/Checkbox/FormCheckboxToggle/FormCheckboxToggle';
import ImagePreview from 'components/ImagePreview/ImagePreview';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useTimer } from 'hooks/utils';

import CIMBLogo from 'images/fpx/cimb.png';
import FPXLogo from 'images/fpx/fpx.png';
import HSBCLogo from 'images/fpx/hsbc.png';
import MBBLogo from 'images/fpx/mbb.png';
import PBELogo from 'images/fpx/pbe.png';
import RHBLogo from 'images/fpx/rhb.png';
import UOBLogo from 'images/fpx/uob.png';
import CreditCardsLogo from 'images/fastpay/credit-cards.png';
import VisaMasterCardsLogo from 'images/razerpay/visa-master-cards.png';
import TNGLogo from 'images/razerpay/tng.png';

import { getFilteredConstants } from 'utils/constants';
import { guard, checkIsArrayEmpty, checkIsObjectEmpty, checkIsStringBoolTrue, constructReadableNum } from 'utils/general';
import { getCheckoutRoute } from 'utils/routes';
import { logError } from 'utils/logging';

import CheckoutPriceDisplay from './components/CheckoutPriceDisplay/CheckoutPriceDisplay';
import SuccessModal from './components/SuccessModal/SuccessModal';
import ProductCard from './components/ProductCard/ProductCard';
import VoucherModal from './components/VoucherModal/VoucherModal';
import FeatureProductCard from './components/FeatureProductCard/FeatureProductCard';
import FeatureProductDetailsModal from './components/FeatureProductDetailsModal/FeatureProductDetailsModal';
import PointsModal from './components/PointsModal/PointsModal';
import ProductDetailsModal from './components/ProductDetailsModal/ProductDetailsModal';

import {
  AlertContainer,
  ContainerCard,
  CheckoutProductListCol,
  CheckoutFormFieldsCol,
  CardTitle,
  BankDetailLabel,
  BankDetailText,
  LinkButton,
  NoteBannerContainer,
  NoteBannerText,
  PointsToEarnText,
  RootContainer,
  StoreDescription,
  StoreName,
  ShippingDetailsRow,
  TotalAmountText,
  PendingAmountText,
  WNBAlert,
  WNBMessageText,
  WNBMessageTextHighlight,
  ResultTitleContainer,
  FeaturedSectionTitle,
  FeatureSectionContainer,
  FeatureProductsScrollableContainer,
  FeaturedProductRow,
  FeaturedProductCol
} from './Checkout.styles.js';

const { useForm } = Form;
const { confirm } = Modal;

const CHECKOUT_NOTIFICATION_COOKIES = 'checkout-notification-token';
const TELEGRAM_NOTIFICATION_COOKIES = 'telegram-notification-token';
const APP_NOTIFICATION_COOKIES = 'app-notification-token';
const APP_URL = 'https://ulive.me/app?utm_source=checkout';

/* ================================================== Local Functions ================================================== */
const useFetchConstants = ({ currencyISO }) => {
  const { isLoading: isOrderStatusesLoading, data: orderStatusesConst } = useFetchConstant('orderStatuses');
  const { isLoading: isShipmentStatusesLoading, data: shipmentStatusesConst } = useFetchConstant('shipmentStatuses');
  const { isLoading: isDeliveryMethodsLoading, data: deliveryMethodsConst } = useFetchConstant('deliveryMethods');
  const { isLoading: isCountryCodesLoading, selection: countryCodes } = useFetchConstant('countries', {
    labelKey: 'phoneCode',
    valueKey: 'phoneCode',
    query: { currency: currencyISO }
  });
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2',
    query: { currency: currencyISO }
  });
  const { isLoading: isEcommercePlatformSourcesLoading, data: ecommercePlatformSourcesConst } = useFetchConstant('ecommercePlatformSources');

  const isLoading =
    isOrderStatusesLoading ||
    isDeliveryMethodsLoading ||
    isCountryCodesLoading ||
    isCountriesLoading ||
    isShipmentStatusesLoading ||
    isEcommercePlatformSourcesLoading;

  return { isLoading, orderStatusesConst, shipmentStatusesConst, deliveryMethodsConst, countryCodes, countries, ecommercePlatformSourcesConst };
};

const constructShippingRemarks = (shouldAllowSkipUpload, oriShippingRemarks, additionalRemarks) => {
  if (!shouldAllowSkipUpload) {
    return oriShippingRemarks;
  }

  if (oriShippingRemarks) {
    return `${oriShippingRemarks}\n${additionalRemarks}`;
  } else {
    return additionalRemarks;
  }
};

const checkIsFbBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
};

const useHandleFbBrowserUpload = () => {
  const [isFbBrowser, setIsFbBrowser] = useState(false);
  const [uploadClickCount, setUploadClickCount] = useState(0);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [shouldAllowSkipUpload, setShouldAllowSkipUpload] = useState(false);

  useEffect(() => {
    const isFbBrowser = checkIsFbBrowser();
    setIsFbBrowser(isFbBrowser);
  }, []);

  useEffect(() => {
    if (isFbBrowser && uploadClickCount > 1 && !isUploadSuccess) {
      setShouldAllowSkipUpload(true);
    }
  }, [isFbBrowser, uploadClickCount, isUploadSuccess]);

  const handleOnUploadClick = () => {
    setUploadClickCount(prevUploadClickCount => {
      return prevUploadClickCount + 1;
    });
  };

  const handleOnUploadSuccess = () => {
    setIsUploadSuccess(true);
    setShouldAllowSkipUpload(false);
  };

  return { shouldAllowSkipUpload, handleOnUploadClick, handleOnUploadSuccess };
};

/* ================================================== Local Components ================================================== */
const WebstoreNotificationBanner = ({ subdomainName, orderNumber, accessCode }) => {
  const { t } = useTranslation(['pageCheckout']);
  // TODO: Fix hardcoded MS store
  const webstoreLink = `https://${subdomainName}.ulive.me/home?orderNumber=${encodeURIComponent(orderNumber)}&accessCode=${encodeURIComponent(
    accessCode
  )}`;

  const handleOnClickAlert = () => {
    window.location.href = webstoreLink;
  };

  return (
    <WNBAlert
      message={
        <WNBMessageText>
          <Tag color="#ffba00">
            <b>{t('pageCheckout:webstore-banner-tag')}</b>
          </Tag>
          <span>{t('pageCheckout:webstore-banner-text-1')} </span>
          <WNBMessageTextHighlight>{t('pageCheckout:webstore-banner-text-2')}</WNBMessageTextHighlight>
        </WNBMessageText>
      }
      type="warning"
      onClick={handleOnClickAlert}
    />
  );
};

const UploadIssueAlert = ({ message }) => {
  return (
    <AlertContainer>
      <Alert type="warning" showIcon message={message} />
    </AlertContainer>
  );
};

const PaymentBankDetailsCard = ({
  storeCurrency,
  balanceAmount,
  pendingOrderPaymentAmount,
  store = {},
  isSelectedOnlineType,
  selectedPaymentMethod,
  isCodDelivery,
  isEnabledPoint,
  pointsToEarn
}) => {
  const { t } = useTranslation(['common', 'pageCheckout']);
  const { bankDetails, eWalletQrFiles, eWalletQrs } = store;

  const FPXDetails = () => {
    return (
      <div>
        <span>We support </span>
        <img src={FPXLogo} alt="fpx" width="42" /> <img height="26" width="26" src={MBBLogo} alt="maybank" />{' '}
        <img height="26" width="26" src={CIMBLogo} alt="cimb" /> <img height="32" width="32" alt="RHB Now" src={RHBLogo} />{' '}
        <img height="32" width="32" alt="PBe" src={PBELogo} /> <img height="32" width="32" alt="HSBC Online Banking" src={HSBCLogo} />{' '}
        <img height="48" width="48" alt="UOB Internet Banking" src={UOBLogo} /> <span> ...and many more</span>
      </div>
    );
  };

  const FastPayDetails = () => {
    return (
      <div>
        <span>We support </span>
        <img src={CreditCardsLogo} alt="visa, mastercard, american express" height="32" />{' '}
      </div>
    );
  };

  const RazerPayDetails = () => {
    return (
      <div>
        <span>We support </span>
        <img src={FPXLogo} alt="fpx" width="42" /> <img src={VisaMasterCardsLogo} alt="visa, mastercard" height="32" />{' '}
        <img src={TNGLogo} alt="tng" height="32" />{' '}
      </div>
    );
  };

  const ManualDetails = () => {
    return (
      <div>
        <BankDetailText>
          {t('pageCheckout:payment-detail-desc-label-pay-to')} {bankDetails.beneficiaryName}
        </BankDetailText>
        <div>
          <span>
            {bankDetails.bankName} | {bankDetails.accountNumber}
          </span>
          {(!checkIsArrayEmpty(eWalletQrFiles) || !checkIsArrayEmpty(eWalletQrs)) && (
            <>
              <hr />
              <p style={{ marginTop: '8px' }}>{t('pageCheckout:payment-detail-desc-label-ewallet-method')}</p>
              <Space wrap>
                {eWalletQrs &&
                  eWalletQrs.map(file => {
                    const previewImageUrl = file.original?.url;
                    const thumbnailImageUrl = file.thumbnail?.url || previewImageUrl;

                    return (
                      <ImagePreview
                        key={file.uid}
                        alt={file.name}
                        width={72}
                        previewImageUrl={previewImageUrl}
                        thumbnailImageUrl={thumbnailImageUrl}
                      />
                    );
                  })}
                {eWalletQrFiles && eWalletQrFiles.map(file => <ImagePreview key={file.uid} alt={file.name} width={72} previewImageUrl={file.url} />)}
              </Space>
            </>
          )}
        </div>
      </div>
    );
  };

  const getPaymentDetailsCardDescription = () => {
    switch (true) {
      case isSelectedOnlineType && selectedPaymentMethod === 'fpx':
        return <FPXDetails />;
      case isSelectedOnlineType && selectedPaymentMethod === 'fastPay':
        return <FastPayDetails />;
      case isSelectedOnlineType && selectedPaymentMethod === 'razerPay':
        return <RazerPayDetails />;
      default:
        return <ManualDetails />;
    }
  };

  return (
    <Card>
      <Card.Meta
        title={
          <div>
            <TotalAmountText>
              <PriceDisplay storeCurrency={storeCurrency} amount={balanceAmount} />
            </TotalAmountText>
            <span> {isCodDelivery ? t('pageCheckout:payment-detail-desc-label-cod') : t('pageCheckout:payment-detail-desc-label-in-total')}</span>
            {isEnabledPoint && <PointsToEarnText>+{constructReadableNum(pointsToEarn)} pts</PointsToEarnText>}
          </div>
        }
        description={
          <>
            {!!pendingOrderPaymentAmount && (
              <PendingAmountText>
                {t('pageCheckout:payment-detail-pending-approval-message')}{' '}
                <PriceDisplay storeCurrency={storeCurrency} amount={pendingOrderPaymentAmount} />
              </PendingAmountText>
            )}
            {!isCodDelivery ? getPaymentDetailsCardDescription() : null}
          </>
        }
      />
    </Card>
  );
};

const PaymentInfoSection = ({
  store,
  storeCurrency,
  balanceAmount,
  pendingOrderPaymentAmount,
  isReceiptRequired,
  isStoreRequirePaymentReceipt,
  isSelectedOnlinePayment,
  paymentMethods,
  shouldAllowUpload,
  shouldAllowSkipUpload,
  selectedPaymentMethod,
  onChangePaymentMethod,
  onUpload,
  onUploadSuccess,
  isCodDelivery,
  receiptsRef,
  currentPhotos,
  isEnabledPoint,
  pointsToEarn,
  setCurrentPhotos,
  setIsCompressing
}) => {
  const { t } = useTranslation(['common', 'pageCheckout']);

  const generatePaymentMethodsLabel = paymentMethod => {
    switch (true) {
      case paymentMethod === 'fastPay':
        return <span>{t(`pageCheckout:payment-method-${paymentMethod}`)}</span>;
      default:
        return t(`pageCheckout:payment-method-${paymentMethod}`);
    }
  };

  const paymentMethodSelections = checkIsArrayEmpty(paymentMethods)
    ? []
    : paymentMethods.map(paymentMethod => ({
        label: generatePaymentMethodsLabel(paymentMethod.code),
        value: paymentMethod.code
      }));

  const handleOnChangePaymentMethod = e => {
    e.preventDefault();
    const selectedValue = e.target.value;
    onChangePaymentMethod(selectedValue);
  };

  const handleOnPhotoChange = photos => {
    setCurrentPhotos(photos);
    onUploadSuccess();
  };

  return (
    <Card title={<CardTitle>{t('pageCheckout:card-title-payment-details')}</CardTitle>} bordered={false} bodyStyle={{ padding: '8px 8px 0 8px' }}>
      <Row gutter={[24, 24]} style={{ margin: 0 }}>
        {!isCodDelivery && (
          <Col span={24} md={{ order: 1 }}>
            <FormRadioButton
              label={selectedPaymentMethod ? `${t('pageCheckout:form-input-label-payment-method')}:` : null}
              name={['payment', 'method']}
              selections={paymentMethodSelections}
              optionType="dot"
              customMarginBottom="0px"
              onChange={handleOnChangePaymentMethod}
            />
          </Col>
        )}

        {shouldAllowUpload && (
          <Col span={24} md={{ order: 3 }} style={{ paddingBottom: '0px' }}>
            <BankDetailLabel>{t('pageCheckout:payment-detail-desc-label-upload-receipts')}</BankDetailLabel>
            {!isStoreRequirePaymentReceipt && shouldAllowSkipUpload && (
              <UploadIssueAlert message={t('pageCheckout:file-upload-fb-browser-issue-message')} />
            )}
            <PhotoManager
              value={currentPhotos}
              onClick={onUpload}
              onChange={handleOnPhotoChange}
              onStatusChange={setIsCompressing}
              buttonLabel={t('pageCheckout:form-submit-upload-button-text')}
              fileSizeThresholdToCompressInKb={400}
              ref={receiptsRef}
              multiple
              shouldRemoveDocsMarkedForDelete
              allowPdf
              required={isReceiptRequired}
              storeId={store._id}
              errorMessage={isReceiptRequired ? t('pageCheckout:form-input-required-message-order-payment-receipt') : undefined}
            />
          </Col>
        )}
        <Col span={24} md={{ order: 2 }}>
          <PaymentBankDetailsCard
            isSelectedOnlineType={isSelectedOnlinePayment}
            storeCurrency={storeCurrency}
            balanceAmount={balanceAmount}
            pendingOrderPaymentAmount={pendingOrderPaymentAmount}
            store={store}
            selectedPaymentMethod={selectedPaymentMethod}
            isCodDelivery={isCodDelivery}
            isEnabledPoint={isEnabledPoint}
            pointsToEarn={pointsToEarn}
          />
        </Col>
        {store.isAllowCustomerPreserveOrder && balanceAmount > 0 ? (
          <Col span={24} md={{ order: 4 }}>
            <FormCheckboxToggle name="isPreservedLocal" label={t('pageCheckout:checkbox-preserve-order-message')} />
          </Col>
        ) : undefined}
      </Row>
    </Card>
  );
};

const VoucherDisplay = ({ t, selectedVouchers, onRemoveVoucherClick, onVoucherClick }) => {
  const promoCount = selectedVouchers.length;
  return (
    <>
      <Col span={6}>{t('pageCheckout:order-display-desc-label-promo')}:</Col>
      <Col span={18} style={{ display: 'flex', justifyContent: 'right' }}>
        {promoCount > 0 ? (
          <Row justify="end">
            {promoCount === 1 ? (
              <>
                <ApplyVoucherButton label={selectedVouchers[0].code} onClick={onVoucherClick} />
                <LinkButton type="link" onClick={onRemoveVoucherClick}>
                  ({t('pageCheckout:order-display-desc-label-remove-promo')})
                </LinkButton>
              </>
            ) : (
              <ApplyVoucherButton label={t('pageCheckout:order-display-desc-label-number-promos-applied', { promoCount })} onClick={onVoucherClick} />
            )}
          </Row>
        ) : (
          <ApplyVoucherButton
            label={
              <>
                <PicRightOutlined /> {t('pageCheckout:order-display-desc-label-apply-promo')}
              </>
            }
            onClick={onVoucherClick}
          />
        )}
      </Col>
    </>
  );
};

const PointDisplay = ({ t, redeemedPoints, onRemovePointsClick, onPointsClick }) => {
  return (
    <>
      <Col span={6}>{t('pageCheckout:order-display-desc-label-points')}:</Col>
      <Col span={18} style={{ display: 'flex', justifyContent: 'right' }}>
        {redeemedPoints?.amount > 0 ? (
          <Row justify="end">
            <>
              <RedeemPointsButton label={String(redeemedPoints.amount)} onClick={onPointsClick} />
              <LinkButton type="link" onClick={onRemovePointsClick}>
                ({t('pageCheckout:order-display-desc-label-remove-points')})
              </LinkButton>
            </>
          </Row>
        ) : (
          <RedeemPointsButton
            label={
              <>
                <LuCoins /> {t('pageCheckout:order-display-desc-label-redeem-points')}
              </>
            }
            onClick={onPointsClick}
          />
        )}
      </Col>
    </>
  );
};

// ========================== Apply Voucher Component & Hooks
const ApplyVoucherButton = ({ label, onClick }) => (
  <div style={label ? { marginLeft: 4 } : { borderBottom: '1px solid #2196F3' }}>
    <LinkButton onClick={onClick} style={label && { textDecoration: 'underline' }} type="link">
      {label}
    </LinkButton>
  </div>
);

const useVoucherModal = ({ resetInvalidVoucherIds }) => {
  const [showVoucherModal, setShowVoucherModal] = useState(false);

  const openVoucherModal = useCallback(() => {
    setShowVoucherModal(true);
  }, []);

  const closeVoucherModal = useCallback(() => {
    resetInvalidVoucherIds();
    setShowVoucherModal(false);
  }, [resetInvalidVoucherIds]);

  return { showVoucherModal, openVoucherModal, closeVoucherModal };
};

// ========================== Feature Products Component

const FeaturedProductCards = ({ featuredProducts, onViewFeaturedProduct }) => {
  return (
    <FeaturedProductRow gutter={{ xs: 16, sm: 16, md: 12 }} wrap={false}>
      {featuredProducts.map(featuredProduct => (
        <FeaturedProductCol key={featuredProduct._id}>
          <FeatureProductCard
            name={featuredProduct.label}
            keyword={featuredProduct.keyword}
            coverPhotoUrl={featuredProduct.coverPhoto?.original?.url || featuredProduct.coverPhotoFile?.url}
            priceMin={featuredProduct.minMsPrice}
            priceMax={featuredProduct.maxMsPrice}
            oriPriceMin={featuredProduct.minOriPrice}
            oriPriceMax={featuredProduct.maxOriPrice}
            isActive
            hasStock={featuredProduct.hasStock}
            onClick={() => onViewFeaturedProduct(featuredProduct._id)}
          />
        </FeaturedProductCol>
      ))}
    </FeaturedProductRow>
  );
};

const FeaturedSection = ({ isLoading, featuredProduct, onViewFeaturedProduct }) => {
  const { t } = useTranslation(['pageCheckout']);
  return isLoading ? (
    <Skeleton active />
  ) : featuredProduct.length > 0 ? (
    <FeatureSectionContainer>
      <FeaturedSectionTitle>{t('pageCheckout:featured-title')}</FeaturedSectionTitle>
      <FeatureProductsScrollableContainer>
        <FeaturedProductCards featuredProducts={featuredProduct} onViewFeaturedProduct={onViewFeaturedProduct} />
      </FeatureProductsScrollableContainer>
    </FeatureSectionContainer>
  ) : null;
};

// ========================== Redeem Points Component & Hooks
const RedeemPointsButton = ({ label, onClick }) => (
  <div style={label ? { marginLeft: 4 } : { borderBottom: '1px solid #2196F3' }}>
    <LinkButton onClick={onClick} style={label && { textDecoration: 'underline' }} type="link">
      {label}
    </LinkButton>
  </div>
);

const usePointsModal = () => {
  const [showPointsModal, setShowPointsModal] = useState(false);

  const openPointsModal = useCallback(() => {
    setShowPointsModal(true);
  }, []);

  const closePointsModal = useCallback(() => {
    setShowPointsModal(false);
  }, []);

  return { showPointsModal, openPointsModal, closePointsModal };
};

const useProductDetailsModal = () => {
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState();

  const openProductDetailsModal = useCallback(productDetails => {
    setSelectedProductDetails(productDetails);
    setShowProductDetailsModal(true);
  }, []);

  const closeProductDetailsModal = useCallback(() => {
    setSelectedProductDetails(null);
    setShowProductDetailsModal(false);
  }, []);

  return { showProductDetailsModal, selectedProductDetails, openProductDetailsModal, closeProductDetailsModal };
};

/* ================================================== Main Component ================================================== */
const Checkout = () => {
  const { t } = useTranslation(['common', 'pageCheckout']);
  const [form] = useForm();
  const history = useHistory();
  const receiptsRef = useRef(null);
  const { orderNumber } = useParams();
  const { accessCode, hasCheckout } = useLocationQuery();
  const [hasInit, setHasInit] = useState(false);
  const [orderProducts, setOrderProducts] = useState([]);
  const [checkedOutOrder, setCheckedOutOrder] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [productShippingFee, setProductShippingFee] = useState();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [isCourierDelivery, setIsCourierDelivery] = useState(false);
  const [isCodDelivery, setIsCodDelivery] = useState(false);
  const [isShipmentIncludeDelivery, setIsShipmentIncludeDelivery] = useState(false);
  const [isOrderCheckedOut, setIsOrderCheckedOut] = useState(false);
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [shouldShowNotification, setShouldShowNotification] = useState(false);
  const [telegramInviteUrl, setTelegramInviteUrl] = useState('');
  const [isSuccessPreservedOrderModalShow, setIsSuccessPreservedOrderModalShow] = useState(false);
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [cookies, setCookie] = useCookies([CHECKOUT_NOTIFICATION_COOKIES], [TELEGRAM_NOTIFICATION_COOKIES], [APP_NOTIFICATION_COOKIES]);
  const [invalidVoucherIds, setInvalidVoucherIds] = useState([]);
  const [isViewFeaturedProduct, setIsViewFeaturedProduct] = useState(false);
  const [selectedFeaturedProductId, setSelectedFeaturedProductId] = useState(null);
  const [redeemedPoints, setRedeemedPoints] = useState({ amount: 0, discountAmount: 0 });

  const { isLoading: isCheckoutDetailsLoading, data: checkoutDetails, refetch: refetchCheckoutDetails } = useGetCheckoutDetails(
    { orderNumber, accessCode },
    {}
  );
  const { data: selectedFeaturedProduct, isLoading: isLoadingMicrositeFeaturedProduct } = useGetExternalMicrositeFeaturedProductDetails(
    selectedFeaturedProductId
  );
  const storeCurrency = useMemo(() => guard(() => checkoutDetails.store.currency, {}), [checkoutDetails]);
  const localeConfig = useMemo(() => guard(() => storeCurrencyToLocaleConfigMapping[storeCurrency.iso], {}), [storeCurrency]);

  const isEnabledPoint = guard(() => checkoutDetails.store.pointConfig.isEnabled, false);

  useEffect(() => {
    if (!isCheckoutDetailsLoading && checkoutDetails.point && checkoutDetails.point.redeem) {
      setRedeemedPoints(checkoutDetails.point.redeem);
    }
  }, [isCheckoutDetailsLoading, checkoutDetails]);

  const totalDiscountAmount = useMemo(
    () =>
      guard(() => checkoutDetails.discount.amount, 0) +
      guard(() => {
        return selectedVouchers.reduce((totalVoucherDiscount, selectedVoucher) => totalVoucherDiscount + selectedVoucher.discountAmount, 0);
      }, 0) +
      guard(() => redeemedPoints.discountAmount, 0),

    [checkoutDetails, selectedVouchers, redeemedPoints]
  );

  const pointsToEarn = useMemo(() => {
    if (isEnabledPoint) {
      const earnRate = checkoutDetails.store.pointConfig.earnRate;
      const balance = checkoutDetails.subtotalPrice - totalDiscountAmount;
      return Math.floor(balance / earnRate);
    }

    return 0;
  }, [isEnabledPoint, checkoutDetails, totalDiscountAmount]);

  useEffect(() => {
    if (!isCheckoutDetailsLoading && checkIsStringBoolTrue(hasCheckout) && checkedOutOrder && checkedOutOrder.isPreserved) {
      setIsSuccessPreservedOrderModalShow(true);
      history.replace(getCheckoutRoute(orderNumber, { accessCode }).path);
    }
  }, [isCheckoutDetailsLoading, hasCheckout, checkedOutOrder, orderNumber, accessCode, history]);

  const {
    isLoading: isConstantsLoading,
    orderStatusesConst,
    shipmentStatusesConst,
    deliveryMethodsConst,
    countryCodes,
    countries,
    ecommercePlatformSourcesConst
  } = useFetchConstants({ currencyISO: storeCurrency.iso });
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipmentsForExternal(
    checkoutDetails && checkoutDetails.store && checkoutDetails.store._id
  );
  const { isLoading: isPaymentMethodsLoading, data: paymentMethods } = useGetAvailablePaymentMethodsForExternal(
    checkoutDetails && checkoutDetails.store && checkoutDetails.store._id,
    { shouldFetch: checkoutDetails && checkoutDetails.store && checkoutDetails.store._id }
  );
  const { isLoading: isLoadingMicrositeFeaturedProducts, data: micrositeFeaturedProducts } = useGetExternalMicrositeFeaturedProducts(
    checkoutDetails && checkoutDetails.store && checkoutDetails.store._id
  );

  const { shouldAllowSkipUpload, handleOnUploadClick, handleOnUploadSuccess, isUploadSuccess } = useHandleFbBrowserUpload();
  const { isLoading: isVouchersLoading, data: rawVouchers } = useGetCustomerPromosForExternal(
    checkoutDetails && checkoutDetails.customer && checkoutDetails.customer._id,
    checkoutDetails && checkoutDetails._id
  );
  const resetInvalidVoucherIds = useCallback(() => {
    setInvalidVoucherIds([]);
  }, []);
  const { showVoucherModal, openVoucherModal, closeVoucherModal } = useVoucherModal({ resetInvalidVoucherIds });
  const { showPointsModal, openPointsModal, closePointsModal } = usePointsModal();
  const { showProductDetailsModal, selectedProductDetails, openProductDetailsModal, closeProductDetailsModal } = useProductDetailsModal();

  const isSelectedPaymentMethodOnlineType = useMemo(() => {
    if (!checkIsArrayEmpty(paymentMethods)) {
      const currentPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.code === selectedPaymentMethod);
      return checkIsObjectEmpty(currentPaymentMethod) ? true : currentPaymentMethod.isOnlinePayment; // Always choose online payment if store has online payment
    }
    return false;
  }, [paymentMethods, selectedPaymentMethod]);

  const vouchers = useMemo(() => {
    if (rawVouchers && checkoutDetails) {
      const formattedVouchers = rawVouchers.map(voucher => ({
        ...voucher,
        isRedeemable: (!voucher.minSpendingAmount || checkoutDetails.subtotalPrice >= voucher.minSpendingAmount) && !voucher.isExpired
      }));
      return formattedVouchers;
    }
    return [];
  }, [rawVouchers, checkoutDetails]);

  const { totalAmount, balanceAmount } = useMemo(() => {
    const shippingFeeAmountDiff =
      guard(() => checkoutDetails.shipment.shippingFee, 0) +
      guard(() => checkoutDetails.shipment.productShippingFee, 0) -
      (shippingFee || 0) -
      (productShippingFee || 0);
    const promotionDiscountAmountDiff =
      guard(() => checkoutDetails.promotionDetails.discountAmount, 0) -
      guard(() => selectedVouchers.reduce((totalVoucherDiscount, selectedVoucher) => totalVoucherDiscount + selectedVoucher.discountAmount, 0), 0);
    const pointDiscountAmountDiff = guard(() => checkoutDetails.point.redeem.discountAmount, 0) - guard(() => redeemedPoints.discountAmount, 0);

    const oldSubtotalPrice =
      guard(() => checkoutDetails.totalAmount, 0) -
      guard(() => checkoutDetails.shipment.shippingFee, 0) -
      guard(() => checkoutDetails.shipment.productShippingFee, 0) -
      guard(() => checkoutDetails.extraFee.amount, 0) +
      guard(() => checkoutDetails.discount.amount, 0) +
      guard(() => checkoutDetails.promotionDetails.discountAmount, 0) +
      guard(() => checkoutDetails.point.redeem.discountAmount, 0);

    const subtotalPriceDiff = oldSubtotalPrice - guard(() => checkoutDetails.subtotalPrice, 0);

    const newTotalAmount = guard(
      () => checkoutDetails.totalAmount - subtotalPriceDiff + promotionDiscountAmountDiff + pointDiscountAmountDiff - shippingFeeAmountDiff,
      0
    );
    const newBalanceAmount = guard(
      () => checkoutDetails.balanceAmount + promotionDiscountAmountDiff + pointDiscountAmountDiff - shippingFeeAmountDiff,
      0
    );

    const successOrderPaymentAmount = guard(() => checkoutDetails.successOrderPaymentAmount, 0);

    return {
      totalAmount: Math.max(newTotalAmount, 0),
      balanceAmount: successOrderPaymentAmount + newBalanceAmount - pointDiscountAmountDiff === 0 ? -successOrderPaymentAmount : newBalanceAmount
    };
  }, [checkoutDetails, productShippingFee, shippingFee, selectedVouchers, redeemedPoints]);

  const handleOnShowBannerTimerEnd = useCallback(async () => {
    const telegramUrlObj = await getCheckIsTelegramExist(checkoutDetails.store._id).catch(ex => {
      return {};
    });
    if (telegramUrlObj.url) {
      setTelegramInviteUrl(telegramUrlObj.url);
    } else {
      setShouldShowNotification(true);
    }
  }, [checkoutDetails]);
  useTimer(1, isOrderCheckedOut, handleOnShowBannerTimerEnd);

  const showWebstoreBanner = guard(
    () => checkoutDetails.store.subscriptionConfig.isMicrositeEnabled && !!checkoutDetails.store.micrositeSettings.subdomainName,
    false
  );
  const showPaymentInfoSection = !!(checkoutDetails && checkoutDetails.store && checkoutDetails.store.bankDetails && balanceAmount > 0);
  const showCustomerNoteBanner = !!(checkoutDetails && checkoutDetails.store && checkoutDetails.store.customerNote);

  const isLoading =
    !hasInit ||
    isCheckoutDetailsLoading ||
    isShipmentsLoading ||
    isPaymentMethodsLoading ||
    isConstantsLoading ||
    isVouchersLoading ||
    isLoadingMicrositeFeaturedProducts;
  const isShipmentMethodSelected = checkoutDetails && !!checkoutDetails.shipment.method;

  const isCheckoutWithOnlinePayment = showPaymentInfoSection && isSelectedPaymentMethodOnlineType;

  const isCheckoutOrderProductFromMicrosite = !!orderProducts.find(orderProduct =>
    orderProduct.sources.find(source => ecommercePlatformSourcesConst && source.platform === ecommercePlatformSourcesConst.MICROSITE.code)
  );

  const isEnabledAppNotif = guard(() => checkoutDetails.store.notifConfig.isEnabled, false);

  const isRequirePaymentReceipt = guard(() => checkoutDetails.store.isRequirePaymentReceipt, false);
  const isRequireEmailAddress = guard(() => checkoutDetails.store.isRequireEmailAddress, false);

  const checkAndSetDeliveryStates = useCallback(
    shipmentMethodId => {
      if (shipments && shipments.length > 0 && deliveryMethodsConst && shipmentMethodId) {
        const selectedShipmentMethod = shipments.find(shipment => shipment._id === shipmentMethodId);
        const isShipmentCourierDelivery = selectedShipmentMethod && selectedShipmentMethod.deliveryMethod === deliveryMethodsConst.COURIER.code;
        setIsCourierDelivery(isShipmentCourierDelivery);

        const isIncludeShippingFeeShipments = getFilteredConstants(deliveryMethodsConst, 'isIncludeShippingFee', true);
        const isShipmentIncludeDelivery = selectedShipmentMethod && isIncludeShippingFeeShipments.includes(selectedShipmentMethod.deliveryMethod);
        setIsShipmentIncludeDelivery(isShipmentIncludeDelivery);

        const isShipmentCodDelivery = selectedShipmentMethod && selectedShipmentMethod.deliveryMethod === deliveryMethodsConst.COD.code;
        setIsCodDelivery(isShipmentCodDelivery);
      } else {
        setIsCourierDelivery(false);
        setIsCodDelivery(false);
        setIsShipmentIncludeDelivery(false);
      }
    },
    [shipments, deliveryMethodsConst]
  );

  const checkAndSetCheckedOutOrder = useCallback(
    (status, checkedOutOrder) => {
      if (status && orderStatusesConst) {
        const nonCheckedOutStatusCodes = [orderStatusesConst.PENDING.code, orderStatusesConst.PRE_CHECKOUT.code];

        checkedOutOrder.isPreserved = checkedOutOrder.isPreserved || checkedOutOrder.isPreservePending;

        setCheckedOutOrder(checkedOutOrder);
        setIsOrderCheckedOut(!nonCheckedOutStatusCodes.includes(status));
        setIsOrderCancelled(status === orderStatusesConst.CANCELLED.code);
      } else {
        setIsOrderCheckedOut(false);
      }
    },
    [orderStatusesConst]
  );

  useEffect(() => {
    if (!hasInit) {
      const hasCountryCodes = countryCodes && countryCodes.length > 0;
      const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;
      const hasDeliveryMethodsConst = !!deliveryMethodsConst;
      const hasOrderStatusesConst = !!orderStatusesConst;

      if (
        !isCheckoutDetailsLoading &&
        checkoutDetails &&
        // In order to support checkout page without shipments,
        // we are checking only if it's finish loading, instad of the shipment methods exists like the others
        !isShipmentsLoading &&
        hasCountryCodes &&
        hasPaymentMethods &&
        hasDeliveryMethodsConst &&
        hasOrderStatusesConst &&
        form
      ) {
        setOrderProducts(checkoutDetails.products || []);
        checkAndSetCheckedOutOrder(checkoutDetails.status, checkoutDetails);

        if (
          checkoutDetails.promotionDetails &&
          checkoutDetails.promotionDetails.promotions &&
          checkoutDetails.promotionDetails.promotions.length > 0
        ) {
          // FIXME: Temporary duplicate check isOrderCheckedOut to prevent re-calc discount amount
          const nonCheckedOutStatusCodes = [orderStatusesConst.PENDING.code, orderStatusesConst.PRE_CHECKOUT.code];
          const isOrderCheckedOut = !nonCheckedOutStatusCodes.includes(checkoutDetails.status);

          const appliedVouchers = checkoutDetails.promotionDetails.promotions.reduce((tempAppliedVouchers, promotion) => {
            return [
              ...tempAppliedVouchers,
              {
                ...promotion,
                discountAmount: promotion.discountAmount,
                _id: promotion.customerPromo
              }
            ];
          }, []);

          if (isOrderCheckedOut) {
            setSelectedVouchers(appliedVouchers);
          } else {
            const tempSelectedVouchers = [];
            Promise.all(
              appliedVouchers.map(appliedVoucher =>
                getCalculatePromoDiscount(checkoutDetails._id, appliedVoucher._id)
                  .then(discountAmount => {
                    tempSelectedVouchers.push({ ...appliedVoucher, discountAmount });
                  })
                  .catch(ex => {
                    logError(`Something went wrong when calculating discount amount ${ex.message}`);
                  })
              )
            ).then(() => {
              setSelectedVouchers(tempSelectedVouchers);
              closeVoucherModal();
            });
          }
        }
        if (checkoutDetails.shipment) {
          if (checkoutDetails.shipment.method) {
            setShippingFee(checkoutDetails.shipment.shippingFee);
            setProductShippingFee(checkoutDetails.shipment.productShippingFee);
          }
          checkAndSetDeliveryStates(checkoutDetails.shipment.method);
          if (
            !checkoutDetails.shipment.recipientContact ||
            (checkoutDetails.shipment.recipientContact && !checkoutDetails.shipment.recipientContact.countryCode)
          ) {
            checkoutDetails.shipment.recipientContact = {
              countryCode: countryCodes && countryCodes[0] && countryCodes[0].value // To preset the country code selection
            };
          }
          if (!checkoutDetails.shipment.address || (checkoutDetails.shipment.address && !checkoutDetails.shipment.address.country)) {
            checkoutDetails.shipment.address = {
              country: countries && countries[0] && countries[0].value
            };
          }
        }

        const currentPaymentMethod = paymentMethods[0].code; // To always preset first option for payment method
        setSelectedPaymentMethod(currentPaymentMethod);

        form.setFieldsValue({
          ...checkoutDetails,
          payment: {
            ...checkoutDetails.payment,
            method: currentPaymentMethod
          }
        });

        setHasInit(true);
      }
    }
  }, [
    hasInit,
    isCheckoutDetailsLoading,
    checkoutDetails,
    countries,
    countryCodes,
    form,
    paymentMethods,
    isShipmentsLoading,
    shipments, // This is needed for checkAndSetDeliveryStates deps
    deliveryMethodsConst, // This is needed for checkAndSetDeliveryStates deps
    orderStatusesConst, // This is needed for checkAndSetCheckedOutOrder deps
    checkAndSetDeliveryStates,
    checkAndSetCheckedOutOrder,
    closeVoucherModal
  ]);

  const shipmentMethods = useMemo(
    () =>
      isShipmentsLoading || isConstantsLoading || !shipments
        ? []
        : shipments
            .map(shipment => ({
              value: shipment._id,
              label: `${shipment.label} (${shipment.deliveryMethod})`,
              disabled: shipment.status !== shipmentStatusesConst.ACTIVE.code
            }))
            .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)),
    [isShipmentsLoading, isConstantsLoading, shipments, shipmentStatusesConst]
  );

  const showNotification = useCallback(
    placement => {
      const notificationKey = CHECKOUT_NOTIFICATION_COOKIES;

      notification.open({
        key: notificationKey,
        message: t('pageCheckout:notification-message'),
        description: (
          <div>
            <p style={{ marginBottom: '0px' }}>
              {t('pageCheckout:notification-description-1')}{' '}
              <a
                href="https://ulive.me"
                onClick={() => {
                  setCookie(CHECKOUT_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
                  window.dataLayer.push({
                    event: 'checkout_notification',
                    action: 'chat_with_us'
                  });
                  notification.close(notificationKey);
                }}
                target="_blank"
                rel="noreferrer"
              >
                {t('pageCheckout:notification-description-2')}
              </a>
            </p>
            <p style={{ marginTop: '12px', fontSize: '12px', color: 'grey', fontStyle: 'italic' }}>{t('pageCheckout:notification-caption')}</p>
          </div>
        ),
        duration: 0,
        onClose: () => {
          setCookie(CHECKOUT_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
          window.dataLayer.push({
            event: 'checkout_notification',
            action: 'close_notification_popup'
          });
        },
        placement
      });
    },
    [setCookie, t]
  );

  const showTelegramNotification = useCallback(
    placement => {
      const telegramNotificationKey = TELEGRAM_NOTIFICATION_COOKIES;

      notification.open({
        key: telegramNotificationKey,
        message: t('pageCheckout:telegram-notification-message'),
        description: (
          <div>
            <p style={{ marginBottom: '0px' }}>
              <Trans i18nKey="pageCheckout:telegram-notification-description-1" values={{ storeName: checkoutDetails.store.name }}>
                Join <b>Store Name</b> for more great deals, promos, and instant live notification. 🔥🔥🔥
              </Trans>
            </p>
            <div>
              <Button
                type="primary"
                href={telegramInviteUrl}
                style={{ marginTop: '12px' }}
                onClick={() => {
                  setCookie(TELEGRAM_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
                  window.dataLayer.push({
                    event: 'telegram_notification',
                    action: 'join_us'
                  });
                  notification.close(telegramNotificationKey);
                }}
              >
                {t('pageCheckout:telegram-notification-button-text')}
              </Button>
            </div>
          </div>
        ),
        duration: 0,
        onClose: () => {
          setCookie(TELEGRAM_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
          window.dataLayer.push({
            event: 'telegram_notification',
            action: 'close_notification_popup'
          });
        },
        placement
      });
    },
    [setCookie, t, checkoutDetails, telegramInviteUrl]
  );

  const showAppNotification = useCallback(
    placement => {
      const appNotificationKey = APP_NOTIFICATION_COOKIES;

      notification.open({
        key: appNotificationKey,
        message: t('pageCheckout:app-notification-message'),
        description: (
          <div>
            <p style={{ marginBottom: '8px' }}>{t('pageCheckout:app-notification-description-1')}</p>
            <p style={{ marginBottom: '12px' }}>
              <Trans
                i18nKey="pageCheckout:app-notification-description-2"
                values={{ inviteCode: guard(() => checkoutDetails.store.notifConfig.inviteCode) }}
              >
                Join <b>Invite Code</b> for more great deals, promos, and instant live notification. 🔥🔥🔥
              </Trans>
            </p>
            <div>
              <Button
                type="primary"
                href={APP_URL}
                onClick={() => {
                  setCookie(APP_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
                  window.dataLayer.push({
                    event: 'app_notification',
                    action: 'join_us'
                  });
                  notification.close(appNotificationKey);
                }}
              >
                {t('pageCheckout:app-notification-button-text')}
              </Button>
            </div>
          </div>
        ),
        duration: 0,
        onClose: () => {
          setCookie(APP_NOTIFICATION_COOKIES, true, { expires: moment().add(7, 'days').toDate() });
          window.dataLayer.push({
            event: 'app_notification',
            action: 'close_notification_popup'
          });
        },
        placement
      });
    },
    [setCookie, t, checkoutDetails]
  );

  useEffect(() => {
    // Temporary disable notification
    if (false && isOrderCheckedOut) {
      if (isEnabledAppNotif && !cookies[APP_NOTIFICATION_COOKIES]) {
        showAppNotification('bottomRight');
      } else if (!isEnabledAppNotif && telegramInviteUrl && !cookies[TELEGRAM_NOTIFICATION_COOKIES]) {
        showTelegramNotification('bottomRight');
        setTelegramInviteUrl('');
      } else if (shouldShowNotification && !cookies[CHECKOUT_NOTIFICATION_COOKIES] && (storeCurrency.iso === 'MYR' || storeCurrency.iso === 'SGD')) {
        showNotification('bottomRight');
        setShouldShowNotification(false);
      }
    }
  }, [
    isOrderCheckedOut,
    shouldShowNotification,
    showNotification,
    cookies,
    storeCurrency.iso,
    showTelegramNotification,
    telegramInviteUrl,
    isEnabledAppNotif,
    showAppNotification
  ]);

  const handleOnCheckoutClick = () => {
    const allFieldsValue = form.getFieldsValue();

    if (receiptsRef.current ? receiptsRef.current.validate() : true) {
      confirm({
        title: t('pageCheckout:confirm-modal-checkout-title'),
        content: t('pageCheckout:confirm-modal-checkout-content'),
        okText: t('common:modal-ok-text'),
        cancelText: t('common:modal-cancel-text'),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          setIsCheckoutLoading(true);
          const receipts = await receiptsRef.current?.update();
          postCheckout(
            { orderNumber, accessCode },
            {
              ...allFieldsValue,
              isPreserved: allFieldsValue.isPreservedLocal,
              payment: {
                ...checkoutDetails.payment,
                ...allFieldsValue.payment,
                receipts
              },
              customer: {
                ...checkoutDetails.customer,
                name: allFieldsValue.shipment.recipientName,
                contact: allFieldsValue.shipment.recipientContact,
                email: allFieldsValue.shipment.recipientEmail,
                address: allFieldsValue.shipment.address
              },
              shipment: {
                ...allFieldsValue.shipment,
                remarks: constructShippingRemarks(
                  shouldAllowSkipUpload,
                  allFieldsValue.shipment.remarks,
                  !isRequirePaymentReceipt && !isUploadSuccess && shouldAllowSkipUpload
                    ? t('pageCheckout:file-upload-fb-browser-issue-additional-remarks')
                    : undefined
                ),
                shippingFee: shippingFee
              },
              ...(selectedVouchers.length > 0 && { customerPromos: selectedVouchers.map(selectedVoucher => selectedVoucher._id) }),
              ...(isEnabledPoint && {
                point: {
                  redeem: redeemedPoints,
                  hasRedeemPointUpdate: guard(() => redeemedPoints.amount, 0) !== guard(() => checkoutDetails.point.redeem.amount, 0)
                }
              })
            }
          )
            .then(checkedOutOrder => {
              if (checkedOutOrder && checkedOutOrder.paymentRedirectUrl) {
                Modal.warning({
                  title: t('pageCheckout:warning-modal-pg-redirect-title'),
                  centered: true,
                  okText: t('pageCheckout:warning-modal-pg-redirect-button-ok-text'),
                  okButtonProps: { loading: true }
                });
                window.location.replace(checkedOutOrder.paymentRedirectUrl);
              } else {
                checkAndSetCheckedOutOrder(checkedOutOrder.status, checkedOutOrder);
                history.replace(getCheckoutRoute(orderNumber, { accessCode, hasCheckout: true }).path);
              }
            })
            .catch(e => {
              handlePromoErrorMessage(e);
            })
            .finally(() => {
              setIsCheckoutLoading(false);
            });
        },
        onCancel() {}
      });
    }
  };

  const handleOnFormValidationFailed = ({ errorFields }) => {
    receiptsRef.current && receiptsRef.current.validate();
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const handleOnShipmentMethodChange = shipmentMethodId => {
    checkAndSetDeliveryStates(shipmentMethodId);
    getCalculateShipmentFee(checkoutDetails._id, shipmentMethodId)
      .then(shippingObj => {
        const { shippingFee, productShippingFee } = shippingObj;

        setShippingFee(shippingFee);
        setProductShippingFee(productShippingFee);
      })
      .catch(ex => {
        logError('Something went wrong when calculating shipping fee', ex);
        message.error(`Something went wrong when calculating shipping fee: ${ex.message}. Please refresh your browser and try again.`);
      });

    form.setFields([
      {
        name: ['payment', 'receiptFiles'],
        errors: []
      }
    ]);
  };

  const handleOnApplyVouchers = appliedVouchers => {
    const tempSelectedVouchers = [];
    Promise.all(
      appliedVouchers.map(appliedVoucher =>
        getCalculatePromoDiscount(checkoutDetails._id, appliedVoucher._id)
          .then(discountAmount => {
            tempSelectedVouchers.push({ ...appliedVoucher, discountAmount });
          })
          .catch(ex => {
            if (
              ex.message === 'Unable to apply this promotion because your spending amount does not meet the minimum criteria.' ||
              ex.message === 'Customer Promo not found' ||
              ex.message === 'This promotion is no longer available.' ||
              ex.message === 'This promotion is expired.' ||
              ex.message === 'This promotion is no longer stackable.'
            ) {
              setInvalidVoucherIds([...invalidVoucherIds, appliedVoucher._id]);
            }
            throw new Error(ex.message);
          })
      )
    )
      .then(() => {
        setSelectedVouchers(tempSelectedVouchers);
        closeVoucherModal();
      })
      .catch(ex => {
        handlePromoErrorMessage(ex);
      });
  };

  const handleOnRemoveVoucher = () => {
    setSelectedVouchers([]);
  };

  const handleOnReedemPoints = points => {
    setRedeemedPoints(points);
    closePointsModal();
  };

  const handleOnRemovePoints = () => {
    setRedeemedPoints({ amount: 0, discountAmount: 0 });
  };

  const handlePromoErrorMessage = ex => {
    const invalidVoucherId = ex.message.substring(ex.message.indexOf('[') + 1, ex.message.lastIndexOf('] -'));
    if (invalidVoucherId) {
      setInvalidVoucherIds([...invalidVoucherIds, invalidVoucherId]);
    }

    if (
      ex.message.includes('Unable to apply this promotion because your spending amount does not meet the minimum criteria.') ||
      ex.message.includes('You can only redeem a maximum of')
    ) {
      message.error(t('pageCheckout:voucher-modal-error-message-promo-updated'));
    } else if (ex.message.includes('This promotion is no longer stackable.')) {
      message.error(t('pageCheckout:voucher-modal-error-message-promo-unstackable'));
    } else if (
      ex.message.includes('Customer Promo not found') ||
      ex.message.includes('This promotion is no longer available.') ||
      ex.message.includes('This promotion is expired.')
    ) {
      message.error(t('pageCheckout:voucher-modal-error-message-promo-expired'));
    } else {
      message.error(ex.message);
    }
  };

  const handleOnClickFeaturedProduct = async productId => {
    setSelectedFeaturedProductId(productId);
    setIsViewFeaturedProduct(true);
  };

  return (
    <>
      <Helmet>
        <meta name="title" id="gtm-title" content="Checkout" />
        <title>{t('pageCheckout:page-title', { orderNumber })}</title>
      </Helmet>
      <Form
        form={form}
        initialValues={{ ...checkoutDetails, isPreservedLocal: false }}
        scrollToFirstError={true}
        style={{ width: '100%' }}
        onFinish={handleOnCheckoutClick}
        onFinishFailed={handleOnFormValidationFailed}
      >
        <ContainerCard>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <RootContainer>
              {isViewFeaturedProduct && (
                <FeatureProductDetailsModal
                  isLoading={isLoadingMicrositeFeaturedProduct}
                  selectedFeaturedProduct={selectedFeaturedProduct}
                  orderProducts={orderProducts}
                  onAfterAddToCart={() => {
                    refetchCheckoutDetails();
                    setHasInit(false);
                  }}
                  onCancel={() => setIsViewFeaturedProduct(false)}
                />
              )}
              <Col span={24}>
                <Row style={{ width: '100%' }} justify="space-between">
                  <Col span={24} md={0} style={{ marginBottom: '32px' }}>
                    <LanguageSwitcher localesToShow={localeConfig.locales || []} />
                  </Col>
                  <Col span={24} md={18}>
                    {checkoutDetails && checkoutDetails.store && (
                      <>
                        <Row gutter={16} align="middle">
                          {(checkoutDetails.store.logoV2 || checkoutDetails.store.logo) && (
                            <Col>
                              <ProfileAvatar size={60} isSquare src={checkoutDetails.store.logoV2.thumbnail.url || checkoutDetails.store.logo.url} />
                            </Col>
                          )}
                          <Col span={24} md={checkoutDetails.store.logo ? 20 : undefined}>
                            <StoreName>{checkoutDetails.store.name}</StoreName>
                          </Col>
                        </Row>
                        {checkoutDetails.store.bankDetails && (
                          <StoreDescription>
                            {checkoutDetails.store.bankDetails.bankName} {checkoutDetails.store.bankDetails.accountNumber}{' '}
                            {checkoutDetails.store.bankDetails.beneficiaryName}
                          </StoreDescription>
                        )}
                      </>
                    )}
                  </Col>
                  <Col span={0} md={6}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      <LanguageSwitcher localesToShow={localeConfig.locales || []} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} md={0}>
                    <Title style={{ fontSize: '16px' }}>{t('pageCheckout:order-section-title-mobile')}:</Title>
                    <Title style={{ fontSize: '20px', marginBottom: '8px' }}>{orderNumber}</Title>
                  </Col>
                  <Col span={0} md={24}>
                    <Title style={{ fontSize: '20px', marginBottom: '8px' }}>{t('pageCheckout:order-section-title', { orderNumber })}</Title>{' '}
                  </Col>
                </Row>
                {showWebstoreBanner && (
                  <WebstoreNotificationBanner
                    subdomainName={checkoutDetails.store.micrositeSettings.subdomainName}
                    orderNumber={orderNumber}
                    accessCode={accessCode}
                  />
                )}
              </Col>
              <CheckoutProductListCol span={24} md={10} lg={8} showBanner={showWebstoreBanner}>
                <FeaturedSection
                  isLoading={isLoading}
                  featuredProduct={micrositeFeaturedProducts}
                  onViewFeaturedProduct={handleOnClickFeaturedProduct}
                />
                <List
                  header={
                    <CardTitle>
                      <b>{t('pageCheckout:success-modal-order-products')}</b>
                    </CardTitle>
                  }
                  rowKey={record => record._id}
                  dataSource={orderProducts}
                  renderItem={(orderProduct, index) => (
                    <>
                      <ProductCard
                        label={orderProduct.label}
                        description={orderProduct.description}
                        keyword={orderProduct.matchingKeyword}
                        quantity={orderProduct.quantity}
                        total={orderProduct.priceDetails.totalAmount}
                        oriPrice={orderProduct.priceDetails.oriPrice}
                        previewImageUrl={orderProduct.coverPhoto?.original?.url || orderProduct.coverPhotoFile?.url}
                        thumbnailImageUrl={orderProduct.coverPhoto?.thumbnail?.url || orderProduct.coverPhotoFile?.url}
                        storeCurrency={storeCurrency}
                        openProductDetailsModal={openProductDetailsModal}
                      />
                      <br />
                      {index === orderProducts.length - 1 && (
                        <>
                          <Row style={{ marginTop: 12 }} gutter={[0, 8]}>
                            <CheckoutPriceDisplay
                              label={t('pageCheckout:order-display-desc-label-subtotal')}
                              amount={checkoutDetails.subtotalPrice}
                              storeCurrency={storeCurrency}
                            />
                            <CheckoutPriceDisplay
                              label={t('pageCheckout:order-display-desc-label-shipping-fee')}
                              labelWhenZero={t('pageCheckout:order-display-desc-label-shipping-fee-free')}
                              labelWhenUndefined={t('pageCheckout:order-display-desc-label-no-shipping-details')}
                              storeCurrency={storeCurrency}
                              amount={shippingFee}
                            />
                            {!!productShippingFee && (
                              <CheckoutPriceDisplay
                                label={t('pageCheckout:order-display-desc-label-product-shipping-fee')}
                                storeCurrency={storeCurrency}
                                amount={productShippingFee}
                              />
                            )}
                            {checkoutDetails.extraFee && checkoutDetails.extraFee.amount ? (
                              <CheckoutPriceDisplay
                                label={t('pageCheckout:order-display-desc-label-extra-fee')}
                                storeCurrency={storeCurrency}
                                amount={checkoutDetails.extraFee.amount}
                              />
                            ) : null}
                            <VoucherDisplay
                              t={t}
                              selectedVouchers={selectedVouchers}
                              onRemoveVoucherClick={handleOnRemoveVoucher}
                              onVoucherClick={openVoucherModal}
                            />
                            {isEnabledPoint && (
                              <PointDisplay
                                t={t}
                                redeemedPoints={redeemedPoints}
                                onRemovePointsClick={handleOnRemovePoints}
                                onPointsClick={openPointsModal}
                              />
                            )}
                            {totalDiscountAmount ? (
                              <CheckoutPriceDisplay
                                label={t('pageCheckout:order-display-desc-label-discount')}
                                storeCurrency={storeCurrency}
                                amount={totalDiscountAmount}
                                isDeduct
                              />
                            ) : null}
                            <CheckoutPriceDisplay
                              label={t('pageCheckout:order-display-desc-label-total')}
                              labelWhenZero={t('pageCheckout:order-display-desc-label-total-zero')}
                              storeCurrency={storeCurrency}
                              amount={totalAmount}
                              highlight
                            />
                          </Row>
                          {(!!checkoutDetails.pendingOrderPaymentAmount || !!checkoutDetails.successOrderPaymentAmount) && (
                            <Row style={{ marginTop: 24 }} gutter={[0, 8]}>
                              <Col span={24}>{t('pageCheckout:order-display-payment-info-title')}</Col>
                              {!!checkoutDetails.pendingOrderPaymentAmount && (
                                <CheckoutPriceDisplay
                                  label={t('pageCheckout:order-display-desc-label-pending-amount')}
                                  storeCurrency={storeCurrency}
                                  amount={checkoutDetails.pendingOrderPaymentAmount}
                                  warn
                                />
                              )}
                              {!!checkoutDetails.successOrderPaymentAmount && (
                                <CheckoutPriceDisplay
                                  label={t('pageCheckout:order-display-desc-label-success-amount')}
                                  storeCurrency={storeCurrency}
                                  amount={checkoutDetails.successOrderPaymentAmount}
                                />
                              )}
                              <CheckoutPriceDisplay
                                label={t('pageCheckout:order-display-desc-label-balance-amount')}
                                storeCurrency={storeCurrency}
                                amount={balanceAmount}
                              />
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
              </CheckoutProductListCol>
              <CheckoutFormFieldsCol span={24} md={14} lg={16} showBanner={showWebstoreBanner}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Card title={<CardTitle>{t('pageCheckout:card-title-shipping-details')}</CardTitle>} bordered={false} bodyStyle={{ padding: 0 }}>
                      <ShippingDetailsRow gutter={16}>
                        <Col span={24} xxl={12}>
                          <FormInput
                            label={t('pageCheckout:form-input-label-order-recipient-name')}
                            name={['shipment', 'recipientName']}
                            placeholder={t('pageCheckout:form-input-placeholder-order-recipient-name')}
                            requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-name')}
                          />
                        </Col>
                        <Col span={24} xxl={12}>
                          <FormGroupSelectionInput
                            label={t('pageCheckout:form-input-label-order-recipient-contact')}
                            inputName={['shipment', 'recipientContact', 'contactNumber']}
                            selectionName={['shipment', 'recipientContact', 'countryCode']}
                            inputType="contact"
                            selections={countryCodes}
                            requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-contact')}
                          />
                        </Col>
                        {isRequireEmailAddress && (
                          <Col span={24} xxl={12}>
                            <FormInput
                              label={t('pageCheckout:form-input-label-order-recipient-email')}
                              name={['shipment', 'recipientEmail']}
                              placeholder={t('pageCheckout:form-input-placeholder-order-recipient-email')}
                              type="email"
                              requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-email')}
                            />
                          </Col>
                        )}
                        <Col span={24}>
                          <FormSelection
                            label={t('pageCheckout:form-input-label-order-shipping-method')}
                            name={['shipment', 'method']}
                            selections={shipmentMethods}
                            onChange={handleOnShipmentMethodChange}
                            requiredErrorMessage={t('pageCheckout:form-input-required-message-order-shipping-method')}
                            isDisabled={isShipmentMethodSelected}
                          />
                        </Col>
                        {isShipmentIncludeDelivery && (
                          <>
                            <Col span={24}>
                              <FormInput
                                label={t('pageCheckout:form-input-label-order-recipient-address-line-1')}
                                name={['shipment', 'address', 'addressLine1']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-address-line-1')}
                                requiredErrorMessage={t('pageCheckout:form-input-placeholder-order-recipient-address-line-1')}
                              />
                            </Col>
                            <Col span={24}>
                              <FormInput
                                label={t('pageCheckout:form-input-label-order-recipient-address-line-2')}
                                name={['shipment', 'address', 'addressLine2']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-address-line-2')}
                              />
                            </Col>
                            <Col span={24} md={12}>
                              <FormInput
                                label={t('pageCheckout:form-input-label-order-recipient-city')}
                                name={['shipment', 'address', 'city']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-city')}
                                requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-city')}
                              />
                            </Col>
                            <Col span={24} md={12}>
                              <FormInput
                                label={t('pageCheckout:form-input-label-order-recipient-zipcode')}
                                name={['shipment', 'address', 'zipcode']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-zipcode')}
                                requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-zipcode')}
                              />
                            </Col>
                            <Col span={24} md={12}>
                              <FormInput
                                label={t('pageCheckout:form-input-label-order-recipient-state')}
                                name={['shipment', 'address', 'state']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-state')}
                                requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-state')}
                              />
                            </Col>
                            <Col span={24} md={12}>
                              <FormSelection
                                label={t('pageCheckout:form-input-label-order-recipient-country')}
                                name={['shipment', 'address', 'country']}
                                placeholder={t('pageCheckout:form-input-placeholder-order-recipient-country')}
                                requiredErrorMessage={t('pageCheckout:form-input-required-message-order-recipient-country')}
                                selections={countries}
                              />
                            </Col>
                          </>
                        )}
                        <Col span={24}>
                          <FormInput
                            label={t('pageCheckout:form-input-label-order-shipping-remarks')}
                            name={['shipment', 'remarks']}
                            placeholder={t('pageCheckout:form-input-placeholder-order-shipping-remarks')}
                            type="textArea"
                          />
                        </Col>
                      </ShippingDetailsRow>
                    </Card>
                  </Col>
                  {showCustomerNoteBanner && (
                    <Col span={24}>
                      <NoteBannerContainer>
                        <Alert
                          message={
                            <Space direction="vertical">
                              <Row align="middle">
                                <Tag color="#ffba00">
                                  <b>
                                    <InfoCircleOutlined />
                                  </b>
                                </Tag>
                                <span>
                                  <b>{t('pageCheckout:customer-note-banner-text')}:</b>
                                </span>
                              </Row>
                              <NoteBannerText>
                                <TextWithUrl text={checkoutDetails.store.customerNote} />
                              </NoteBannerText>
                            </Space>
                          }
                          type="warning"
                        />
                      </NoteBannerContainer>
                    </Col>
                  )}
                  {showPaymentInfoSection && (
                    <Col span={24}>
                      <PaymentInfoSection
                        store={checkoutDetails && checkoutDetails.store}
                        storeCurrency={storeCurrency}
                        balanceAmount={balanceAmount}
                        pendingOrderPaymentAmount={checkedOutOrder.pendingOrderPaymentAmount}
                        isReceiptRequired={isRequirePaymentReceipt || (isCourierDelivery && !shouldAllowSkipUpload)}
                        isStoreRequirePaymentReceipt={isRequirePaymentReceipt}
                        isSelectedOnlinePayment={isSelectedPaymentMethodOnlineType}
                        paymentMethods={paymentMethods}
                        shouldAllowUpload={!isOrderCheckedOut && !isSelectedPaymentMethodOnlineType}
                        shouldAllowSkipUpload={shouldAllowSkipUpload}
                        selectedPaymentMethod={selectedPaymentMethod}
                        onChangePaymentMethod={setSelectedPaymentMethod}
                        onUpload={handleOnUploadClick}
                        onUploadSuccess={handleOnUploadSuccess}
                        isCodDelivery={isCodDelivery}
                        receiptsRef={receiptsRef}
                        currentPhotos={currentPhotos}
                        isEnabledPoint={isEnabledPoint}
                        pointsToEarn={pointsToEarn}
                        setCurrentPhotos={setCurrentPhotos}
                        setIsCompressing={setIsCompressing}
                      />
                    </Col>
                  )}
                  {!isOrderCheckedOut && (
                    <Col span={24} style={{ paddingBottom: '0px' }}>
                      <Button
                        htmlType="submit"
                        block
                        type="primary"
                        icon={isSelectedPaymentMethodOnlineType ? <CreditCardOutlined /> : <ShoppingCartOutlined />}
                        disabled={isOrderCheckedOut}
                        loading={isCheckoutLoading || isCompressing}
                      >
                        {isCheckoutWithOnlinePayment
                          ? t('pageCheckout:form-submit-payment-gateway-checkout-button-text')
                          : t('pageCheckout:form-submit-checkout-button-text')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CheckoutFormFieldsCol>
            </RootContainer>
          )}
          {isOrderCheckedOut && (
            <SuccessModal
              orderNumber={orderNumber}
              accessCode={accessCode}
              storeDetails={checkedOutOrder.store}
              recipientDetails={checkedOutOrder.shipment}
              storeCurrency={storeCurrency}
              subtotalPrice={checkedOutOrder.subtotalPrice}
              shippingFee={checkedOutOrder.shipment.shippingFee}
              productShippingFee={checkedOutOrder.shipment.productShippingFee}
              extraFeeAmount={checkedOutOrder.extraFee && checkedOutOrder.extraFee.amount}
              totalDiscountAmount={totalDiscountAmount}
              totalAmount={checkedOutOrder.totalAmount}
              orderProducts={orderProducts}
              isOrderCancelled={isOrderCancelled}
              hasMicrositeOrder={isCheckoutOrderProductFromMicrosite}
              isPreserved={checkedOutOrder.isPreserved}
              isEnabledPoint={isEnabledPoint}
              pointsEarned={checkedOutOrder.point?.earn?.amount}
              currentPoints={checkedOutOrder.customer.currentBalancePoints}
            />
          )}
          {isSuccessPreservedOrderModalShow && (
            <Modal visible={isSuccessPreservedOrderModalShow} forceRender footer={null} closable={false}>
              <Result
                status="success"
                title={<ResultTitleContainer>{t('pageCheckout:preserve-success-redirection-alert-modal-title')}</ResultTitleContainer>}
                subTitle={t('pageCheckout:preserve-success-redirection-alert-modal-desc')}
              />
            </Modal>
          )}
          {showVoucherModal && (
            <VoucherModal
              orderId={checkoutDetails._id}
              storeCurrency={storeCurrency}
              vouchers={vouchers}
              selectedVouchers={selectedVouchers}
              maxStackVocuherCount={checkoutDetails.store && checkoutDetails.store.maxVouchersPerOrder}
              invalidVoucherIds={invalidVoucherIds}
              onApplyVouchers={handleOnApplyVouchers}
              onCancel={closeVoucherModal}
              removeSelfFromInvalidVoucherIds={id => setInvalidVoucherIds(invalidVoucherIds.filter(invalidVoucherId => invalidVoucherId !== id))}
            />
          )}
          {showPointsModal && (
            <PointsModal
              storeCurrency={storeCurrency}
              currentPoints={checkoutDetails.customer.redeemablePoints}
              subtotalPrice={checkoutDetails.subtotalPrice}
              totalDiscountAmount={totalDiscountAmount}
              shippingFee={shippingFee + productShippingFee || 0}
              successOrderPaymentAmount={checkoutDetails.successOrderPaymentAmount}
              redeemRate={checkoutDetails.store.pointConfig.redeemRate}
              redeemedPoints={redeemedPoints}
              onReedemPoints={handleOnReedemPoints}
              onCancel={closePointsModal}
            />
          )}
          {showProductDetailsModal && (
            <ProductDetailsModal selectedProductDetails={selectedProductDetails} storeCurrency={storeCurrency} onCancel={closeProductDetailsModal} />
          )}
        </ContainerCard>
      </Form>
    </>
  );
};

export default Checkout;
