import { useState, useCallback } from 'react';
import { Button, Col, message, Modal, Popover, Row, Space, Table, Tooltip } from 'antd';
import { Helmet } from 'react-helmet-async';
import {
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  TagOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import { deleteProduct, patchProduct, useGetProducts } from 'apis/product';
import { withAppContext } from 'contexts/AppContext/AppContext';

import CurrencyDisplay from 'components/CurrencyDisplay/CurrencyDisplay';
import ShippingFeeIcon from 'components/Icon/ShippingFeeIcon';
import BulkPurchaseIcon from 'components/Icon/BulkPurchaseIcon';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import Paragraph from 'components/Paragraph/Paragraph';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';

import { useFetchConstant } from 'hooks/constants';

import { checkIsArrayEmpty, checkIsObjectEmpty, constructDisplayPrice, getLabelOfConstant } from 'utils/general';
import { getProductDetailsRoute, getNewProductRoute, getGiftDetailsRoute, getNewGiftRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterSearch,
  handleOnAggregationTableChange,
  constructColumnFilterRadio,
  formatPaginationShowTotalDisplay
} from 'utils/table/table';

import QuickEditModal from './components/QuickEditModal/QuickEditModal';
import QuickUpdateStatusModal from './components/QuickUpdateStatusModal/QuickUpdateStatusModal';
import BulkCreateModal from './components/BulkCreateModal/BulkCreateModal';
import DownloadProductsCSVModal from './components/DownloadProductsCSVModal/DownloadProductsCSVModal';
import ProductPendingOrderModal from './components/ProductPendingOrderModal/ProductPendingOrderModal';

import {
  TitleContainer,
  TooltipQtyIcon,
  TooltipKeywordIcon,
  StyledImagePreview,
  DownloadCSVButton,
  ProductPendingOrderQuantity
} from './Products.styles';

const { confirm, error } = Modal;

const constructInventoryDisplay = (productKeyword, variances = [], type) => {
  return (
    <Popover
      trigger="click"
      content={variances.map(variance => (
        <p key={variance.keyword}>
          <TagOutlined /> [{productKeyword}
          {variance.keyword}] {variance.label} x {variance[type] || 0}
        </p>
      ))}
    >
      <TooltipQtyIcon hasPreorderQty={!checkIsObjectEmpty(variances.find(variance => variance[type] < 0))} />
    </Popover>
  );
};

const constructVariancesKeywordsDisplay = (t, productKeyword, variances = [], allBuyKeyword) => {
  return (
    <Popover
      trigger="click"
      content={
        <>
          <p>
            <b>
              {t('pageProduct:tooltip-message-keyword')}: {productKeyword}
            </b>
          </p>
          {variances.map(variance => (
            <p key={variance.keyword}>
              <TagOutlined /> {productKeyword}
              {variance.keyword}
            </p>
          ))}

          {allBuyKeyword && (
            <>
              <p>
                <b>{t('pageProduct:tooltip-message-buy-all-keyword')}</b>
              </p>
              <p>
                <TagOutlined /> {allBuyKeyword}
              </p>
            </>
          )}
        </>
      }
    >
      <TooltipKeywordIcon />
    </Popover>
  );
};

const constructPriceDisplayForProductWithVariances = (variances = [], currencyISO) => {
  const variancesPriceList = variances.map(variance => Number(variance.price));
  const maxPrice = Math.max(...variancesPriceList);
  const minPrice = Math.min(...variancesPriceList);
  const isMinMaxPriceEqual = maxPrice === minPrice;

  return checkIsArrayEmpty(variances)
    ? '-'
    : isMinMaxPriceEqual
    ? constructDisplayPrice(maxPrice, currencyISO)
    : `${constructDisplayPrice(minPrice, currencyISO)} - ${constructDisplayPrice(maxPrice, currencyISO)}`;
};

const constructColumns = ({
  t,
  currencyISO,
  productTypes,
  productTypesConst,
  productStatuses,
  productStatusesConst,
  isRoleAllowOperation,
  handleOnEditStatusClick,
  handleOnQuickEditClick,
  handleOnPendingOrderClick
}) => [
  {
    ...constructColumn(t('pageProduct:table-header-name'), 'label', {
      hasAggregationSorter: true,
      width: '20%'
    }),
    render: (text, record) => {
      const previewImageUrl = record.coverPhoto?.original?.url || record.coverPhotoFile?.url;
      const thumbnailImageUrl = record.coverPhoto?.thumbnail?.url || previewImageUrl;

      const productLinkComp = productTypesConst && (
        <Link to={record.type === productTypesConst.GIFT.code ? getGiftDetailsRoute(record._id).path : getProductDetailsRoute(record._id).path}>
          {String(text)}
        </Link>
      );
      const productNameComp = isRoleAllowOperation ? productLinkComp : <span>{String(text)}</span>;

      return previewImageUrl ? (
        <Row align="middle" gutter={{ md: 8 }}>
          <Col>
            <StyledImagePreview previewImageUrl={previewImageUrl} thumbnailImageUrl={thumbnailImageUrl} />
          </Col>
          <Col span={18}>{productNameComp}</Col>
        </Row>
      ) : (
        productNameComp
      );
    },
    ...constructColumnFilterSearch('label', t('pageProduct:table-header-action-search-product'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageProduct:table-header-qty-left'), 'inventory', { hasAggregationSorter: true, width: '10%' }),
    render: (text, record) => (
      <Row>
        <Col span={4}>{checkIsArrayEmpty(record.variances) ? '' : constructInventoryDisplay(record.keyword, record.variances, 'inventory')}</Col>
        <Col>
          {text}
          {record.pendingOrderQuantity > 0 && (
            <>
              {' '}
              <Tooltip title={t('pageProduct:product-pending-order-quantity-tooltip')}>
                <ProductPendingOrderQuantity onClick={() => handleOnPendingOrderClick(record._id)}>
                  ({record.pendingOrderQuantity})
                </ProductPendingOrderQuantity>
              </Tooltip>
            </>
          )}
        </Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('pageProduct:table-header-ori-inventory'), 'oriInventory', { hasAggregationSorter: true, width: '10%' }),
    render: (text, record) => (
      <Row>
        <Col span={4}>{checkIsArrayEmpty(record.variances) ? '' : constructInventoryDisplay(record.keyword, record.variances, 'oriInventory')}</Col>
        <Col>{text || '-'}</Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('pageProduct:table-header-keyword'), 'keyword', { hasAggregationSorter: true, width: '10%' }),
    ...constructColumnFilterSearch('keyword', t('pageProduct:table-header-action-search-product-keyword'), { hasAggregationFilter: true }),
    render: (text, record) => (
      <Row>
        <Col span={4}>
          {checkIsArrayEmpty(record.variances) ? '' : constructVariancesKeywordsDisplay(t, text, record.variances, record.allBuyKeyword)}
        </Col>
        <Col>{String(text)}</Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('modalOverviewProductKeyword:table-header-price'), 'price', { isPrice: true, width: '10%' }),
    render: (price, record) => (
      <Row>
        <Col>
          {checkIsArrayEmpty(record.variances)
            ? constructDisplayPrice(price, currencyISO)
            : constructPriceDisplayForProductWithVariances(record.variances, currencyISO)}
        </Col>
      </Row>
    )
  },
  {
    ...constructColumn(t('pageProduct:table-header-type'), 'type', { hasAggregationSorter: true, width: '10%' }),
    ...constructColumnFilterRadio('type', productTypes),
    render: (type, record) => <>{getLabelOfConstant(type, productTypes)}</>
  },
  {
    ...constructColumn(t('pageProduct:table-header-status'), 'status', { hasAggregationSorter: true, width: '10%' }),
    ...constructColumnFilterRadio('status', productStatuses),
    render: (status, record) => (
      <>
        <StatusIndicator isActive={productStatusesConst && record.status === productStatusesConst.ACTIVE.code} />{' '}
        {getLabelOfConstant(status, productStatuses)}
      </>
    )
  },
  {
    ...constructColumn(t('pageProduct:table-header-settings'), 'settings', { width: '10%' }),
    render: (settings, record) => {
      const hasBulkPurchaseTiersSetting = record.bulkPurchaseTiers && record.bulkPurchaseTiers.length > 0;
      const hasShipmentsSetting = record.shipments && record.shipments.length > 0;
      const hasSetting = hasBulkPurchaseTiersSetting || hasShipmentsSetting;

      return hasSetting ? (
        <Row gutter={8}>
          {hasBulkPurchaseTiersSetting && (
            <Col>
              <Popover
                trigger="click"
                content={
                  <div>
                    <p>
                      <b>
                        <CurrencyDisplay prefix={t('pageProduct:tooltip-message-settings-bulkpurchase-tier')} />
                      </b>
                    </p>
                    {record.bulkPurchaseTiers.map(bulkPurchaseTier => (
                      <Paragraph type="small">
                        {bulkPurchaseTier.quantity}: {constructDisplayPrice(bulkPurchaseTier.price)}
                      </Paragraph>
                    ))}
                  </div>
                }
              >
                <BulkPurchaseIcon type="link" width={18} height={18} style={{ marginLeft: '4px', marginBottom: '2px', cursor: 'pointer' }} />
              </Popover>
            </Col>
          )}
          {hasShipmentsSetting && (
            <Col>
              <Popover
                trigger="click"
                content={
                  <div>
                    <p>
                      <b>
                        <CurrencyDisplay prefix={t('pageProduct:tooltip-message-settings-shipments')} />
                      </b>
                    </p>
                    {record.shipments.map(shipment => (
                      <Paragraph type="small">
                        {shipment.label}:{' '}
                        {shipment.minFee === shipment.maxFee
                          ? constructDisplayPrice(shipment.minFee)
                          : `${constructDisplayPrice(shipment.minFee)} - ${constructDisplayPrice(shipment.maxFee)}`}
                      </Paragraph>
                    ))}
                  </div>
                }
              >
                <ShippingFeeIcon type="link" width={22} height={22} style={{ marginLeft: '4px', marginBottom: '2px', cursor: 'pointer' }} />
              </Popover>
            </Col>
          )}
        </Row>
      ) : (
        '-'
      );
    }
  },
  {
    ...(isRoleAllowOperation && {
      ...constructColumn(t('pageProduct:table-header-actions'), 'action', { width: '10%' }),
      render: (text, record) => {
        const isActive = productStatusesConst && record.status === productStatusesConst.ACTIVE.code;
        return (
          <Space wrap={true}>
            <TextButton
              size="small"
              icon={isActive ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={() => handleOnEditStatusClick(isActive, record._id)}
              text={isActive ? t('pageProduct:deactivate-button') : t('pageProduct:activate-button')}
            />
            <TextButton
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleOnQuickEditClick(record._id)}
              text={t('pageProduct:quick-manage-button')}
            />
          </Space>
        );
      }
    })
  }
];

const Products = ({ storeCurrency, checkCanUserAccess, storeOperatorRoles }) => {
  const { t } = useTranslation(['common', 'pageProduct']);
  const history = useHistory();
  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });
  const [isQuickEditClicked, setIsQuickEditClicked] = useState(false);
  const [isQuickUpdateStatusClicked, setIsQuickUpdateStatusClicked] = useState(false);
  const [isEditingMultipleProducts, setIsEditingMultipleProducts] = useState(false);
  const [isBulkUploadProductsClicked, setIsBulkUploadProductsClicked] = useState(false);
  const [isDownloadProductCSVClicked, setIsDownloadProductCSVClicked] = useState(false);
  const [isProductPendingOrderClicked, setIsProductPendingOrderClicked] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { isLoading: isProductsLoading, paginatedData: products, total, refetch: refetchProducts } = useGetProducts(query);
  const { selection: productTypes, data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');
  const { selection: productStatuses, data: productStatusesConst, isLoading: isProductStatusesLoading } = useFetchConstant('productStatuses');

  const isLoading = isProductsLoading || isProductTypesLoading || isProductStatusesLoading;

  const isRoleAllowOperation = checkCanUserAccess([
    storeOperatorRoles.ADMIN.code,
    storeOperatorRoles.OPERATOR.code,
    storeOperatorRoles.LIVE_STREAMER.code
  ]);

  const handleOnEditStatusClick = (isActive, selectedProductId) => {
    confirm({
      title: isActive ? t('pageProduct:confirm-modal-deactivate-status-title') : t('pageProduct:confirm-modal-activate-status-title'),
      icon: <ExclamationCircleOutlined />,
      okText: isActive ? t('pageProduct:confirm-modal-dectivate-button-text') : t('pageProduct:confirm-modal-activate-button-text'),
      cancelText: t('pageProduct:confirm-modal-cancel-button-text'),
      okButtonProps: { type: 'primary', danger: isActive },
      onOk() {
        const payload = { status: isActive ? productStatusesConst.INACTIVE.code : productStatusesConst.ACTIVE.code };
        patchProduct(selectedProductId, payload)
          .then(() => {
            message.success(t('common:update-success-message'));
            refetchProducts();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  const handleOnQuickEditClick = selectedProductId => {
    setIsQuickEditClicked(true);
    setSelectedProductId(selectedProductId);
  };

  const handleOnModalOkClick = useCallback(
    payload => {
      setIsQuickEditClicked(false);
      patchProduct(selectedProductId, payload)
        .then(() => {
          message.success(t('common:update-success-message'));
          setSelectedProductId('');
          refetchProducts();
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    },
    [refetchProducts, selectedProductId, t]
  );

  const handleOnModalCancelClick = () => {
    setIsQuickEditClicked(false);
    setSelectedProductId('');
  };

  const handleOnDeleteMultipleProducts = () => {
    setIsEditingMultipleProducts(true);
    confirm({
      title: t('common:confirm-modal-delete-title', { itemName: t('pageProductDetails:item-name-lowercase') }),
      content: t('common:confirm-modal-delete-content'),
      okText: t('common:modal-ok-text'),
      cancelText: t('common:modal-cancel-text'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        selectedProducts.map(product =>
          deleteProduct(product)
            .then(() => {
              message.success(t('common:delete-success-message', { itemName: t('pageProductDetails:item-name') }));
              refetchProducts();
              setIsEditingMultipleProducts(false);
              setIsQuickUpdateStatusClicked(false);
              setSelectedProducts([]);
            })
            .catch(ex => {
              setIsEditingMultipleProducts(false);
              error({
                title: ex.message
              });
            })
        );
      },
      onCancel() {
        setIsEditingMultipleProducts(false);
      }
    });
  };

  const handleOnUpdateMultipleProductStatuses = isToDeactivate => {
    setIsEditingMultipleProducts(true);
    confirm({
      title: isToDeactivate
        ? t('pageProduct:confirm-modal-deactivate-status-title', { itemCount: selectedProducts.length })
        : t('pageProduct:confirm-modal-activate-status-title', { itemCount: selectedProducts.length }),
      icon: <ExclamationCircleOutlined />,
      okText: isToDeactivate
        ? t('pageProduct:confirm-modal-dectivate-button-text', { itemCount: selectedProducts.length })
        : t('pageProduct:confirm-modal-activate-button-text', { itemCount: selectedProducts.length }),
      cancelText: t('pageProduct:confirm-modal-cancel-button-text'),
      okButtonProps: { type: 'primary', danger: isToDeactivate },
      onOk() {
        const payload = { status: isToDeactivate ? productStatusesConst.INACTIVE.code : productStatusesConst.ACTIVE.code };
        selectedProducts.map(product =>
          patchProduct(product, payload)
            .then(() => {
              message.success(t('common:update-success-message'));
              refetchProducts();
              setIsEditingMultipleProducts(false);
              setIsQuickUpdateStatusClicked(false);
              setSelectedProducts([]);
            })
            .catch(ex => {
              setIsEditingMultipleProducts(false);
              error({
                title: ex.message
              });
            })
        );
      },
      onCancel() {
        setIsEditingMultipleProducts(false);
      }
    });
  };

  const handleOnBulkUploadSuccess = () => {
    setIsBulkUploadProductsClicked(false);
    refetchProducts();
  };

  const handleOnPendingOrderClick = selectedProductId => {
    setIsProductPendingOrderClicked(true);
    setSelectedProductId(selectedProductId);
  };

  const handleOnPendingOrderCancelClick = () => {
    setIsProductPendingOrderClicked(false);
    setSelectedProductId('');
  };

  return (
    <FullWidthContainer>
      <Helmet>
        <meta name="title" id="gtm-title" content="Products" />
        <title>{t('pageProduct:page-title')}</title>
      </Helmet>
      <TitleContainer justify="space-between">
        <Title>{t('pageProduct:title')}</Title>
        {isRoleAllowOperation && (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setIsBulkUploadProductsClicked(true);
              }}
              icon={<PlusOutlined />}
            >
              {t('pageProduct:bulk-create-products')}
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => {
                history.push(getNewGiftRoute().path);
              }}
            >
              {t('pageProduct:add-new-gift-button')}
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => {
                history.push(getNewProductRoute().path);
              }}
            >
              {t('pageProduct:add-new-product-button')}
            </Button>
          </Space>
        )}
      </TitleContainer>
      {isRoleAllowOperation && (
        <Row gutter={[8, 24]} justify="end">
          <Col>
            <Space>
              <DownloadCSVButton type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => setIsDownloadProductCSVClicked(true)} />
              <Button
                type="primary"
                disabled={selectedProducts.length < 1}
                onClick={() => {
                  setIsQuickUpdateStatusClicked(true);
                }}
                icon={<EditOutlined />}
              >
                {t('pageProduct:quick-edit-multiple-products', { itemCount: selectedProducts.length })}
              </Button>
            </Space>
          </Col>
        </Row>
      )}
      <Table
        size="small"
        loading={isLoading}
        rowKey={record => record._id}
        rowSelection={{
          type: 'checkbox',
          onChange: selectedRowKeys => {
            setSelectedProducts(selectedRowKeys);
          },
          selectedRowKeys: selectedProducts
        }}
        dataSource={products}
        columns={constructColumns({
          t,
          currencyISO: storeCurrency.iso,
          productTypes,
          productTypesConst,
          productStatuses,
          productStatusesConst,
          isRoleAllowOperation,
          handleOnEditStatusClick,
          handleOnQuickEditClick,
          handleOnPendingOrderClick
        })}
        scroll={{ x: 768 }}
        locale={{
          triggerDesc: t('common:table-header-sort-trigger-desc'),
          triggerAsc: t('common:table-header-sort-trigger-asc'),
          cancelSort: t('common:table-header-sort-cancel-sort')
        }}
        pagination={{ total, showSizeChanger: true, showTotal: formatPaginationShowTotalDisplay }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
      {isQuickEditClicked && (
        <QuickEditModal
          visible={isQuickEditClicked}
          productId={selectedProductId}
          productTypesConst={productTypesConst}
          onClose={handleOnModalCancelClick}
          onOk={handleOnModalOkClick}
        />
      )}
      {isQuickUpdateStatusClicked && (
        <QuickUpdateStatusModal
          visible={true}
          productsCount={selectedProducts.length}
          isLoading={isEditingMultipleProducts}
          onCancel={() => setIsQuickUpdateStatusClicked(false)}
          onDelete={handleOnDeleteMultipleProducts}
          onDeactivate={() => handleOnUpdateMultipleProductStatuses(true)}
          onActivate={() => handleOnUpdateMultipleProductStatuses(false)}
        />
      )}
      {isBulkUploadProductsClicked && (
        <BulkCreateModal onOk={() => handleOnBulkUploadSuccess()} onCancel={() => setIsBulkUploadProductsClicked(false)} />
      )}
      {isDownloadProductCSVClicked && (
        <DownloadProductsCSVModal visible={isDownloadProductCSVClicked} onCancel={() => setIsDownloadProductCSVClicked(false)} />
      )}
      {isProductPendingOrderClicked && (
        <ProductPendingOrderModal
          visible={isProductPendingOrderClicked}
          onCancel={handleOnPendingOrderCancelClick}
          productId={selectedProductId}
          isRoleAllowOperation={isRoleAllowOperation}
        />
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(Products);
