import { useMemo, useState, useCallback } from 'react';
import { Avatar, Button, Space, Table as AntdTable, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Helmet as ReactHelmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MdOutlineContactEmergency } from 'react-icons/md';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetStoreCustomers, useGetStoreCustomerTierSuggestions } from 'apis/storecustomer';

import { DEFAULT_PAGINATION_QUERY } from 'utils/constants';
import { constructReadableNum, getLabelOfConstant, guard } from 'utils/general';
import { getCustomerDetailsRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterRadio,
  constructColumnFilterSearch,
  formatPaginationShowTotalDisplay,
  handleOnAggregationTableChange
} from 'utils/table/table';
import { useIsLoadings } from 'hooks/utils';
import { useFetchConstant } from 'hooks/constants';

import FBModal, { useFBModal } from 'components/FBModal/FBModal';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import CustomerMergeModal, { useCustomerMergeModal } from 'components/CustomerMergeModal/CustomerMergeModal';
import CustomerRemarksModal, { useCustomerRemarksModal } from 'components/CustomerRemarksModal/CustomerRemarksModal';
import Title from 'components/Title/Title';
import TextButton from 'components/TextButton/TextButton';
import CustomerSettingModal from './components/CustomerSettingModal/CustomerSettingModal';

import Logo from 'images/u-logo.png';

import { TitleContainer, NameLabel, RemarksClickableIcon, WAClickableIcon, FBClickableIcon, UliveAccountIcon } from './Customers.styles';

/* =================================================== Local Functions =================================================== */
const useCustomers = () => {
  const [query, setQuery] = useState(DEFAULT_PAGINATION_QUERY);

  const { isLoading: isLoadingCustomers, paginatedData: customers, total, refetch: refetchCustomers } = useGetStoreCustomers(query);

  return { isLoadingCustomers, customers, query, total, refetchCustomers, setQuery };
};

const getAddressText = ({ addressLine1, addressLine2, city, zipcode, state, country }, countries) =>
  `${addressLine1 ? addressLine1 + ' ' : ''}${addressLine2 ? addressLine2 + ', ' : ''}${zipcode || ''} ${city ? city + ', ' : ''}${
    state ? state + ', ' : ''
  }${country ? getLabelOfConstant(country, countries) + '.' : ''}`;

/* =================================================== Local Components =================================================== */

const Helmet = ({ t }) => {
  return (
    <ReactHelmet>
      <meta name="title" id="gtm-title" content="Customers" />
      <title>{t('pageCustomers:page-title')}</title>
    </ReactHelmet>
  );
};

const Table = ({
  t,
  loading,
  customers,
  countries,
  storeCustomerTiers,
  socialMediaSourcesConst,
  total,
  setQuery,
  handleOnCustomerRemarksBtnClick,
  handleOnWABtnClick,
  handleOnFBBtnClick,
  handleOnMergeCustomerBtnClick
}) => {
  const columns = useMemo(
    () => [
      {
        ...constructColumn(t('pageCustomers:table-header-customer-info'), 'info', { width: '30%' }),
        ...constructColumnFilterSearch('info', t('pageCustomers:table-header-customer-info'), { hasAggregationFilter: true }),
        render: (_, record) => {
          const customerId = record._id;
          const customerName = record.name;
          const customerSource = record.source;
          const customerSourceUsername = record.sourceUserName;
          const profilePicUrl = record.profilePicUrl;
          const customerContactNumber = guard(() => `${record.contact.countryCode || ''}${record.contact.contactNumber || ''}`, '');
          const customerRemarks = record.remarks;
          const buyerUserId = record.buyerUser;

          const hasCustomerSource = !!customerSource;
          const hasContactNumber = !!customerContactNumber;
          const hasCustomerRemarks = !!customerRemarks;
          const hasUliveAccount = !!buyerUserId;
          const isFBCustomer = customerSource === guard(() => socialMediaSourcesConst.FB.code, '');

          const isRoleAllowUpdate = true; // TODO_KONG_POINT: What would be the appropriate role for this?

          return (
            <>
              <Link to={getCustomerDetailsRoute(customerId).path}>
                {hasCustomerSource && isRoleAllowUpdate ? (
                  <>
                    {profilePicUrl && (
                      <>
                        <Avatar size="small" src={`${profilePicUrl}`} />{' '}
                      </>
                    )}
                    <NameLabel isUserBlacklisted={record.customerIsBlacklisted}>
                      {customerName} {`(${customerSource.toUpperCase()}: ${customerSourceUsername})`}
                    </NameLabel>{' '}
                    {customerContactNumber && <span> ({customerContactNumber}) </span>}
                  </>
                ) : (
                  <span>
                    {customerName} {customerContactNumber && <span> ({customerContactNumber}) </span>}
                  </span>
                )}
              </Link>{' '}
              {hasCustomerRemarks && <RemarksClickableIcon width={14} height={14} onClick={() => handleOnCustomerRemarksBtnClick(customerId)} />}
              {hasContactNumber && <WAClickableIcon onClick={() => handleOnWABtnClick(customerContactNumber)} />}
              {isFBCustomer && <FBClickableIcon width={14} height={14} onClick={() => handleOnFBBtnClick(customerId)} />}
              {hasUliveAccount && (
                <Tooltip title={t('pageCustomers:tooltip-message-ulive-buyer')} trigger="click" arrowPointAtCenter>
                  <UliveAccountIcon src={Logo} shape="square" size={14} />
                </Tooltip>
              )}
            </>
          );
        }
      },
      {
        ...constructColumn(t('pageCustomers:table-header-customer-tier'), 'tier', { width: '10%', hasAggregationSorter: true }),
        ...constructColumnFilterRadio('tier', storeCustomerTiers, { hasAggregationFilter: true }),
        render: (_, record) => {
          return record.customerTier ? `${record.customerTier.emoji} ${record.customerTier.label}` : '-';
        }
      },
      {
        ...constructColumn(t('pageCustomers:table-header-customer-email'), 'email', { width: '20%' }),
        ...constructColumnFilterSearch('email', t('pageCustomers:table-header-customer-email'), { hasAggregationFilter: true })
      },
      {
        ...constructColumn(t('pageCustomers:table-header-customer-address'), 'address', { width: '20%' }),
        render: address => {
          return <div>{guard(() => getAddressText(address, countries) || '-', '-')}</div>;
        }
      },
      {
        ...constructColumn(t('pageCustomers:table-header-customer-current-point'), 'point', {
          hasAggregationSorter: true,
          width: '10%'
        }),
        render: point => {
          return <div>{point ? constructReadableNum(point) : 0}</div>;
        }
      },
      {
        ...constructColumn(t('pageCustomers:table-header-customer-action'), 'action', { width: '10%' }),
        render: (text, record) => (
          <TextButton
            size="small"
            icon={<MdOutlineContactEmergency style={{ marginRight: '4px', marginBottom: '-2px' }} />}
            text={t('pageCustomers:table-action-button-merge')}
            onClick={() => handleOnMergeCustomerBtnClick(record._id)}
          />
        )
      }
    ],
    [
      t,
      countries,
      storeCustomerTiers,
      socialMediaSourcesConst,
      handleOnCustomerRemarksBtnClick,
      handleOnWABtnClick,
      handleOnFBBtnClick,
      handleOnMergeCustomerBtnClick
    ]
  );

  return (
    <AntdTable
      loading={loading}
      rowKey={record => record._id}
      dataSource={customers}
      columns={columns}
      scroll={{ x: 1200 }}
      locale={{
        triggerDesc: t('common:table-header-sort-trigger-desc'),
        triggerAsc: t('common:table-header-sort-trigger-asc'),
        cancelSort: t('common:table-header-sort-cancel-sort')
      }}
      pagination={{
        total,
        showSizeChanger: true,
        showTotal: formatPaginationShowTotalDisplay
      }}
      onChange={(pagination, filters, sorter) => {
        handleOnAggregationTableChange(
          {
            pagination,
            filters,
            sorter
          },
          setQuery
        );
      }}
    />
  );
};

/* =================================================== Main Component =================================================== */
const Customers = ({ store, storeCurrency }) => {
  const { t } = useTranslation(['common', 'pageCustomers']);
  const [isCustomerSettingVisible, setIsCustomerSettingVisible] = useState(false);

  const { isLoadingCustomers, customers, total, refetchCustomers, setQuery } = useCustomers();
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { isLoading: isStoreCustomerTierLoading, data: storeCustomerTiers, refetch: refetchCustomerTiers } = useGetStoreCustomerTierSuggestions();

  const {
    isCustomerRemarksModalVisible,
    setIsCustomerRemarksModalVisible,
    selectedCustomerId: customerRemarksSelectedCustomerId,
    handleOnCustomerRemarksBtnClick
  } = useCustomerRemarksModal();

  const {
    isCustomerMergeModalVisible,
    defaultSelectedMainCustomer,
    handleOnMergeCustomerBtnClick,
    handleOnMergeCustomerModalCancel
  } = useCustomerMergeModal();

  const { isFBModalVisible, setIsFBModalVisible, selectedCustomerId: fbModalSelectedCustomerId, handleOnFBBtnClick } = useFBModal();

  const { isLoading } = useIsLoadings([isLoadingCustomers, isCountriesLoading, isSocialMediaSourcesLoading, isStoreCustomerTierLoading]);

  const handleOnWABtnClick = useCallback(
    contactNumber => {
      const hasPresetCustomWAMessage = !!store.customWAMessage;
      const message = encodeURIComponent(hasPresetCustomWAMessage ? `${store.customWAMessage}` : `Hi!`);
      window.open(`https://api.whatsapp.com/send?phone=${contactNumber}&text=${message}`);
      window.dataLayer.push({
        event: 'wa_event',
        action: 'wa_button_clicked'
      });
    },
    [store.customWAMessage]
  );

  const handleOnMergeCustomerSuccess = useCallback(() => {
    refetchCustomers();
    handleOnMergeCustomerModalCancel();
  }, [refetchCustomers, handleOnMergeCustomerModalCancel]);

  const handleOnUpdateCustomerSettingSuccess = useCallback(() => {
    refetchCustomers();
    refetchCustomerTiers();
    setIsCustomerSettingVisible(false);
  }, [refetchCustomers, refetchCustomerTiers, setIsCustomerSettingVisible]);

  return (
    <>
      <Helmet t={t} />
      <FullWidthContainer>
        <TitleContainer justify="space-between">
          <Title>{t('pageCustomers:page-title')}</Title>
          <Space>
            <Button type="primary" shape="round" onClick={() => setIsCustomerSettingVisible(true)}>
              <SettingOutlined />
            </Button>
          </Space>
        </TitleContainer>
        <div>
          <Table
            t={t}
            loading={isLoading}
            customers={customers}
            socialMediaSourcesConst={socialMediaSourcesConst}
            countries={countries}
            storeCustomerTiers={storeCustomerTiers}
            total={total}
            setQuery={setQuery}
            handleOnCustomerRemarksBtnClick={handleOnCustomerRemarksBtnClick}
            handleOnWABtnClick={handleOnWABtnClick}
            handleOnFBBtnClick={handleOnFBBtnClick}
            handleOnMergeCustomerBtnClick={handleOnMergeCustomerBtnClick}
          />
        </div>
      </FullWidthContainer>
      {isCustomerSettingVisible && (
        <CustomerSettingModal
          visible
          storeCurrency={storeCurrency}
          onOk={handleOnUpdateCustomerSettingSuccess}
          onCancel={() => setIsCustomerSettingVisible(false)}
        />
      )}
      {isCustomerRemarksModalVisible && (
        <CustomerRemarksModal
          isCustomerRemarksModalVisible={isCustomerRemarksModalVisible}
          setIsCustomerRemarksModalVisible={setIsCustomerRemarksModalVisible}
          selectedCustomerId={customerRemarksSelectedCustomerId}
        />
      )}
      {isFBModalVisible && (
        <FBModal
          isFBModalVisible={isFBModalVisible}
          setIsFBModalVisible={setIsFBModalVisible}
          selectedCustomerId={fbModalSelectedCustomerId}
          storeCustomWAMessage={store.customWAMessage}
        />
      )}
      {isCustomerMergeModalVisible && (
        <CustomerMergeModal
          isCustomerMergeModalVisible={isCustomerMergeModalVisible}
          defaultSelectedMainCustomer={defaultSelectedMainCustomer}
          onMergeSuccess={handleOnMergeCustomerSuccess}
          onCancel={handleOnMergeCustomerModalCancel}
        />
      )}
    </>
  );
};

export default withAppContext(Customers);
